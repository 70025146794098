import { Spin } from 'antd';
import classNames from 'classnames';
import React from 'react';

// Time for animation still after iframe has loaded.
const EXTRA_LOADING_TIME = 1000;

interface Props {
  readonly url: string;
}

interface State {
  clearIframe: boolean;
  loaded: boolean;
}

export default class FrameView extends React.Component<Props, State> {
  state: State = { clearIframe: false, loaded: false };
  clearIframeTimerHandle: number | null = null;
  iframeLoadedTimerHandle: number | null = null;

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.url !== this.props.url) {
      this.setState({ clearIframe: true, loaded: false });
      this.clearIframeTimerHandle = window.setTimeout(
        () => this.setState({ clearIframe: false }),
        100
      );
    }
  }

  componentWillUnmount() {
    this.clearIframeTimerHandle && window.clearTimeout(this.clearIframeTimerHandle);
    this.iframeLoadedTimerHandle && window.clearTimeout(this.iframeLoadedTimerHandle);
  }

  iframeLoaded = () => {
    this.iframeLoadedTimerHandle = window.setTimeout(
      () => this.setState({ loaded: true }),
      EXTRA_LOADING_TIME
    );
  };

  render() {
    const iframeClass = classNames('app-FrameView-iframe', {
      'app-FrameView-iframe-loading': !this.state.loaded,
    });
    return (
      <div className="app-FrameView">
        {!this.state.loaded && (
          <div className="app-FrameView-loading">
            <Spin />
          </div>
        )}
        {!this.state.clearIframe && (
          <iframe
            className={iframeClass}
            frameBorder="0"
            height="100%"
            onLoad={this.iframeLoaded}
            src={this.props.url}
            width="100%"
          />
        )}
      </div>
    );
  }
}
