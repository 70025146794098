import 'core-js/stable';

// Allow immer to work on IE 11
import { enableES5 } from 'immer';
enableES5();

import React from 'react';
import { render } from 'react-dom';

import 'assets/styles/main.less';
import AppContainer from './AppContainer';

const rootEl = document.getElementById('root');

render(<AppContainer />, rootEl);
