import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as api from 'state/api';
import * as auth from 'state/auth';
import * as dataSource from 'state/data/dataSource';

const selector = createStructuredSelector({
  currentOrganizationId: auth.selectCurrentOrganizationId,
  isFetching: api.selectIsFetchDataSourcesPending,
  sourceTables: dataSource.selectSourceTables,
  sourceTableGroups: dataSource.selectSourceTableGroups,
  usesPostgres: auth.selectUsesPostgres,
});

export default connect(selector);
