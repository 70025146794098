import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as dataSource from 'state/data/dataSource';
import modals from 'state/modals';

// Use empty createStructuredSelector to keep type checks happy.
const selector = createStructuredSelector({});

const actions = {
  delete: dataSource.deleteSourceTableGroup,
  showDataSourceUploadModal: modals.showDataSourceUpload,
  sync: dataSource.syncSourceTableGroup,
};

export default connect(selector, actions);
