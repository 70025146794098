import { CloseOutlined, WarningOutlined } from '@ant-design/icons';
import { Alert, Button, Popover } from 'antd';
import moment from 'moment';
import React from 'react';
import TimeAgo from 'react-timeago';

import { DataExport } from 'types';
import DataExportAccess from './DataExportAccess';
import DataExportDownload from './DataExportDownload';
import DataExportSettings from './DataExportSettings';

export interface OwnProps {}

export interface InjectedProps {
  readonly canEdit: boolean;
  readonly closeDataExportDetails: () => void;
  readonly dataExport: DataExport;
  readonly deleteDataExport: () => void;
  readonly editDataExport: (values: {}) => void;
  readonly showSqlInEditor: (values: {}) => void;
  readonly visible: boolean;
}

type Props = OwnProps & InjectedProps;

export default class DataExportDetails extends React.Component<Props> {
  renderAlert = () => {
    let message = `Something has changed in the source data and the
    data export SQL is no longer valid!`;
    const { refreshErrorAt } = this.props.dataExport;
    if (refreshErrorAt) {
      const errorAt = moment(refreshErrorAt).format('DD.MM.YYYY hh:mm');
      message = `Refreshing data export data failed at ${errorAt}.
        Data on this data export is no longer up to date.`;
    }

    return (
      <Alert
        className="app-DataExportDetails__alert"
        banner
        icon={<WarningOutlined className="app-DataExportDetails__alert-icon" />}
        message={message}
        type="error"
      />
    );
  };

  renderCreatedAt = () => {
    const createdAt = `${this.props.dataExport.createdAt}Z`;
    const creator = this.props.dataExport.creatorName;
    return (
      <div className="app-DataExportDetails__created-at">
        Created by {creator} on {moment(createdAt).format('DD.MM.YYYY')}
      </div>
    );
  };

  renderEditedAt = () => {
    const updatedAt = `${this.props.dataExport.updatedAt}Z`;
    return (
      <div className="app-DataExportDetails__edited-at">
        Edited <TimeAgo date={updatedAt} />
      </div>
    );
  };

  renderInfo = () => {
    return (
      <Alert
        className="app-DataExportDetails__info"
        banner
        message="Data in this data export is filtered by the logged in user."
        type="info"
      />
    );
  };

  renderDataUpdatedAt = () => {
    if (!this.props.dataExport.dataUpdatedAt) {
      return null;
    }
    const updatedAt = `${this.props.dataExport.dataUpdatedAt}Z`;
    return (
      <div className="app-DataExportDetails__data-updated-at">
        Data updated <TimeAgo date={updatedAt} />
      </div>
    );
  };

  renderSubheader = (text: string) => {
    return <h3 className="app-DataExportDetails__subheader">{text}</h3>;
  };

  renderContent = () => {
    const { canEdit, dataExport, editDataExport } = this.props;

    if (!dataExport) return <div />;

    return (
      <div className="app-DataExportDetails">
        {dataExport.hasLatestRefreshFailed && this.renderAlert()}
        {dataExport.isParameterized && this.renderInfo()}

        <div className="app-DataExportDetails__header-section">
          <div className="app-DataExportDetails__header-section-content">
            <h3 className="app-DataExportDetails__header">{dataExport.name}</h3>
            {this.renderDataUpdatedAt()}
            {dataExport.description && (
              <div className="app-DataExportDetails__description">{dataExport.description}</div>
            )}
            {dataExport.createdAt && this.renderCreatedAt()}
            {dataExport.updatedAt && this.renderEditedAt()}
          </div>
          <DataExportSettings
            canEdit={this.props.canEdit}
            dataExport={dataExport}
            onDelete={this.props.deleteDataExport}
            showSqlInEditor={this.props.showSqlInEditor}
          />
          <Button
            icon={<CloseOutlined />}
            onClick={this.props.closeDataExportDetails}
            shape="circle"
          />
        </div>

        {this.renderSubheader('Manage Access')}
        <DataExportAccess
          canEdit={canEdit}
          dataExport={dataExport}
          editDataExport={editDataExport}
        />

        {this.renderSubheader('Download as')}
        <DataExportDownload dataExport={dataExport} />
      </div>
    );
  };

  render() {
    return (
      <div className="app-DataExportDetails__wrapper">
        <Popover
          content={this.renderContent()}
          placement="leftTop"
          trigger="click"
          visible={this.props.visible}
        />
      </div>
    );
  }
}
