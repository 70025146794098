import startsWith from 'lodash/startsWith';
import React from 'react';

import { View as ViewType } from 'types';
import DashboardView from './DashboardView';
import FrameView from './FrameView';
import PivotView from './PivotView';
import TableauView from './TableauView';
import TableView from './TableView';
import ViewTopbar from './ViewTopbar';

const TABLEAU_SERVER = 'https://reports.quickbi.io/';

interface Props {
  readonly view: ViewType | null;
}

function renderComponent(view: ViewType) {
  switch (view.kind) {
    case 'dashboard':
      return <DashboardView id={view.dashboard!.id} />;
    case 'pivot':
      // @ts-ignore
      return <PivotView id={view.pivot!.id} />;
    case 'table':
      // @ts-ignore
      return <TableView id={view.tableView!.id} />;
    default:
      if (startsWith(view.url, TABLEAU_SERVER)) {
        // @ts-ignore
        return <TableauView view={view} />;
      }
      return <FrameView url={view.url} />;
  }
}

export default function View(props: Props) {
  if (!props.view) return <div />;
  return (
    <div className="app-View">
      <ViewTopbar />
      <div className="app-View__content">{renderComponent(props.view)}</div>
    </div>
  );
}
