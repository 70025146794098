import { Form, Divider, Input, Select } from 'antd';
import React from 'react';

import ViewsEditor from './ViewsEditor';

interface Props {
  readonly updateViewGroup: any;
  readonly viewGroup: any;
}

const Option = Select.Option;
const FormItem = Form.Item;

export default class ViewGroupEditor extends React.Component<Props> {
  handleChange = (data: {}) => {
    const { updateViewGroup, viewGroup } = this.props;
    updateViewGroup(viewGroup.id, data);
  };

  handleViewChangerTextChange = (event: any) => {
    const text = event.target.value;
    this.handleChange({ urlChangerText: text });
  };

  handleViewChangerTypeChange = (value: string) => {
    this.handleChange({ urlChanger: value });
  };

  handleViewsChange = (views: any) => {
    this.handleChange({ views });
  };

  renderViewChanger = () => {
    const { viewGroup } = this.props;
    return (
      <div className="app-ViewGroupEditor__view-changer">
        <h3 className="app-ViewGroupEditor__subheader">View Changer</h3>
        <Form layout="inline">
          <FormItem label="Type">
            <Select
              onChange={this.handleViewChangerTypeChange}
              style={{ width: 120 }}
              value={viewGroup.urlChanger || ''}
            >
              <Option value="dropdown">Dropdown</Option>
              <Option value="switch">Switch</Option>
              <Option value="">Hidden</Option>
            </Select>
          </FormItem>
          <FormItem label="Text">
            <Input
              onChange={this.handleViewChangerTextChange}
              style={{ width: 240 }}
              value={viewGroup.urlChangerText}
            />
          </FormItem>
        </Form>
        <p className="app-ViewGroupEditor__help-text">
          Only visible if there are more than one view on the page.
        </p>
      </div>
    );
  };

  render() {
    const { viewGroup } = this.props;

    if (!viewGroup) {
      return (
        <div className="app-ViewGroupEditor">
          <h2 className="app-ViewGroupEditor__header">Page</h2>
          <p>Select a page from the left to edit.</p>
        </div>
      );
    }

    return (
      <div className="app-ViewGroupEditor">
        <h2 className="app-ViewGroupEditor__header">{`Page | ${viewGroup.name}`}</h2>
        {this.renderViewChanger()}
        <Divider className="app-ViewGroupEditor__divider" />
        <ViewsEditor onChange={this.handleViewsChange} views={viewGroup.views} />
      </div>
    );
  }
}
