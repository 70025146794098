import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { Statistic } from 'antd';
import classNames from 'classnames';
import get from 'lodash/get';
import isNull from 'lodash/isNull';
import React from 'react';
import makeComponentTrashable from 'trashable-react';

import { formatNumber } from 'shared/utils';
import { DashboardBoxSpecs } from 'types';
import { getBoxComparisonSpecs, getBoxDifference } from './utils';

export interface OwnProps {
  readonly loadFunction?: any;
  readonly loadParams: any;
  readonly specs: DashboardBoxSpecs;
}

export interface InjectedProps {
  readonly registerPromise: Function;
}

export type Props = OwnProps & InjectedProps;

interface State {
  comparisonValue: number | null;
  value: number | null;
}

class DashboardBox extends React.Component<Props, State> {
  state: State = {
    comparisonValue: null,
    value: null,
  };

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.loadParams !== prevProps.loadParams) {
      this.loadData();
    }
  }

  getValueText = () => {
    const { value } = this.state;
    if (isNull(value)) {
      return '';
    }
    const prefix = get(this.props.specs, 'value.prefix', '');
    const suffix = get(this.props.specs, 'value.suffix', '');
    return `${prefix}${formatNumber(value)}${suffix}`;
  };

  loadData = () => {
    const { loadFunction, loadParams, registerPromise, specs } = this.props;
    if (loadFunction) {
      registerPromise(loadFunction(loadParams)).then((data: any) => {
        if (data.length) {
          let comparisonValue = 0;
          let value = 0;
          const comparisonColumn = get(specs, 'comparison.column', 'comparisonValue');
          const valueColumn = get(specs, 'value.column', 'value');
          data.forEach((item: any) => {
            comparisonValue += item[comparisonColumn] || 0;
            value += item[valueColumn] || 0;
          });
          this.setState({ comparisonValue, value });
        } else {
          this.setState({ comparisonValue: null, value: null });
        }
      });
    }
  };

  renderComparison = () => {
    const { comparisonValue, value } = this.state;
    const { comparison, comparisonStyles } = this.props.specs;
    const comparisonSpecs = getBoxComparisonSpecs(comparison);
    if (!comparisonValue || !value || comparisonSpecs.hidden) {
      return null;
    }

    const difference = getBoxDifference(value, comparisonValue, comparisonSpecs.type);

    let color = 'grey';
    let IconType = ArrowUpOutlined;
    if (difference > 0) {
      color = 'green';
    }
    if (difference < 0) {
      color = 'red';
      IconType = ArrowDownOutlined;
    }

    return (
      <Statistic
        className="app-DashboardBox__comparison"
        decimalSeparator=","
        groupSeparator=" "
        precision={comparisonSpecs.precision}
        prefix={<IconType />}
        suffix={comparisonSpecs.suffix}
        value={difference}
        valueStyle={{ ...comparisonStyles, color }}
      />
    );
  };

  render() {
    const { specs } = this.props;
    const boxStyles = specs.styles || {};
    const boxClassNames = classNames('app-DashboardBox', {
      'app-DashboardBox--size-small': specs.size === 'small',
    });
    return (
      <div className={boxClassNames} style={boxStyles}>
        <div className="app-DashboardBox__title" style={specs.titleStyles}>
          {specs.title}
        </div>
        <div className="app-DashboardBox__numbers">
          <div className="app-DashboardBox__value" style={specs.valueStyles}>
            {this.getValueText()}
          </div>
          {this.renderComparison()}
        </div>
      </div>
    );
  }
}

export default makeComponentTrashable(DashboardBox);
