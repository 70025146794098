import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as dataExportsForFront from 'state/data/dataExportsForFront';
import { InjectedProps } from './DataExportSelect';

const selector = createStructuredSelector({
  dataExportGrouping: dataExportsForFront.selectDataExportGrouping,
});

export default connect<InjectedProps, {}, {}>(selector);
