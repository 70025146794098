import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as auth from 'state/auth';

export const selector = createStructuredSelector({
  credentials: auth.selectCredentials,
});

export default connect(selector);
