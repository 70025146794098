import { Action, createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

import { Pivot } from 'types';

interface State {
  readonly activePivot: Pivot | null;
  readonly activePivotId: string | null;
}

// -------
// Actions
// -------

export const fetch = createAction('app/data/pivots/fetch', (id: string) => id);

export const edit = createAction('app/data/pivots/edit');

// ---------
// Selectors
// ---------

function select(state: any): State {
  return state.data.pivots;
}

export const selectActivePivot = (pivotIdSelector: any) => {
  return createSelector(pivotIdSelector, select, (pivotId: string, state: State): Pivot | null =>
    pivotId === state.activePivotId ? state.activePivot : null
  );
};

// -----
// State
// -----

type PivotAction = Action<Pivot>;
const initialState: State = {
  activePivot: null,
  activePivotId: null,
};
const handlers = {
  [String(fetch)]: (state: State, action: any): State => {
    return {
      ...state,
      activePivot: null,
      activePivotId: action.payload,
    };
  },
  [`${fetch}.success`]: (state: State, action: PivotAction): State => {
    const payload = action.payload as Pivot;
    return {
      ...state,
      activePivot: payload,
    };
  },
};
export default handleActions(handlers, initialState);
