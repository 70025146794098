import { Input, Typography } from 'antd';
import React from 'react';

const { Password } = Input;
const { Text } = Typography;

interface Props {
  readonly isPassword: boolean;
  readonly text: string;
}

export default class CopyInput extends React.Component<Props> {
  static defaultProps = {
    isPassword: false,
  };

  render() {
    const { isPassword, text } = this.props;
    return (
      <div className="app-CopyInput">
        {isPassword && <Password value={text} />}
        {!isPassword && <Input value={text} />}
        <Text className="app-CopyInput__copyable-text" code copyable>
          {text}
        </Text>
      </div>
    );
  }
}
