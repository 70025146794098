import { Button } from 'antd';
import React from 'react';

export interface OwnProps {
  readonly values: {};
}

export interface InjectedProps {
  readonly showSqlInEditor: (values: {}) => void;
}

type Props = OwnProps & InjectedProps;

export default class ShowSqlInEditorButton extends React.Component<Props> {
  showSqlInEditor = (event: any) => {
    event.stopPropagation();
    this.props.showSqlInEditor(this.props.values);
  };

  render() {
    return (
      <Button
        className="app-ShowSqlInEditorButton"
        onClick={this.showSqlInEditor}
        size="small"
        type="primary"
      >
        SQL
      </Button>
    );
  }
}
