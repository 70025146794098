import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Popover } from 'antd';
import { arrayMoveImmutable } from 'array-move';
import keyBy from 'lodash/keyBy';
import React from 'react';

import { TableViewColumn } from 'types';
import { ColumnChangeHandler } from './Column';
import SortableColumns from './SortableColumns';

interface Props {
  readonly columns: TableViewColumn[];
  readonly isSaving: boolean;
  readonly onCancel: () => void;
  readonly onSave: (data: {}) => void;
}

interface State {
  columns: { [key: string]: TableViewColumn };
  columnIds: string[];
}

export default class ColumnsForm extends React.Component<Props, State> {
  state = {
    columnIds: this.props.columns.map((column) => column.id),
    columns: keyBy(this.props.columns, 'id'),
  };

  handleColumnChange: ColumnChangeHandler = (columnId, data) => {
    const { columns } = this.state;
    this.setState({
      columns: {
        ...columns,
        [columnId]: {
          ...columns[columnId],
          ...data,
        },
      },
    });
  };

  handleSave = () => {
    const { columnIds, columns } = this.state;
    const data = columnIds.map((columnId) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { field, ...column } = columns[columnId];
      return column;
    });
    this.props.onSave(data);
  };

  handleSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    this.setState(({ columnIds }) => ({
      columnIds: arrayMoveImmutable(columnIds, oldIndex, newIndex),
    }));
  };

  renderFieldHeader(title: string, helpText?: string) {
    return (
      <div className="app-ColumnsForm__row">
        {title}
        {helpText && (
          <Popover content={helpText} placement="bottomLeft" trigger="click">
            <InfoCircleOutlined className="app-ColumnsForm__info-icon" />
          </Popover>
        )}
      </div>
    );
  }

  render() {
    return (
      <div className="app-ColumnsForm">
        <div className="app-ColumnsForm__content">
          <div className="app-ColumnsForm__headers">
            {this.renderFieldHeader(
              'Column',
              `Name of the column in the original data.
              Use the drag handle to sort columns.`
            )}
            {this.renderFieldHeader('Header', 'Visible name for the column in the table.')}
            {this.renderFieldHeader(
              'Header group',
              `Group columns together by using same header group name.
              Leave empty if you don't want to have grouped headers.`
            )}
            {this.renderFieldHeader(
              'Fixed',
              'Select "left" or "right" to fix columns on either side of the table.'
            )}
            {this.renderFieldHeader(
              'Width',
              `Column width in pixels. If left empty the column width will be
              calculated automatically based on column content.`
            )}
            {this.renderFieldHeader(
              'Data type',
              'Change only if the default data type for the column is not correct.'
            )}
            {this.renderFieldHeader(
              'Data format',
              'In what format to show column values. Leave empty for text columns.'
            )}
            {this.renderFieldHeader(
              'Filter sorting',
              `Whether to sort unique values in filters in ascending or
              descending order.`
            )}
          </div>
          <SortableColumns
            axis="x"
            columnIds={this.state.columnIds}
            columns={this.state.columns}
            helperClass="app-ColumnsForm__helper"
            lockAxis="x"
            onColumnChange={this.handleColumnChange}
            onSortEnd={this.handleSortEnd}
            useDragHandle
          />
        </div>
        <div className="app-ColumnsForm__actions">
          <Button onClick={this.props.onCancel}>Cancel</Button>
          <Button disabled={this.props.isSaving} onClick={this.handleSave} type="primary">
            {this.props.isSaving ? 'Saving...' : 'Save'}
          </Button>
        </div>
      </div>
    );
  }
}
