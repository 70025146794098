import { EditOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import React from 'react';

import ErrorMessages from 'shared/ErrorMessages';
import { ErrorMessage } from 'types';
import SortableGroups from './SortableGroups';
import { buildFullData } from './utils';

export type TreeData = any[];

export interface InjectedProps {
  readonly errorMessages: ErrorMessage[];
  readonly fetchDataExports: (options: {}) => void;
  readonly hideEditor: () => void;
  readonly isSaving: boolean;
  readonly isVisible: boolean;
  readonly saveDataExportGrouping: (data: {}) => void;
  readonly showEditor: () => void;
  readonly treeData: TreeData;
}

type Props = InjectedProps;

interface State {
  treeData: TreeData;
}

export default class DataExportGroupEditor extends React.Component<Props, State> {
  state: State = {
    treeData: [],
  };

  componentDidUpdate(prevProps: Props) {
    if (this.props.isVisible && !prevProps.isVisible) {
      this.props.fetchDataExports({ isFox: true });
    }
  }

  showModal = () => {
    const { treeData } = this.props;
    this.setState({
      treeData,
    });
    this.props.showEditor();
  };

  handleSave = () => {
    const data = buildFullData(this.state.treeData);
    this.props.saveDataExportGrouping(data);
  };

  handleTreeChange = (treeData: []) => {
    this.setState({ treeData });
  };

  handleCancel = () => {
    this.props.hideEditor();
  };

  render() {
    return (
      <div className="app-DataExportGroupEditor">
        <Button
          className="app-DataExportGroupEditor__button"
          icon={<EditOutlined />}
          onClick={this.showModal}
          shape="circle"
          size="small"
        />
        <Modal
          className="app-DataExportGroupEditor__modal"
          confirmLoading={this.props.isSaving}
          maskClosable={false}
          okText="Save"
          onCancel={this.handleCancel}
          onOk={this.handleSave}
          title="Group data exports"
          visible={this.props.isVisible}
          width={570}
        >
          <ErrorMessages errorMessages={this.props.errorMessages} />
          <SortableGroups onChange={this.handleTreeChange} treeData={this.state.treeData} />
        </Modal>
      </div>
    );
  }
}
