import { Layout } from 'antd';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { FOX_PAGE_PATH, FOX_PAGE_PATH_OLD, FRONT_PAGE_PATH } from 'shared/constants';
import NoPermissionPage from 'shared/NoPermissionPage';
import NotFoundPage from 'shared/NotFoundPage';
import { UserOrganization } from 'types';
import FoxPage from './FoxPage';
import FrontPage from './FrontPage';
import Topbar from './Topbar';

interface OwnProps {}

interface InjectedProps {
  readonly canView: boolean;
  readonly organization: UserOrganization | null;
  readonly resetData: () => void;
  readonly resetEditorTabs: () => void;
  readonly url: string;
}

type Props = OwnProps & InjectedProps;

export default class Organization extends React.Component<Props> {
  componentDidUpdate(prevProps: Props) {
    if (prevProps.organization !== this.props.organization) {
      this.props.resetData();
      this.props.resetEditorTabs();
    }
  }

  renderContent() {
    const { canView, organization, url } = this.props;
    if (!organization) return <NotFoundPage />;
    if (organization.isFoxHidden && organization.isFrontHidden) return <NotFoundPage />;
    if (!canView) return <NoPermissionPage />;

    const foxPagePath = `${url}/${FOX_PAGE_PATH}`;
    const frontPagePath = `${url}/${FRONT_PAGE_PATH}`;
    const oldFoxPagePath = `${url}/${FOX_PAGE_PATH_OLD}`;

    const defaultPagePath =
      organization.defaultPage === 'fox' ? `${url}/${FOX_PAGE_PATH}` : `${url}/${FRONT_PAGE_PATH}`;

    return (
      <Switch>
        <Route exact component={FoxPage} path={foxPagePath} />
        <Route component={FrontPage} path={frontPagePath} />
        <Redirect from={oldFoxPagePath} to={foxPagePath} />
        <Redirect to={defaultPagePath} />
      </Switch>
    );
  }

  render() {
    return (
      <div className="app-Organization">
        <Layout>
          <Layout.Header className="app-Organization__topbar-container">
            <Topbar />
          </Layout.Header>
          <Layout.Content className="app-Organization__content">
            {this.renderContent()}
          </Layout.Content>
        </Layout>
      </div>
    );
  }
}
