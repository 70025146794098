import { Spin } from 'antd';
import React from 'react';
import DocumentTitle from 'react-document-title';
import { Route, Switch } from 'react-router-dom';

import NotFoundPage from 'shared/NotFoundPage';
import Home from './Home';
import Login from './Login';
import Organization from './Organization';

export interface OwnProps {}

export interface InjectedProps {
  readonly isAuthenticated: boolean;
  readonly isAuthStarted: boolean;
  readonly isFetchingSession: boolean;
  readonly loadAuth: () => void;
}

type Props = OwnProps & InjectedProps;

export default class App extends React.Component<Props> {
  UNSAFE_componentWillMount() {
    this.props.loadAuth();
  }

  render() {
    if (this.props.isFetchingSession || !this.props.isAuthStarted) {
      return (
        <div className="app-App">
          <div className="app-App-loading">
            <Spin />
          </div>
        </div>
      );
    }
    if (!this.props.isAuthenticated) {
      return (
        <div className="app-App">
          <Login />
        </div>
      );
    }
    return (
      <div className="app-App">
        <DocumentTitle title="QuickBI" />
        <Switch>
          <Route exact component={Home} path="/" />
          <Route component={Organization} path="/w/:slug/" />
          <Route component={NotFoundPage} />
        </Switch>
      </div>
    );
  }
}
