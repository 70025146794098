import React from 'react';

import { DataExport } from 'types';
import AuthAccess from './AuthAccess';
import FrontAccess from './FrontAccess';
import SharedUrlAccess from './SharedUrlAccess';

export interface Props {
  readonly canEdit: boolean;
  readonly dataExport: DataExport;
  readonly editDataExport: (values: {}) => void;
}

export default class DataExportAccess extends React.Component<Props> {
  editDataExport = (values: {}) => {
    if (!this.props.canEdit) return;
    const { dataExport, editDataExport } = this.props;
    const meta = {
      ...values,
      dataExportName: dataExport.name,
    };
    const data = {
      id: dataExport.id,
      attributes: values,
    };
    editDataExport({ data, meta });
  };

  render() {
    return (
      <div className="app-DataExportAccess">
        <FrontAccess
          canEdit={this.props.canEdit}
          dataExport={this.props.dataExport}
          onChange={this.editDataExport}
        />
        <AuthAccess
          canEdit={this.props.canEdit}
          dataExport={this.props.dataExport}
          onChange={this.editDataExport}
        />
        <SharedUrlAccess
          canEdit={this.props.canEdit}
          dataExport={this.props.dataExport}
          onChange={this.editDataExport}
        />
      </div>
    );
  }
}
