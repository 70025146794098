import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { createSelector, createStructuredSelector } from 'reselect';

import * as auth from 'state/auth';
import * as router from 'state/router';
// import { InjectedProps } from './Topbar';

interface Params {
  readonly slug: string;
}

interface OwnProps extends RouteComponentProps<Params> {}

function selectUrl(state: any, props: OwnProps) {
  return props.match.url;
}

const selectHasNavLinks = createSelector(
  auth.selectCanViewFoxAndFront,
  auth.selectIsFoxHidden,
  auth.selectIsFrontHidden,
  (canViewFoxAndFront, isFoxHidden, isFrontHidden) =>
    canViewFoxAndFront && !isFoxHidden && !isFrontHidden
);

export const selector = createStructuredSelector({
  currentPage: router.selectCurrentPage,
  hasNavLinks: selectHasNavLinks,
  username: auth.selectUsername,
  url: selectUrl,
});

export const actions = {
  onLogOut: auth.logOut,
};

export default connect(selector, actions);
