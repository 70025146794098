import { decamelizeKeys } from 'humps';
import normalize from 'json-api-normalizer';
import qs from 'querystringify';
import build from 'redux-object';

import { formatDate } from 'shared/utils';
import { Credentials, TableView } from 'types';
import { makeApiRequest } from './utils';

// -------
// Typings
// -------

type Value = number | string;
interface TableViewDataItem {
  [key: string]: Value;
}
interface TableViewData {
  data: TableViewDataItem[];
  totalCount: number;
}
export interface DataQueryOptions {
  format: string;
  filter?: string;
  'page[limit]'?: number;
  'page[offset]'?: number;
  sort?: string;
  total_count?: boolean;
}

export interface ValueQueryOptions {
  'page[limit]'?: number;
  'page[offset]'?: number;
}

// -------
// Helpers
// -------

function formatDateValue(dateStr: string) {
  const value = new Date(dateStr);
  return {
    value,
    text: formatDate(value),
  };
}

// ------------------
// Exported functions
// ------------------

export function getTableView(credentials: Credentials, id: string): Promise<TableView> {
  return makeApiRequest<any>(credentials, `table-views/${id}`).then((json: any) =>
    build(normalize(json), 'tableViews', id)
  );
}

export function getTableViewData(
  credentials: Credentials,
  id: string,
  options: DataQueryOptions
): Promise<TableViewData> {
  const query = qs.stringify(options);
  return makeApiRequest<any>(credentials, `table-views/${id}/data?${query}`).then((json: any) => {
    const attrs = json.data.attributes;
    return {
      data: attrs.data,
      totalCount: attrs.total_count,
    };
  });
}

export function getDistinctValues(
  credentials: Credentials,
  tableViewId: string,
  field: string,
  options: ValueQueryOptions,
  dataType: string
): Promise<any[]> {
  const query = qs.stringify(options);
  const params = query ? `?${query}` : '';
  return makeApiRequest<any>(
    credentials,
    `table-views/${tableViewId}/columns/${field}/distinct_values${params}`
  ).then((json: any) => {
    const attrs = json.data.attributes;
    return attrs.data.map((value: string) => {
      if (dataType === 'date') return formatDateValue(value);
      return {
        value,
        text: value,
      };
    });
  });
}

export function saveColumns(
  credentials: Credentials,
  { tableViewId, data }: { tableViewId: string; data: any }
): Promise<any> {
  const jsonAPIFormattedData = {
    data: {
      type: 'table_view_column_lists',
      id: tableViewId,
      attributes: { data: decamelizeKeys(data) },
    },
  };
  return makeApiRequest<any>(
    credentials,
    `table-views/${tableViewId}/columns`,
    'PATCH',
    jsonAPIFormattedData
  );
}
