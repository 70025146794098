import { createBrowserHistory } from 'history';
import { compose, createStore, Store } from 'redux';

import createRootReducer from 'state';
import middleware from './middleware';

export const history = createBrowserHistory();

const createStoreWithMiddleware: any = compose(...middleware)(createStore);

function configureStore(initialState?: any) {
  const rootReducer = createRootReducer(history);

  let store: Store<any>;
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const freezeState = require('redux-freeze-state');
    store = createStoreWithMiddleware(freezeState(rootReducer), initialState);
  } else {
    store = createStoreWithMiddleware(rootReducer, initialState);
  }

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('state', () => {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const createNextRootReducer = require('state').default;
      store.replaceReducer(createNextRootReducer(history));
    });
  }

  return store;
}

export default configureStore();
