export const FOX_PAGE_NAME = 'Warehouse';
export const FOX_PAGE_PATH = 'warehouse';
export const FOX_PAGE_PATH_OLD = 'data-sources';

export const FRONT_PAGE_NAME = 'Reports';
export const FRONT_PAGE_PATH = 'reports';

// Should be same as in 'app/assets/styles/variables.less'.
export const TABLET_WIDTH = 768;
export const TOPBAR_HEIGHT = 55;
export const TAB_BAR_HEIGHT = 30;

export const FRONT_PAGE_SIDEBAR_WIDTH = 190;
