import { Form, FormItem, Input } from 'formik-antd';
import { Button, Modal, Typography } from 'antd';
import { Formik } from 'formik';
import React from 'react';

import ErrorMessage from 'shared/ErrorMessage';
import * as types from 'types';

const { Paragraph } = Typography;

interface OwnProps {}

interface InjectedProps {
  readonly errorMessages: types.ErrorMessage[];
  readonly gsServiceAccountEmail: string;
  readonly hideModal: () => void;
  readonly isSaving: boolean;
  readonly isVisible: boolean;
  readonly saveSourceTableGroup: (data: types.SourceTableGroupSaveData) => void;
}

type Props = OwnProps & InjectedProps;

interface Fields {
  spreadsheet_id_or_url: string;
}

export default class AddGoogleSheet extends React.Component<Props> {
  static initial: Fields = {
    spreadsheet_id_or_url: '',
  };

  validate(values: Fields) {
    const errors: any = {};
    if (!values.spreadsheet_id_or_url) {
      errors.spreadsheet_id_or_url = 'Required';
    }
    return errors;
  }

  handleSave = (values: Fields) => {
    const data = {
      attributes: {
        type: 'google-sheet',
        data: values,
      },
    };
    this.props.saveSourceTableGroup(data);
  };

  renderError = (error: types.ErrorMessage) => {
    if (error.status === '403') {
      return (
        <ErrorMessage
          key={error.title}
          message="Access to Google Sheet was denied"
          description={
            'We were not allowed to get data from your Google Sheet. ' +
            'Please make sure you have shared the Sheet with the email below.'
          }
        />
      );
    }

    if (error.status === '409' && error.title === 'DuplicateColumnNameInGoogleSheet') {
      return (
        <ErrorMessage
          key={error.title}
          message="Duplicate column name in Google Sheet"
          description={
            'Fetching data from Google Sheet failed because some column names appeared more than once. ' +
            'Please make sure all headers in the first row of your sheet have unique names and try again.'
          }
        />
      );
    }

    return (
      <ErrorMessage key={error.title} message={error.title} description={error.detail || ''} />
    );
  };

  renderErrors = () => {
    return (
      <div className="app-ErrorMessages">{this.props.errorMessages.map(this.renderError)}</div>
    );
  };

  renderForm = () => {
    return (
      <Form>
        <FormItem name="spreadsheet_id_or_url">
          <Input name="spreadsheet_id_or_url" size="large" />
        </FormItem>
        <div>It will take some time for the Sheet to be uploaded.</div>
        <div className="app-AddGoogleSheet__modal-footer">
          <Button onClick={this.props.hideModal}>Cancel</Button>
          <Button disabled={this.props.isSaving} htmlType="submit" type="primary">
            {this.props.isSaving ? 'Saving...' : 'Save'}
          </Button>
        </div>
      </Form>
    );
  };

  render() {
    return (
      <div className="app-AddGoogleSheet">
        <Modal
          className="app-AddGoogleSheet__modal"
          destroyOnClose
          footer={null}
          maskClosable={false}
          onCancel={this.props.hideModal}
          title="Add Google Sheet to source tables"
          visible={this.props.isVisible}
          width={800}
        >
          <div className="app-AddGoogleSheet__modal-content">
            {this.renderErrors()}
            <p>
              1. Share the Google Sheet with the following email address (view permission is
              enough):
            </p>
            <Paragraph code copyable>
              {this.props.gsServiceAccountEmail}
            </Paragraph>
            <p>2. Copy the Google Sheet URL and paste it below:</p>
            <Formik
              enableReinitialize
              initialValues={AddGoogleSheet.initial}
              onSubmit={this.handleSave}
              validate={this.validate}
            >
              {this.renderForm}
            </Formik>
          </div>
        </Modal>
      </div>
    );
  }
}
