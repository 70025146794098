import get from 'lodash/get';
import React from 'react';

import ErrorMessage from 'shared/ErrorMessage';
import * as types from 'types';

interface Props {
  readonly errorMessages: types.ErrorMessage[];
}

export default class ErrorMessages extends React.Component<Props> {
  renderError(error: types.ErrorMessage) {
    const title = error.title;
    const detail = get(error, 'detail', '');
    const description = get(error, 'source.parameter', '');
    return (
      <ErrorMessage
        key={`${title}-${detail}-${description}`}
        message={`${title}${title && detail ? ' - ' : ''}${detail}`}
        description={description}
      />
    );
  }

  render() {
    const { errorMessages } = this.props;
    if (!errorMessages.length) {
      return null;
    }
    return <div className="app-ErrorMessages">{errorMessages.map(this.renderError)}</div>;
  }
}
