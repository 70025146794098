import { CloseOutlined } from '@ant-design/icons';
import { Button, Input, Popconfirm, Tooltip } from 'antd';
import classNames from 'classnames';
import React from 'react';
import SortableTree, { changeNodeAtPath, removeNodeAtPath } from 'react-sortable-tree';

import { generateNodeId, getNodeKey } from './utils';

interface Props {
  readonly onChange: (treeData: any[]) => void;
  readonly treeData: any[];
}

function canDrop({ node, nextParent }: any) {
  const parentType = nextParent && nextParent.type;
  if (node.type === 'dataExportGroup') {
    return !parentType;
  }
  if (node.type === 'dataExport') {
    return parentType !== 'dataExport';
  }
  return false;
}

export default class SortableGroups extends React.Component<Props> {
  addNewGroup = () => {
    const newGroup = {
      children: [],
      id: generateNodeId(),
      name: 'New group',
      type: 'dataExportGroup',
    };
    const newTreeData = this.props.treeData.concat(newGroup);
    this.props.onChange(newTreeData);
  };

  generateNodeProps = ({ node, path }: any) => {
    const handleRemove = () => {
      const newTreeData = removeNodeAtPath({
        path,
        getNodeKey,
        treeData: this.props.treeData,
      });
      this.props.onChange(newTreeData);
    };

    const handleNameChange = (event: any) => {
      const name = event.target.value;
      const newTreeData = changeNodeAtPath({
        getNodeKey,
        path,
        treeData: this.props.treeData,
        newNode: { ...node, name },
      });
      this.props.onChange(newTreeData);
    };

    const removeButton =
      node.children && node.children.length ? (
        <Tooltip placement="topRight" title="You can only delete group that doesn't have children">
          <Button
            className="app-SortableGroups__node-remove-button"
            disabled
            icon={<CloseOutlined />}
            key="remove-node-button"
            shape="circle"
            size="small"
          />
        </Tooltip>
      ) : (
        <Popconfirm cancelText="No" okText="Yes" onConfirm={handleRemove} title="Are you sure？">
          <Button
            className="app-SortableGroups__node-remove-button"
            icon={<CloseOutlined />}
            key="remove-node-button"
            shape="circle"
            size="small"
          />
        </Popconfirm>
      );

    const buttons = node.type === 'dataExportGroup' ? [removeButton] : [];

    const className = classNames('app-SortableGroups__node', {
      'app-SortableGroups__group': node.type === 'dataExportGroup',
      'app-SortableGroups__export': node.type === 'dataExport',
    });

    const title =
      node.type === 'dataExportGroup' ? (
        <Input
          className={classNames('app-SortableGroups__node-input', {
            'app-SortableGroups__node-input--error': !node.name,
          })}
          onChange={handleNameChange}
          size="small"
          value={node.name}
        />
      ) : (
        <span>{node.name}</span>
      );

    return {
      buttons,
      className,
      title,
    };
  };

  render() {
    return (
      <div className="app-SortableGroups">
        <Button
          className="app-SortableGroups__add-group-button"
          onClick={this.addNewGroup}
          size="small"
          type="primary"
        >
          Add new group
        </Button>
        <div className="app-SortableGroups__content">
          <SortableTree
            canDrop={canDrop}
            generateNodeProps={this.generateNodeProps}
            getNodeKey={getNodeKey}
            maxDepth={2}
            onChange={this.props.onChange}
            rowHeight={48}
            treeData={this.props.treeData}
          />
        </div>
      </div>
    );
  }
}
