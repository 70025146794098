import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';

import { QBIAggregateFunc, DxPivotField, DxPivotState, PivotAggregate } from 'types';

export function getDefaultDataAreaField(): DxPivotField {
  return {
    area: 'data',
    dataField: null,
    isMeasure: true,
    summaryType: 'count',
  };
}

export function getAggregatesToSave(pivotState: DxPivotState): PivotAggregate[] {
  const dataAreaFields = filter(pivotState.fields, { area: 'data' });
  return dataAreaFields.map((field) => {
    // It's now assumed that the only custom summary type is "count unique". If
    // new custom summary types are added this logic has to change!
    const func = (field.summaryType === 'custom'
      ? 'count_unique'
      : field.summaryType) as QBIAggregateFunc;
    return {
      func,
      field: field.dataField || null,
    };
  });
}

export function getFieldDataToSave(
  pivotState: DxPivotState,
  fieldIdsByField: { [index: string]: string }
) {
  const nonDataAreafields: DxPivotField[] = filter(
    pivotState.fields,
    (field: DxPivotField) => field.area !== 'data'
  );
  // Sort fields by area and area index. The idea is that backend will save
  // pivot field positions according to this order to database and the next
  // time the field data is fetched, pivot grid renders fields in this same
  // order. This means especially that area indices will then be the same as
  // now. Sorting by area first is just for convience: it's just nice that
  // the fields in row area will have the smallest positions and so on (but
  // it's not important).
  const sorted: DxPivotField[] = sortBy(nonDataAreafields, [
    (field: DxPivotField) => {
      const priorities: { [index: string]: number } = { row: 1, column: 2, filter: 3 };
      return priorities[field.area];
    },
    (field: DxPivotField) => field.areaIndex,
  ]);
  return sorted.map((field: DxPivotField) => {
    const dataField = field.dataField as string;
    const id = fieldIdsByField[dataField];
    const sortOrder: '' | 'asc' | 'desc' = field.sortOrder || '';
    return {
      id,
      sortOrder,
      area: field.area === 'filter' ? '' : field.area,
      filterType: field.filterType || 'include',
      filterValues: field.filterValues || [],
    };
  });
}

// Custom summary function for DevExtreme pivot field configuration that
// calculates the number of unique values for a field. See
// https://js.devexpress.com/Documentation/Guide/Widgets/PivotGrid/Summaries/#Custom_Summaries
export function calculateCountUniqueSummary(options: any) {
  if (options.summaryProcess === 'start') {
    options.totalValue = 0;
    options.uniqueValues = [];
  } else if (options.summaryProcess === 'calculate') {
    const isCounted = options.uniqueValues.includes(options.value);
    if (!isCounted) {
      options.uniqueValues.push(options.value);
      options.totalValue += 1;
    }
  } else if (options.summaryProcess === 'finalize') {
    options.uniqueValues = [];
  }
}
