import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

interface IState {
  readonly isCollapsed: boolean;
}

// -------
// Actions
// -------

export const collapse = createAction('app/front/sidebar/collapse');
export const toggle = createAction('app/front/sidebar/toggle');

// ---------
// Selectors
// ---------

function select(state: any): IState {
  return state.front.sidebar;
}

export const selectIsCollapsed = createSelector(
  select,
  (state: IState): boolean => state.isCollapsed
);

// -----
// State
// -----

const initialState: IState = {
  isCollapsed: false,
};
const handlers = {
  [String(collapse)]: (state: IState): IState => ({
    ...state,
    isCollapsed: true,
  }),
  [String(toggle)]: (state: IState): IState => ({
    ...state,
    isCollapsed: !state.isCollapsed,
  }),
};
export default handleActions(handlers, initialState);
