import isNull from 'lodash/isNull';
import isString from 'lodash/isString';
import max from 'lodash/max';
import mean from 'lodash/mean';
import min from 'lodash/min';
import sum from 'lodash/sum';
import orderBy from 'lodash/orderBy';
import values from 'lodash/values';

import { DashboardChartSpecs } from 'types';

// -------------
// aggregateData
// -------------

function count(array: []) {
  return array.length;
}

const methods: { [key: string]: Function } = {
  count,
  max,
  mean,
  min,
  sum,
};

/** Aggregates row data for dashboard charts.
 *
 * @param  {Array}  data    JSON object formatted data from data export
 * @param  {Object} options specifies aggregation fields, method, ordering etc.
 * @return {Array}          aggregated data
 *
 * Example:
 *
 * const data = [
 *   { month: '2018-03', owner: 'Luke', sales: 200, deals: 4 },
 *   { month: '2018-03', owner: 'Han', sales: 300, deals: 2 },
 *   { month: '2018-03', owner: 'Leia', sales: 400, deals: 6 },
 *   { month: '2018-04', owner: 'Luke', sales: 100, deals: 1 },
 *   { month: '2018-04', owner: 'Han', sales: 200, deals: 2 },
 * ];
 *
 * const options = {
 *   field: 'month',
 *   valueFields: ['sales', 'deals'],
 *   method: 'sum',
 * };
 *
 *  returns ->
 * [
 *   { month: '2018-03', sales: 900, deals: 12 },
 *   { month: '2018-04', sales: 300, deals: 3 },
 * ];
 */
export function aggregateData(data: any, options: DashboardChartSpecs['aggregation']) {
  if (!options || !options.field || !options.valueFields) {
    return data;
  }

  const { field, valueFields } = options;
  const methodName = options.method || 'sum';
  const aggregationMethod = methods[methodName];
  const orderByField = options.orderBy || field;
  const orderByDirection = options.orderByDirection || 'asc';

  const aggregated: { [key: string]: any } = {};

  data.forEach((item: any) => {
    const aggregatedBy = item[field];

    if (!aggregated[aggregatedBy]) {
      // initialize values
      aggregated[aggregatedBy] = {
        [field]: aggregatedBy,
        [orderByField]: item[orderByField],
      };
      valueFields.forEach((valueField: string) => {
        aggregated[aggregatedBy][valueField] = [];
      });
    }
    valueFields.forEach((valueField: string) => {
      if (!isNull(item[valueField])) {
        aggregated[aggregatedBy][valueField].push(item[valueField]);
      }
    });
  });

  const rv = values(aggregated).map((item) => {
    valueFields.forEach((valueField: string) => {
      item[valueField] = aggregationMethod(item[valueField]);
    });
    return item;
  });

  return orderBy(rv, orderByField, orderByDirection);
}

// ------------------
// getChartTitleSpecs
// ------------------

const SUBTITLE_DEFAULTS = {
  font: {
    size: 15,
  },
  text: '',
};

const TITLE_DEFAULTS = {
  font: {
    size: 20,
  },
  text: '',
};

export function getChartTitleSpecs(title: string | any) {
  if (isString(title)) {
    return {
      ...TITLE_DEFAULTS,
      text: title,
    };
  }
  const { subtitle, ...titleProps } = title;
  return {
    ...TITLE_DEFAULTS,
    ...titleProps,
    subtitle: {
      ...SUBTITLE_DEFAULTS,
      ...subtitle,
    },
  };
}

// --------------------
// getChartTooltipSpecs
// --------------------

const TOOLTIP_DEFAULTS = {
  enabled: true,
  font: {
    size: 12,
  },
};

export function getChartTooltipSpecs(specs: DashboardChartSpecs['tooltip']) {
  return { ...TOOLTIP_DEFAULTS, ...specs };
}
