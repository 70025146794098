import { connect } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';

import * as auth from 'state/auth';
import * as dataSource from 'state/data/dataSource';
import * as editorTabs from 'state/fox/editorTabs';
import modals from 'state/modals';
import { AutoCompleteItem } from 'types';
import { OwnProps } from './SqlEditor';

function selector() {
  function tabIdSelector(state: any, props: OwnProps) {
    return props.tabId;
  }

  const selectAutoCompleteWordList = createSelector(
    dataSource.selectTableNames,
    dataSource.selectColumnNames,
    (tables, columns) => {
      const items: AutoCompleteItem[] = [];
      tables.forEach((table) => {
        items.push({ value: table, meta: 'table' });
      });
      columns.forEach((column) => {
        items.push({ value: column, meta: 'column' });
      });
      return items;
    }
  );

  return createStructuredSelector({
    autoCompleteWordList: selectAutoCompleteWordList,
    canEditDataExports: auth.selectCanEditDataExports,
    canQuery: auth.selectCanQuery,
    isDataExport: editorTabs.selectIsDataExportFactory(tabIdSelector),
    isValidating: editorTabs.selectIsValidatingFactory(tabIdSelector),
    needsValidation: editorTabs.selectNeedsValidationFactory(tabIdSelector),
    sql: editorTabs.selectSqlFactory(tabIdSelector),
  });
}

const actions = {
  changeSql: editorTabs.changeTabSql,
  showDataExportModal: modals.showDataExport,
  validateSql: editorTabs.validateTabSql,
};

export default connect(selector, actions);
