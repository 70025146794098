import 'devextreme/ui/pivot_grid';
import renderer from 'devextreme/core/renderer';

import { DxPivotField } from 'types';

export default function getPivotRenderer(
  domNode: any,
  fields: DxPivotField[],
  loadFunction: any,
  extraProps: {}
) {
  const dataSource: any = {
    fields,
    load: loadFunction,
  };
  return renderer(domNode)
    .dxPivotGrid({
      ...extraProps,
      dataSource,
      allowExpandAll: true,
      allowSortingBySummary: true,
      allowSorting: true,
      allowFiltering: true,
      export: {
        fileName: 'pivot',
      },
      headerFilter: {
        allowSearch: true,
      },
      showBorders: true,
      showColumnTotals: false,
      showRowTotals: false,
      showColumnGrandTotals: true,
      showRowGrandTotals: true,
      fieldChooser: {
        enabled: false,
      },
      fieldPanel: {
        showColumnFields: true,
        showDataFields: false,
        showFilterFields: false,
        showRowFields: true,
        allowFieldDragging: true,
        texts: {
          columnFieldArea: 'Drop column fields here',
          rowFieldArea: 'Drop row fields here',
        },
        visible: true,
      },
      texts: {
        grandTotal: 'Total',
      },
    })
    .dxPivotGrid('instance');
}
