import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as auth from 'state/auth';
import * as dataExports from 'state/data/dataExports';
import * as dataExportDetails from 'state/fox/dataExportDetails';
import * as editorTabs from 'state/fox/editorTabs';

const selector = createStructuredSelector({
  canEdit: auth.selectCanEditDataExports,
  dataExport: dataExports.selectDataExportFactory(dataExportDetails.selectDataExportId),
  visible: dataExportDetails.selectVisible,
});

const actions = {
  closeDataExportDetails: dataExportDetails.close,
  deleteDataExport: dataExports.remove,
  editDataExport: dataExports.edit,
  showSqlInEditor: editorTabs.openTab,
};

export default connect(selector, actions);
