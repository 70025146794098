import get from 'lodash/get';
import { connect } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';

import * as api from 'state/api';
import * as dataSource from 'state/data/dataSource';
import modals from 'state/modals';

const groupKeySelector = createSelector(modals.selectDataSourceUploadData, (modalData: any) =>
  get(modalData, 'groupKey', '')
);

export const selector = createStructuredSelector({
  errorMessages: api.selectDataSourceUploadErrors,
  groupKey: groupKeySelector,
  isUploading: api.selectIsDataSourceUploadPending,
  isVisible: modals.selectIsDataSourceUploadVisible,
  uploadInfo: dataSource.selectDataSourceUploadInfo,
});

const actions = {
  hideModal: modals.hideDataSourceUpload,
  uploadFile: dataSource.upload,
};

export default connect(selector, actions);
