import 'devextreme/ui/pivot_grid_field_chooser';
import renderer from 'devextreme/core/renderer';

export default function getGridFieldChooserRenderer(domNode: any, dataSource: any, extraProps: {}) {
  return renderer(domNode)
    .dxPivotGridFieldChooser({
      ...extraProps,
      dataSource,
      headerFilter: {
        allowSearch: true,
      },
    })
    .dxPivotGridFieldChooser('instance');
}
