import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

interface State {
  readonly dataExportId: string | null;
  readonly visible: boolean;
}

// -------
// Actions
// -------

export const close = createAction('app/fox/dataExportDetails/close');
export const open = createAction('app/fox/dataExportDetails/open');
export const toggle = createAction('app/fox/dataExportDetails/toggle');

// ---------
// Selectors
// ---------

function select(state: any): State {
  return state.fox.dataExportDetails;
}

export const selectDataExportId = createSelector(
  select,
  (state: State): string => state.dataExportId || ''
);

export const selectVisible = createSelector(select, (state: State): boolean => state.visible);

export const selectVisibleDataExportId = createSelector(select, (state: State): string =>
  state.visible ? state.dataExportId || '' : ''
);

// -----
// State
// -----

const initialState: State = {
  dataExportId: null,
  visible: false,
};
const handlers = {
  [String(close)]: (state: State): State => ({
    ...state,
    visible: false,
  }),
  [String(open)]: (state: State, action: ReduxActions.Action<string>): State => ({
    ...state,
    dataExportId: action.payload,
    visible: true,
  }),
  [String(toggle)]: (state: State, action: ReduxActions.Action<string>): State => {
    const currentId = state.dataExportId;
    const newId = action.payload;
    const visible = newId === currentId ? !state.visible : true;
    return {
      ...state,
      visible,
      dataExportId: newId,
    };
  },
};
export default handleActions(handlers, initialState);
