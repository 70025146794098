import { Credentials } from 'types';
import { makeApiRequest } from './utils';

// -----
// types
// -----

type Value = number | string;
interface SqlDataItem {
  [key: string]: Value;
}
interface SqlData {
  data: SqlDataItem[];
  totalCount: number;
}
export interface SqlQueryOptions {
  filter?: string;
  limit?: number;
  offset?: number;
  sort?: string;
  sql?: string;
  total_count?: boolean;
}

// ------------
// api requests
// ------------

export function validateSql(credentials: Credentials, sql: string): Promise<any> {
  const data = {
    data: {
      type: 'query_validations',
      attributes: { sql },
    },
  };
  return makeApiRequest<any>(credentials, 'validate-query', 'POST', data).then((json: any) => {
    const { columns, column_types, error_msg, formatted_sql, is_sql_valid } = json.data.attributes;
    return {
      columns,
      columnTypes: column_types,
      errorMessage: error_msg,
      formattedSql: formatted_sql,
      isValid: is_sql_valid,
    };
  });
}

export function postSql(credentials: Credentials, options: SqlQueryOptions): Promise<SqlData> {
  const data = {
    data: {
      type: 'queries',
      attributes: { ...options },
    },
  };
  return makeApiRequest<any>(credentials, 'queries', 'POST', data).then((json: any) => {
    const attrs = json.data.attributes;
    if (options.total_count) {
      return {
        data: attrs.data,
        totalCount: attrs.total_count,
      };
    }
    return attrs.data;
  });
}
