import { CloseOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import findIndex from 'lodash/findIndex';
import React from 'react';
import SortableTree, { removeNodeAtPath } from 'react-sortable-tree';

import { generateNodeId, getNodeKey } from './utils';
import ViewEditor from './ViewEditor';

interface Props {
  readonly onChange: any;
  readonly views: any;
}

export default class ViewsEditor extends React.Component<Props> {
  addNewView = () => {
    const { onChange, views } = this.props;
    const newView = {
      dataViewId: null,
      id: generateNodeId(),
      kind: 'iframe',
      text: 'New view',
      type: 'view',
      url: '',
    };
    onChange(views.concat(newView));
  };

  handleViewChange = (id: string, data: any) => {
    const { onChange, views } = this.props;
    const index = findIndex(views, (view: any) => view.id === id);
    const updatedView = {
      ...views[index],
      ...data,
    };
    const updatedViews = Object.assign([...views], { [index]: updatedView });
    onChange(updatedViews);
  };

  generateNodeProps = ({ node, path }: any) => {
    const { onChange, views } = this.props;
    const handleRemove = () => {
      const newViewsData = removeNodeAtPath({
        path,
        getNodeKey,
        treeData: views,
      });
      onChange(newViewsData);
    };

    const handleViewChange = (data: any) => {
      this.handleViewChange(node.id, data);
    };

    const removeButton = (
      <Popconfirm cancelText="No" okText="Yes" onConfirm={handleRemove} title="Are you sure？">
        <Button icon={<CloseOutlined />} key="remove-node-button" shape="circle" size="small" />
      </Popconfirm>
    );

    return {
      buttons: [removeButton],
      className: 'app-ViewsEditor__node',
      title: <ViewEditor onChange={handleViewChange} view={node} />,
    };
  };

  render() {
    const { onChange, views } = this.props;

    return (
      <div className="app-ViewsEditor">
        <h3 className="app-ViewsEditor__header">
          Views
          <Button
            className="app-ViewsEditor__add-view-button"
            onClick={this.addNewView}
            size="small"
            type="primary"
          >
            Add new view
          </Button>
        </h3>
        <SortableTree
          generateNodeProps={this.generateNodeProps}
          getNodeKey={getNodeKey}
          maxDepth={1}
          onChange={onChange}
          rowHeight={120}
          treeData={views}
        />
      </div>
    );
  }
}
