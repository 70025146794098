import React from 'react';
import AceEditor from 'react-ace';

import 'brace/mode/yaml';
import 'brace/theme/sqlserver';

export interface Props {
  readonly height?: number;
  readonly id: string;
  readonly onChange: (json: string) => void;
  readonly value: string;
  readonly width?: number;
}

export default class SpecsEditor extends React.Component<Props> {
  static defaultProps = {
    height: 490,
    width: 960,
  };

  editorComponent: any;

  render() {
    const { id, onChange, value } = this.props;
    return (
      <div className="app-SpecsEditor">
        <AceEditor
          editorProps={{ $blockScrolling: true }}
          focus
          height={`${this.props.height}px`}
          highlightActiveLine={false}
          mode="yaml"
          name={`specs-editor-${id}`}
          onChange={onChange}
          ref={(ace) => {
            this.editorComponent = ace;
          }}
          showPrintMargin={false}
          tabSize={2}
          theme="sqlserver"
          value={value}
          width={`${this.props.width}px`}
        />
      </div>
    );
  }
}
