import { Alert, Spin } from 'antd';
import React from 'react';
import TableauReport from 'tableau-react';
import makeComponentTrashable from 'trashable-react';

import { getTableauTicket, TicketData } from 'services/api/views';
import { ApiError, ApiErrorType, Credentials, View as ViewType } from 'types';

export interface OwnProps {
  readonly view: ViewType;
}

export interface InjectedProps {
  readonly credentials: Credentials;
  readonly registerPromise: Function;
}

interface Error {
  description: string;
  message: string;
  type: 'error' | 'warning';
}

interface State {
  error: Error | null;
  ticket: string | null;
}

export type Props = OwnProps & InjectedProps;

class TableauView extends React.Component<Props, State> {
  state: State = { error: null, ticket: null };

  componentDidMount() {
    this.fetchTicket();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.view.id !== this.props.view.id) {
      this.fetchTicket();
    }
  }

  fetchTicket = () => {
    const { credentials, registerPromise, view } = this.props;
    this.setState({ error: null, ticket: null });
    registerPromise(getTableauTicket(credentials, view.id)).then(
      this.handleTicketResponse,
      this.handleError,
    );
  };

  handleTicketResponse = (data: TicketData) => {
    if (data.viewId === this.props.view.id) {
      this.setState({ error: null, ticket: data.ticket });
    }
  };

  handleError = (apiError: ApiError) => {
    const error = this.parseError(apiError);
    this.setState({ error });
  };

  parseError = (error: ApiError) => {
    if (error.type === ApiErrorType.Forbidden) {
      return {
        description:
          "Sorry, it seems you don't have access to this view yet! Please " +
          'contact QuickBI support to get access.',
        message: 'Permission denied',
        type: 'warning' as any,
      };
    }
    return {
      description:
        'Sorry, something went wrong! Please try again later or contact ' + 'QuickBI support.',
      message: 'Loading view failed',
      type: 'error' as any,
    };
  };

  render() {
    const { view } = this.props;
    const { error, ticket } = this.state;
    const options = {
      height: '100%',
      width: '100%',
    };
    if (error) {
      return (
        <div className="app-TableauView">
          <Alert description={error.description} message={error.message} type={error.type} />
        </div>
      );
    }
    return (
      <div className="app-TableauView">
        {!ticket && (
          <div className="app-TableauView-loading">
            <Spin />
          </div>
        )}
        {ticket && (
          <TableauReport
            className="app-TableauView-report"
            options={options}
            token={ticket}
            url={view.url}
          />
        )}
      </div>
    );
  }
}

export default makeComponentTrashable(TableauView);
