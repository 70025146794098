import { Layout } from 'antd';
import React from 'react';
import DocumentTitle from 'react-document-title';
import { Redirect, Route, Switch } from 'react-router-dom';

import { FRONT_PAGE_NAME, FRONT_PAGE_SIDEBAR_WIDTH } from 'shared/constants';
import NoPermissionPage from 'shared/NoPermissionPage';
import NotFoundPage from 'shared/NotFoundPage';
import { UserOrganization } from 'types';
import Sidebar from './Sidebar';
import View from './View';

interface Props {
  readonly canView: boolean;
  readonly defaultViewPath: string | null;
  readonly isOrganizationRoot: boolean;
  readonly organization: UserOrganization | null;
  readonly url: string;
}

export default class FrontPage extends React.Component<Props> {
  render() {
    const { canView, defaultViewPath, isOrganizationRoot, organization, url } = this.props;
    if (!canView) return <NoPermissionPage />;
    const renderView = (props: any) => <View organization={organization} {...props} />;
    // No need to show home link because organization pages are listed on sidebar
    // so user can just select one of them.
    const renderNotFoundPage = () => <NotFoundPage showHomeLink={false} />;
    return (
      <Layout className="app-FrontPage">
        <DocumentTitle title={`${FRONT_PAGE_NAME} - QuickBI`} />
        <Sidebar />
        <Layout.Content
          className="app-FrontPage__content"
          style={{ marginLeft: FRONT_PAGE_SIDEBAR_WIDTH }}
        >
          <Switch>
            <Route exact render={renderView} path={`${url}/:viewGroupSlug/:viewIndex`} />
            {isOrganizationRoot ? (
              defaultViewPath && <Redirect to={defaultViewPath} />
            ) : (
              <Route render={renderNotFoundPage} />
            )}
          </Switch>
        </Layout.Content>
      </Layout>
    );
  }
}
