import type { CredentialResponse } from '@react-oauth/google';

import { GoogleLogin } from '@react-oauth/google';
import React from 'react';

export interface Props {
  readonly onSuccess: (token: string) => void;
}

export default class GoogleLoginButton extends React.Component<Props> {
  handleSuccess = (response: CredentialResponse) => {
    const { credential: token } = response;
    if (token) {
      this.props.onSuccess(token);
    }
  };

  handleFailure = () => {
    // User should already know why the login failed (for example they closed
    // the popup window themselves) so no need to e.g. show notification.
  };

  render() {
    return (
      <GoogleLogin
        onSuccess={this.handleSuccess}
        onError={this.handleFailure}
        useOneTap={false}
        theme="outline"
        shape="pill"
        size="large"
        text="continue_with"
      />
    );
  }
}
