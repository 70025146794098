import { Collapse } from 'antd';
import includes from 'lodash/includes';
import React from 'react';
import Highlight from 'react-highlighter';
import TimeAgo from 'react-timeago';

import { formatSql } from 'services/sql';
import PermissionCheck from 'shared/PermissionCheck';
import ShowSqlInEditorButton from 'shared/ShowSqlInEditorButton';
import { formatNumber } from 'shared/utils';
import * as types from 'types';
import GroupDropdown from './GroupDropdown';

const Panel = Collapse.Panel;

interface Props {
  readonly activeKeys: string[];
  readonly matchingKeys: string[];
  readonly name?: string;
  readonly onCollapseChange: (activeKeys: string[]) => void;
  readonly search: string;
  readonly sourceTableGroup: types.SourceTableGroup;
  readonly usesPostgres: boolean;
}

export default class SourceTableGroup extends React.Component<Props> {
  getSql = (sourceTable: types.SourceTable) => {
    const columns = sourceTable.columns.map((column) => {
      const hasUpperCase = column.name.toLowerCase() != column.name;
      const hasSpaces = column.name.indexOf(' ') >= 0;
      const useQuotes = hasSpaces || (this.props.usesPostgres && hasUpperCase);
      const columnName = useQuotes ? `"${column.name}"` : column.name;
      return `${sourceTable.alias}.${columnName}`;
    });
    const sql = `SELECT ${columns.join(',\n')} FROM ${sourceTable.name} AS ${sourceTable.alias}`;
    return formatSql(sql);
  };

  renderColumnMetadata = (column: types.SourceTableColumn) => {
    return (
      <div className="app-SourceTableGroup__table-column-metadata">
        {column.dataType}
        {!column.isNullable && ', not null'}
      </div>
    );
  };

  renderTableHeader = (sourceTable: types.SourceTable) => {
    return (
      <div className="app-SourceTableGroup__table">
        <div className="app-SourceTableGroup__table-name">
          <Highlight search={this.props.search}>{sourceTable.name}</Highlight>
        </div>
        <div className="app-SourceTableGroup__table-rowcount">
          {formatNumber(sourceTable.rowCount)} rows
        </div>
        <ShowSqlInEditorButton
          values={{
            name: `[${sourceTable.name}]`,
            sql: this.getSql(sourceTable),
          }}
        />
      </div>
    );
  };

  renderTableColumns = (sourceTable: types.SourceTable) => {
    return (
      <div className="app-SourceTableGroup__table-columns">
        {sourceTable.columns.map((column) => (
          <div className="app-SourceTableGroup__table-column" key={column.name}>
            <Highlight search={this.props.search}>{column.name}</Highlight>
            {this.props.usesPostgres && this.renderColumnMetadata(column)}
          </div>
        ))}
      </div>
    );
  };

  renderSourceTable = (sourceTable: types.SourceTable) => {
    const { matchingKeys, search } = this.props;
    if (search && !includes(matchingKeys, sourceTable.id)) {
      return null;
    }
    return (
      <Panel
        className="app-SourceTable__panel"
        header={this.renderTableHeader(sourceTable)}
        key={sourceTable.id}
        showArrow={false}
      >
        {this.renderTableColumns(sourceTable)}
      </Panel>
    );
  };

  render() {
    const { activeKeys, name, onCollapseChange, sourceTableGroup } = this.props;
    return (
      <div className="app-SourceTableGroup" key={sourceTableGroup.id}>
        <div className="app-SourceTableGroup__header">
          <div>{name}</div>
          <PermissionCheck fox="admin">
            <GroupDropdown sourceTableGroup={sourceTableGroup} />
          </PermissionCheck>
        </div>
        <div className="app-SourceTableGroup__info">
          <div>
            Updated <TimeAgo date={`${sourceTableGroup.mergedWithNewDataAt}Z`} />
          </div>
        </div>
        <Collapse activeKey={activeKeys} bordered={false} onChange={onCollapseChange}>
          {sourceTableGroup.sourceTables.map(this.renderSourceTable)}
        </Collapse>
      </div>
    );
  }
}
