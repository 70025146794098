import { connect } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';

import * as api from 'state/api';
import * as dataExports from 'state/data/dataExports';
import modals from 'state/modals';
import { DataExportGroupingItem, DataExportGroup } from 'types';

function getTreeItemData(item: DataExportGroupingItem) {
  return {
    expanded: item.type === 'dataExportGroup',
    id: item.id,
    name: item.name,
    type: item.type,
  };
}

const treeDataSelector = createSelector(
  dataExports.selectDataExportGrouping,
  (dataExportGrouping) =>
    dataExportGrouping.map((item) => {
      return {
        ...getTreeItemData(item),
        children:
          item.type === 'dataExportGroup'
            ? (item as DataExportGroup).dataExports.map(getTreeItemData)
            : undefined,
      };
    })
);

const selector = createStructuredSelector({
  errorMessages: api.selectSaveDataExportGroupingErrors,
  isSaving: api.selectIsSaveDataExportGroupingPending,
  isVisible: modals.selectIsDataExportGroupEditorVisible,
  treeData: treeDataSelector,
});

const actions = {
  fetchDataExports: dataExports.fetch,
  hideEditor: modals.hideDataExportGroupEditor,
  saveDataExportGrouping: dataExports.saveGrouping,
  showEditor: modals.showDataExportGroupEditor,
};

export default connect(selector, actions);
