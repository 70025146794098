import normalize from 'json-api-normalizer';

import { ApiError, ApiErrorType, Credentials, ViewCategory } from 'types';
import { makeApiRequest } from './utils';

export function getViews(credentials: Credentials): Promise<ViewCategory[]> {
  return makeApiRequest<any>(credentials, 'view-categories').then((json) =>
    normalize(json, { endpoint: '/view-categories' })
  );
}

export function saveViews(credentials: Credentials, data: {}): Promise<ViewCategory[]> {
  const jsonAPIFormattedData = {
    data: {
      type: 'sidebars',
      id: credentials.organizationId,
      attributes: { data },
    },
  };
  return makeApiRequest<any>(credentials, 'sidebar', 'PATCH', jsonAPIFormattedData);
}

export interface TicketData {
  ticket: string;
  viewId: string;
}

export function getTableauTicket(credentials: Credentials, viewId: string): Promise<TicketData> {
  const baseUrl = process.env.API_URL;
  const url = `${baseUrl}/organizations/${credentials.organizationId}/views/${viewId}/tableau_ticket`;
  const csrfToken = credentials.csrfToken as string;
  return window
    .fetch(url, {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/vnd.api+json',
        'X-CSRF-TOKEN': csrfToken,
      },
    })
    .then((response: any) => response.json())
    .then((json: any) => {
      if ('errors' in json) {
        const error = json.errors[0];
        if (error.title === 'Forbidden') {
          throw new ApiError(ApiErrorType.Forbidden);
        }
        throw new ApiError();
      }
      return {
        viewId,
        ticket: json.data.attributes.ticket,
      };
    });
}
