import { connect } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';

import * as auth from 'state/auth';
import * as editorTabs from 'state/fox/editorTabs';
import { OwnProps } from './Table';

/**
 * Number formatter for devextreme DataGrid column values.
 * Splits long numbers into chunks oof three and makes sure period is used as decimal separator.
 *
 * @param number - The number to format
 * @returns The given number as string in easy to read format.
 */
function formatNumber(number: Number): string {
  return Number(number).toLocaleString('fi-FI', { maximumFractionDigits: 20 }).replace(',', '.');
}

function getColumnFormat(dataType: string) {
  if (dataType === 'number') {
    return { formatter: formatNumber };
  }
  if (dataType === 'date') {
    return 'yyyy-MM-dd';
  }
  if (dataType === 'datetime') {
    // NOTE: This might change the precision and format of the original value
    // a little if it contains milliseconds or timezone info.
    // For example: "2020-12-14 10:50:57.557Z" -> "2020-12-14T 10:50:57"
    // This is a limitation of the current solution we are willing to accept
    // to get the devextreme datetime format benefits without too much
    // extra work for rarely used formats.
    return 'yyyy-MM-dd HH:mm:ss';
  }
  return null;
}

function selector() {
  function tabIdSelector(state: any, props: OwnProps) {
    return props.tabId;
  }

  const columnsSelector = createSelector(
    editorTabs.selectColumnsFactory(tabIdSelector),
    editorTabs.selectColumnTypesFactory(tabIdSelector),
    (columns: string[], columnTypes: string[]) =>
      columns.map((column: string, index: number) => ({
        caption: column,
        dataField: column,
        dataType: columnTypes[index],
        format: getColumnFormat(columnTypes[index]),
        showEditorAlways: false,
      }))
  );

  return createStructuredSelector({
    columns: columnsSelector,
    credentials: auth.selectCredentials,
    dataExportId: editorTabs.selectDataExportIdFactory(tabIdSelector),
    isValidating: editorTabs.selectIsValidatingFactory(tabIdSelector),
    sql: editorTabs.selectValidSqlFactory(tabIdSelector),
  });
}

export default connect<{}, {}, OwnProps>(selector);
