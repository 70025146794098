import { createLogic } from 'redux-logic';

import * as notifications from 'services/notifications';
import * as api from 'services/api';
import * as auth from 'state/auth';
import { ApiError, ApiErrorType, AuthUser, Credentials } from 'types';

const logIn = createLogic({
  type: String(auth.logIn),
  process({ action }, dispatch, done) {
    api
      .logIn(action.payload)
      .then((data) => dispatch(auth.logIn.success(data)))
      .catch((error) => dispatch(auth.logIn.failure(error)))
      .then(() => done());
  },
});

const logInGoogle = createLogic({
  type: String(auth.logInGoogle),
  process({ action }, dispatch, done) {
    api
      .logInGoogle(action.payload)
      .then((data) => dispatch(auth.logInGoogle.success(data)))
      .catch((error: ApiError) => {
        if (error.type === ApiErrorType.EmailNotFound) {
          notifications.show('google-login-email-not-found-failure');
        }
        if (error.type === ApiErrorType.NoGoogleEmail) {
          notifications.show('no-google-email-failure');
        }
        if (error.type === ApiErrorType.GoogleLoginFailed) {
          notifications.show('google-login-failure');
        }
        dispatch(auth.logInGoogle.failure(error));
      })
      .then(() => done());
  },
});

const logOut = createLogic({
  type: String(auth.logOut),
  process({ getState }, dispatch, done) {
    const state = getState();
    const credentials: Credentials = auth.selectCredentials(state);
    const user: AuthUser | null = auth.selectUser(state);
    if (user) {
      api
        .logOut(user.id, credentials)
        .then((data) => dispatch(auth.logOut.success(data)))
        .catch((error) => dispatch(auth.logOut.failure(error)))
        .then(() => done());
    }
  },
});

const load = createLogic({
  type: String(auth.load),
  process(state, dispatch, done) {
    api
      .loadAuthenticatedUser()
      .then((user: AuthUser | null) => dispatch(auth.load.success(user)))
      .catch((error) => dispatch(auth.load.failure(error)))
      .then(() => done());
  },
});

export default [load, logIn, logInGoogle, logOut];
