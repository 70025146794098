import isString from 'lodash/isString';
import keyBy from 'lodash/keyBy';
import map from 'lodash/map';
import values from 'lodash/values';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

import { reset } from 'state/data';
import { DataExport, DataExportGroupRaw, DataExportGroupingItem } from 'types';

interface State {
  byId: { [id: string]: DataExport };
  grouping: [];
}

// -------
// Actions
// -------

export const fetch = createAction('app/data/dataExportsForFront/fetch');

// ---------
// Selectors
// ---------

export function select(state: any): State {
  return state.data.dataExportsForFront;
}

export const selectDataExports = createSelector(select, (state: State): DataExport[] =>
  values(state.byId)
);

export const selectDataExportGrouping = createSelector(
  select,
  (state: State): DataExportGroupingItem[] => {
    const { byId, grouping } = state;
    return map(grouping, (item: string | DataExportGroupRaw) => {
      if (isString(item)) {
        return {
          ...byId[item],
          type: 'dataExport',
        };
      }
      return {
        dataExports: map(item.dataViewIds, (id) => ({
          ...byId[id],
          type: 'dataExport',
        })),
        id: item.groupId,
        name: item.groupName,
        type: 'dataExportGroup',
      };
    });
  }
);

// -----
// State
// -----

const initialState: State = {
  byId: {},
  grouping: [],
};

const handlers = {
  [`${fetch}.failure`]: (): State => initialState,
  [`${fetch}.success`]: (state: State, action: any): State => {
    const { dataExports, grouping } = action.payload;
    return {
      grouping,
      byId: keyBy(dataExports, 'id'),
    };
  },
  [String(reset)]: (): State => initialState,
};

export default handleActions(handlers, initialState);
