import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware } from 'redux';
import { createLogicMiddleware } from 'redux-logic';

import logic from 'logic';
import { history } from 'store';
import { sentryReduxEnhancer } from './sentry';

const storeEnhancers = [applyMiddleware(routerMiddleware(history), createLogicMiddleware(logic))];

if (process.env.NODE_ENV === 'production') {
  // Because Sentry uses a redux enhancer, it should not be wrapped with applyMiddleware.
  storeEnhancers.push(sentryReduxEnhancer);
} else {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const createLogger = require('redux-logger').createLogger;
  const loggerMiddleware = createLogger({
    collapsed: true,
    duration: true,
  });
  storeEnhancers.push(applyMiddleware(loggerMiddleware));
}

export default storeEnhancers;
