import React from 'react';

interface Props {}

export class NoPermissionPage extends React.Component<Props> {
  render() {
    return (
      <div className="app-NoPermissionPage">
        <h2 className="app-NoPermissionPage-header">
          You do not have permission to view this page!
        </h2>
      </div>
    );
  }
}

export default NoPermissionPage;
