import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as api from 'state/api';
import * as auth from 'state/auth';
import * as dashboards from 'state/data/dashboards';
import modals from 'state/modals';
import { OwnProps } from './DashboardView';

function selectDashboardId(state: any, props: OwnProps) {
  return props.id;
}

export const selector = createStructuredSelector({
  canEdit: auth.selectIsAdminFront,
  credentials: auth.selectCredentials,
  dashboard: dashboards.selectDashboardFactory(selectDashboardId),
  isFetching: api.selectIsFetchDashboardsPending,
});

const actions = {
  fetchDashboard: dashboards.fetch,
  showDashboardEditor: modals.showDashboardEditor,
};

export default connect(selector, actions);
