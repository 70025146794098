import { connect } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';

import { canEdit, isAdmin } from 'services/auth';
import * as auth from 'state/auth';
import { InjectedProps, OwnProps } from './PermissionCheck';

export function selector() {
  function requiredFoxLevelSelector(state: any, props: OwnProps) {
    return props.fox;
  }

  function requiredFrontLevelSelector(state: any, props: OwnProps) {
    return props.front;
  }

  const canAccessSelector = createSelector(
    requiredFoxLevelSelector,
    requiredFrontLevelSelector,
    auth.selectFoxPermissionLevel,
    auth.selectFrontPermissionLevel,
    (requiredFoxLevel, requiredFrontLevel, foxPermissionLevel, frontPermissionLevel) => {
      if (requiredFoxLevel && requiredFoxLevel === 'edit') {
        return canEdit(foxPermissionLevel);
      }
      if (requiredFoxLevel && requiredFoxLevel === 'admin') {
        return isAdmin(foxPermissionLevel);
      }
      if (requiredFrontLevel && requiredFrontLevel === 'admin') {
        return isAdmin(frontPermissionLevel);
      }
      return false;
    }
  );

  return createStructuredSelector({
    canAccess: canAccessSelector,
  });
}

export default connect<InjectedProps, {}, {}>(selector);
