import { Input, Spin } from 'antd';
import some from 'lodash/some';
import toLower from 'lodash/toLower';
import React from 'react';

import * as types from 'types';
import SourceTableGroup from './SourceTableGroup';

const Search = Input.Search;

export interface OwnProps {}

export interface InjectedProps {
  readonly currentOrganizationId: string | null;
  readonly isFetching: boolean;
  readonly sourceTables: types.SourceTable[];
  readonly sourceTableGroups: types.SourceTableGroup[];
  readonly usesPostgres: boolean;
}

type Props = OwnProps & InjectedProps;

interface State {
  activeKeys: string[];
  matchingKeys: string[];
  search: string;
}

export default class SourceTables extends React.Component<Props, State> {
  state: State = {
    activeKeys: [],
    matchingKeys: [],
    search: '',
  };

  componentDidUpdate(prevProps: Props) {
    if (prevProps.currentOrganizationId !== this.props.currentOrganizationId) {
      this.setState({
        activeKeys: [],
        matchingKeys: [],
        search: '',
      });
    }
  }

  handleCollapseChange = (activeKeys: string[]) => {
    this.setState({ activeKeys });
  };

  handleSearch = (search: string) => {
    const matchingTables = this.getMatchingTables(search);
    const matchingKeys = matchingTables.map((table) => table.id);
    const activeKeys = search ? matchingKeys : [];
    this.setState({ activeKeys, matchingKeys, search });
  };

  getSourceTableGroupName = (sourceTableGroup: types.SourceTableGroup) => {
    const { sourceTableGroups } = this.props;
    if (sourceTableGroups.length === 1 && toLower(sourceTableGroups[0].name) === 'default') {
      return 'Source tables';
    }
    return sourceTableGroup.name;
  };

  getMatchingTables = (search: string = this.state.search) => {
    if (!search) {
      return this.props.sourceTables;
    }

    return this.props.sourceTables.filter(
      (table) =>
        table.name.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
        some(
          table.columns,
          (column) => column.name.toLowerCase().indexOf(search.toLowerCase()) > -1
        )
    );
  };

  renderSourceTableGroup = (sourceTableGroup: types.SourceTableGroup) => {
    return (
      <SourceTableGroup
        activeKeys={this.state.activeKeys}
        key={sourceTableGroup.id}
        matchingKeys={this.state.matchingKeys}
        name={this.getSourceTableGroupName(sourceTableGroup)}
        onCollapseChange={this.handleCollapseChange}
        search={this.state.search}
        sourceTableGroup={sourceTableGroup}
        usesPostgres={this.props.usesPostgres}
      />
    );
  };

  render() {
    return (
      <div className="app-SourceTables">
        <div className="app-SourceTables__search-container">
          {Boolean(this.props.sourceTables.length) && (
            <Search onSearch={this.handleSearch} size="small" />
          )}
        </div>
        {this.props.isFetching && (
          <div className="app-SourceTables__loading">
            <Spin />
          </div>
        )}
        {this.props.sourceTableGroups.map(this.renderSourceTableGroup)}
      </div>
    );
  }
}
