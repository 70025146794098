import { Select, Switch } from 'antd';
import { History } from 'history';
import get from 'lodash/get';
import React from 'react';
import { Link } from 'react-router-dom';

import { FRONT_PAGE_PATH } from 'shared/constants';
import { UserOrganization, View, ViewGroup } from 'types';

interface Props {
  readonly currentOrganization: UserOrganization;
  readonly history: History;
  readonly viewGroup: ViewGroup;
  readonly viewIndex: string;
}

export default class ViewTopbar extends React.Component<Props> {
  getBaseUrl() {
    const { currentOrganization, viewGroup } = this.props;
    return `/w/${currentOrganization.slug}/${FRONT_PAGE_PATH}/${viewGroup.slug}`;
  }

  handleSelect = (value: string) => {
    const { history } = this.props;
    const baseUrl = this.getBaseUrl();
    history.push(`${baseUrl}/${value}`);
  };

  renderSelect() {
    const { viewGroup, viewIndex } = this.props;
    const Option = Select.Option;
    return (
      <div className="app-ViewTopbar__select">
        {viewGroup.urlChangerText && (
          <div className="app-ViewTopbar__changer-text">{viewGroup.urlChangerText}</div>
        )}
        <Select onSelect={this.handleSelect} size="small" value={viewIndex}>
          {viewGroup.views.map((viewUrl: View, index: number) => (
            <Option key={`${viewUrl.url}-${index}`} value={String(index)}>
              {viewUrl.text}
            </Option>
          ))}
        </Select>
      </div>
    );
  }

  renderSwitch() {
    const { viewGroup, viewIndex } = this.props;
    const checked = viewIndex === '1';
    const baseUrl = this.getBaseUrl();
    const to = checked ? `${baseUrl}/0` : `${baseUrl}/1`;
    const text = get(viewGroup, `views[${viewIndex}].text`);
    return (
      <Link className="app-ViewTopbar__switch" to={to}>
        {viewGroup.urlChangerText && (
          <div className="app-ViewTopbar__changer-text">{viewGroup.urlChangerText}</div>
        )}
        <Switch checked={checked} />
        <span className="app-ViewTopbar__switch-label">{text}</span>
      </Link>
    );
  }

  renderViewHeader = () => {
    const { viewGroup } = this.props;
    const viewGroupName = get(viewGroup, 'name', '');
    return <h2 className="app-ViewTopbar__header">{viewGroupName}</h2>;
  };

  render() {
    const urlChangerType = get(this.props, 'viewGroup.urlChanger', null);
    return (
      <div className="app-ViewTopbar">
        {this.renderViewHeader()}
        {urlChangerType === 'dropdown' && this.renderSelect()}
        {urlChangerType === 'switch' && this.renderSwitch()}
      </div>
    );
  }
}
