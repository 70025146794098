import { notification } from 'antd';

type Notification = {
  level: 'error' | 'warning' | 'info' | 'success';
  message: string;
  description: string;
  duration: number;
};
type Notifications = {
  'data-export-remove-failure': Notification;
  'download-failure': Notification;
  'excel-export-failure': Notification;
  'google-login-email-not-found-failure': Notification;
  'google-login-failure': Notification;
  'no-google-email-failure': Notification;
  'not-found-failure': Notification;
  'pivot-edit-failure': Notification;
  'request-failure': Notification;
};
const notifications: Notifications = {
  'data-export-remove-failure': {
    level: 'error',
    message: 'Data export could not be deleted',
    description:
      'Sorry! Data export is in use and could not be deleted. ' +
      'To delete the data export first remove tables, pivots and dashboards that are using it.',
    duration: 20,
  },
  'download-failure': {
    level: 'error',
    message: 'Download failed',
    description: 'Sorry! Something went wrong while trying to download data.',
    duration: 10,
  },
  'excel-export-failure': {
    level: 'error',
    message: 'Excel export failed',
    description: 'Sorry! Something went wrong while trying to export data.',
    duration: 10,
  },
  'google-login-email-not-found-failure': {
    level: 'error',
    message: 'Email not found',
    description:
      "Sorry, there's no QuickBI user account with the email you logged " +
      'in to Google! To create a new account please contact your ' +
      'organization admin or QuickBI support.',
    duration: 0,
  },
  'google-login-failure': {
    level: 'error',
    message: 'Google login failed',
    description: 'Sorry, something went wrong!',
    duration: 10,
  },
  'no-google-email-failure': {
    level: 'error',
    message: 'Could not get your Google email',
    description: 'Sorry, login failed because we could not get your email from Google!',
    duration: 10,
  },
  'not-found-failure': {
    level: 'error',
    message: 'Request could not be completed',
    description:
      'The targeted resource could not be located on the server. ' +
      'Try refreshing your browser and try again shortly.',
    duration: 10,
  },
  'pivot-edit-failure': {
    level: 'error',
    message: 'Saving pivot failed',
    description: 'Sorry! Something went wrong while trying to save pivot.',
    duration: 10,
  },
  'request-failure': {
    level: 'error',
    message: 'Something went wrong',
    description:
      'There was a problem with the request. Please check your ' +
      'internet connection and try again shortly. ' +
      'If the problem persists please contact support@quickbi.io',
    duration: 10,
  },
};

export function show(type: keyof Notifications) {
  const notif = notifications[type];
  notification[notif.level]({
    key: type,
    message: notif.message,
    description: notif.description,
    duration: notif.duration,
    className: 'app-Notification',
  });
}
