import { Button } from 'antd';
import get from 'lodash/get';
import React from 'react';
import { FilePond } from 'react-filepond';

const DEFAULT_FILE_TYPE = 'sqlite';

interface Props {
  readonly groupKey: string;
  readonly isUploading: boolean;
  readonly onCancel: () => void;
  readonly uploadFile: (formData: any) => void;
}

interface State {
  file: any;
  filename: string;
}

export default class FileUpload extends React.Component<Props, State> {
  pondRef: any;

  state: State = {
    file: null,
    filename: '',
  };

  handleUpdateFiles = (fileItems: any) => {
    const fileItem = get(fileItems, '[0]', {});
    const { file, filenameWithoutExtension } = fileItem;
    const filename = filenameWithoutExtension || '';
    this.setState({ file, filename });
  };

  handleUpload = () => {
    if (!this.state.file) return;
    const formData = new FormData();
    formData.append('file', this.state.file, this.state.filename);
    formData.append('file_type', DEFAULT_FILE_TYPE);
    if (this.props.groupKey) {
      formData.append('group_key', this.props.groupKey);
    }
    this.props.uploadFile(formData);
  };

  render() {
    return (
      <div className="app-FileUpload">
        <div className="app-FileUpload__file-select">
          <h3 className="app-FileUpload__sub-header">
            Upload an SQLite database file to use as a data source
          </h3>
          <FilePond
            onupdatefiles={this.handleUpdateFiles}
            ref={(ref: any) => (this.pondRef = ref)}
          />
        </div>
        <div className="app-FileUpload__actions">
          <Button onClick={this.props.onCancel}>Cancel</Button>
          <Button onClick={this.handleUpload} disabled={!this.state.file} type="primary">
            {this.props.isUploading ? 'Uploading...' : 'Upload'}
          </Button>
        </div>
      </div>
    );
  }
}
