import { camelizeKeys } from 'humps';
import { ApiError, ApiErrorType, AuthUser, Credentials } from 'types';

export type LoginCredentials = { username: string; password: string };

type SessionItem = {
  attributes: any;
  id: string;
};
type SessionResponse = {
  data: SessionItem;
};
type SessionIndexResponse = {
  data: [SessionItem];
};
type JSONAPIError = {
  status: string;
  title: string;
  code?: string;
  id?: string;
  detail?: string;
  source?: any;
  meta?: any;
};
type ErrorResponse = {
  errors: JSONAPIError[];
};

export function loadAuthenticatedUser(): Promise<AuthUser | null> {
  const baseUrl = process.env.API_URL;
  return window
    .fetch(`${baseUrl}/sessions`, {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/vnd.api+json',
      },
    })
    .then((response: any) => response.json())
    .then((response: SessionIndexResponse | ErrorResponse): AuthUser | null => {
      if ('errors' in response) {
        throw new ApiError();
      }
      const sessionIndexResponse = camelizeKeys(response) as SessionIndexResponse;
      const data = sessionIndexResponse.data;
      if (data.length) {
        const item = data[0] as SessionItem;
        return getAuthUserFromSessionItem(item);
      }
      return null;
    });
}

function getAuthUserFromSessionItem(item: SessionItem): AuthUser {
  return {
    ...item.attributes,
    id: item.id,
  };
}

function getAuthUserFromResponse(response: SessionResponse): AuthUser {
  return getAuthUserFromSessionItem(response.data);
}

export function logIn(credentials: LoginCredentials): Promise<AuthUser> {
  const data = {
    data: {
      type: 'sessions',
      attributes: {
        email: credentials.username,
        password: credentials.password,
      },
    },
  };
  const baseUrl = process.env.API_URL;
  return window
    .fetch(`${baseUrl}/sessions`, {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/vnd.api+json',
      },
      method: 'POST',
      body: JSON.stringify(data as any),
    })
    .then((response: any) => response.json())
    .then(
      (response: SessionResponse | ErrorResponse): AuthUser => {
        if ('errors' in response) {
          const errorResponse = <ErrorResponse>response;
          const error = errorResponse.errors[0];
          if (error.title === 'PasswordChangeRequired') {
            throw new ApiError(ApiErrorType.PasswordChangeRequired);
          }
          if (error.title === 'RateLimitError') {
            throw new ApiError(ApiErrorType.RateLimitError);
          }
          if (error.status === '401') {
            throw new ApiError(ApiErrorType.InvalidCredentials);
          }
          throw new ApiError();
        }
        const sessionResponse = camelizeKeys(response) as SessionResponse;
        const data = getAuthUserFromResponse(sessionResponse);
        return data;
      }
    );
}

export function logInGoogle(token: string): Promise<AuthUser> {
  const data = {
    data: {
      type: 'google_logins',
      attributes: {
        token,
      },
    },
  };
  const baseUrl = process.env.API_URL;
  return window
    .fetch(`${baseUrl}/sessions/google-login`, {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/vnd.api+json',
      },
      method: 'POST',
      body: JSON.stringify(data as any),
    })
    .then((response: any) => response.json())
    .then(
      (response: SessionResponse | ErrorResponse): AuthUser => {
        if ('errors' in response) {
          const errorResponse = <ErrorResponse>response;
          const error = errorResponse.errors[0];
          if (error.title === 'EmailNotFound') {
            throw new ApiError(ApiErrorType.EmailNotFound);
          }
          if (error.title === 'NoGoogleEmail') {
            throw new ApiError(ApiErrorType.NoGoogleEmail);
          }
          if (error.status === '401') {
            throw new ApiError(ApiErrorType.GoogleLoginFailed);
          }
          throw new ApiError();
        }
        const sessionResponse = camelizeKeys(response) as SessionResponse;
        const data = getAuthUserFromResponse(sessionResponse);
        return data;
      }
    );
}

export function logOut(userId: string, credentials: Credentials): Promise<void> {
  const baseUrl = process.env.API_URL;
  const csrfToken = credentials.csrfToken as string;
  return window
    .fetch(`${baseUrl}/sessions/${userId}`, {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/vnd.api+json',
        'X-CSRF-TOKEN': csrfToken,
      },
      method: 'DELETE',
    })
    .then((response: any) => {
      if (response.status !== 204) {
        throw new ApiError();
      }
    });
}
