import { Modal } from 'antd';
import React from 'react';

export function openHelpModal() {
  Modal.info({
    title: 'Need help?',
    content: (
      <div className="app-help-modal">
        Please send us email to
        <span className="app-help-modal-email"> support@quickbi.io</span>. We will reply to you as
        soon as possible.
      </div>
    ),
    maskClosable: true,
  });
}
