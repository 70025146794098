import normalize from 'json-api-normalizer';
import build from 'redux-object';
import snakeCaseKeys from 'snakecase-keys';

import { Credentials, Dashboard, DashboardEditData } from 'types';
import { makeApiRequest } from './utils';

export function getDashboard(credentials: Credentials, id: string): Promise<Dashboard> {
  return makeApiRequest<any>(credentials, `dashboards/${id}`).then((json: any) => {
    if (json.data) {
      return build(normalize(json), 'dashboards', id, { eager: true });
    }
    return json;
  });
}

export function editDashboard(
  credentials: Credentials,
  data: DashboardEditData
): Promise<Dashboard> {
  const jsonAPIFormattedData = {
    data: {
      id: data.id,
      type: 'dashboards',
      attributes: snakeCaseKeys(data.attributes),
    },
  };
  return makeApiRequest<any>(
    credentials,
    `dashboards/${data.id}`,
    'PATCH',
    jsonAPIFormattedData
  ).then((json: any) => {
    if (json.data) {
      return build(normalize(json), 'dashboards', data.id, { eager: true });
    }
    return json;
  });
}
