import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { createSelector, createStructuredSelector } from 'reselect';

import * as auth from 'state/auth';
import * as views from 'state/data/views';

interface Params {
  readonly organizationSlug: string;
  readonly viewGroupSlug: string;
  readonly viewIndex: string;
}

interface Props extends RouteComponentProps<Params> {}

function selectViewGroupSlug(state: any, props: Props) {
  return props.match.params.viewGroupSlug;
}

function selectviewIndex(state: any, props: Props) {
  return props.match.params.viewIndex || '0';
}

const selectViewGroup = createSelector(
  selectViewGroupSlug,
  views.selectViewCategories,
  (slug, viewCategories) => {
    for (const viewCategory of viewCategories) {
      for (const viewGroup of viewCategory.viewGroups) {
        if (viewGroup.slug === slug) {
          return viewGroup;
        }
      }
    }
    return null;
  }
);

export const selector = createStructuredSelector({
  currentOrganization: auth.selectCurrentOrganization,
  viewGroup: selectViewGroup,
  viewIndex: selectviewIndex,
});

export default connect(selector);
