import { WarningOutlined } from '@ant-design/icons';
import { Badge } from 'antd';
import classNames from 'classnames';
import get from 'lodash/get';
import isNull from 'lodash/isNull';
import React from 'react';

import ShowSqlInEditorButton from 'shared/ShowSqlInEditorButton';
import { formatNumber } from 'shared/utils';
import * as types from 'types';

export interface Props {
  readonly canEdit: boolean;
  readonly dataExport: types.DataExport;
  readonly deleteDataExport: () => void;
  readonly selected: boolean;
  readonly showSqlInEditor: (values: {}) => void;
  readonly toggleDataExportDetails: (dataExportId: string) => void;
}

export default class DataExport extends React.Component<Props> {
  showSqlInEditor = () => {
    const { id, name, sql } = this.props.dataExport;
    this.props.showSqlInEditor({
      name,
      sql,
      dataExportId: id,
    });
  };

  renderShowSqlInEditorButton = () => {
    const { id, name, sql } = this.props.dataExport;
    const values = {
      name,
      sql,
      dataExportId: id,
    };
    return <ShowSqlInEditorButton values={values} />;
  };

  toggleDetailsVisible = () => {
    this.props.toggleDataExportDetails(this.props.dataExport.id);
  };

  render() {
    const { dataExport } = this.props;
    const status = get(dataExport, 'fetchSummary.length', false) ? 'success' : 'default';
    const validIcon = <Badge className="app-DataExport__header-icon" status={status} />;
    const inValidIcon = (
      <WarningOutlined className="app-DataExport__header-icon app-DataExport__header-icon--error" />
    );

    return (
      <div
        className={classNames('app-DataExport', {
          'app-DataExport--selected': this.props.selected,
        })}
      >
        <div className="app-DataExport__header" onClick={this.toggleDetailsVisible}>
          {dataExport.hasLatestRefreshFailed ? inValidIcon : validIcon}
          <div>
            <div className="app-DataExport__header-name">{dataExport.name}</div>
            {!isNull(dataExport.rowCount) && (
              <div className="app-DataExport__header-rowcount">
                {formatNumber(dataExport.rowCount)} rows
              </div>
            )}
          </div>
          {this.renderShowSqlInEditorButton()}
        </div>
      </div>
    );
  }
}
