import { Alert, Spin } from 'antd';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import pick from 'lodash/pick';
import uniq from 'lodash/uniq';
import React from 'react';

import {
  DataQueryOptions,
  getDistinctValues,
  getTableViewData,
  ValueQueryOptions,
} from 'services/api/tableViews';
import * as tableExporter from 'services/api/tableExporter';
import { parseLoadOptions } from 'services/datagrid';
import DataGrid from 'shared/DataGrid';
import * as types from 'types';
import ColumnEditor from './ColumnEditor';
import { getColumnFormat, getCommonFixedAttrs } from './utils';

export interface OwnProps {
  readonly id: string;
}

export interface InjectedProps {
  readonly canEditColumns: boolean;
  readonly credentials: types.Credentials;
  readonly fetchTableView: (id: string) => void;
  readonly showColumnEditor: () => void;
  readonly tableView: types.TableView | null;
}

export type Props = OwnProps & InjectedProps;

export default class TableView extends React.Component<Props> {
  currentOptions: any;

  UNSAFE_componentWillMount() {
    this.props.fetchTableView(this.props.id);
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.id !== this.props.id) {
      this.props.fetchTableView(this.props.id);
    }
  }

  getLoadFunction = () => {
    return (loadOptions: any) => {
      const { credentials, id } = this.props;
      const options = parseLoadOptions(loadOptions, 'dataView') as DataQueryOptions;
      this.currentOptions = options;
      return getTableViewData(credentials, id, options);
    };
  };

  getColumns(): types.DxColumns {
    if (!this.props.tableView) return [];
    const columnData = this.props.tableView.columnData;
    const orderedBandNames = uniq(
      columnData.map((item: types.TableViewColumn) => item.headerGroup)
    );

    if (orderedBandNames.length === 1 && orderedBandNames[0] === '') {
      return columnData.map((item) => this.getDxColumn(item));
    }

    const grouped = groupBy(columnData, 'headerGroup');
    return orderedBandNames.map((bandName: string) => {
      const bandColumns = grouped[bandName].map((item) => this.getDxColumn(item));
      return {
        caption: bandName,
        columns: bandColumns,
        // If all columns in this band are fixed, make this band fixed.
        ...getCommonFixedAttrs(bandColumns),
      };
    });
  }

  getDxColumn(column: types.TableViewColumn): types.DxColumn {
    const data: types.DxColumn = {
      caption: column.name,
      dataField: column.field,
      dataType: column.dataType,
      headerFilter: {
        dataSource: {
          load: (loadOptions: any) => {
            const options: ValueQueryOptions = {};
            if (Object.prototype.hasOwnProperty.call(loadOptions, 'take')) {
              options['page[limit]'] = loadOptions.take;
            }
            if (Object.prototype.hasOwnProperty.call(loadOptions, 'skip')) {
              options['page[offset]'] = loadOptions.skip;
            }
            return getDistinctValues(
              this.props.credentials,
              this.props.id,
              column.field,
              options,
              column.dataType
            );
          },
        },
      },
      minWidth: 70,
      showEditorAlways: false,
    };
    if (column.width) {
      data.width = column.width;
    }
    const format = getColumnFormat(column);
    if (format) {
      data.format = format;
    }
    if (column.dataType === 'date') {
      // Make sure date fits to filter row.
      data.minWidth = 140;
    }
    if (column.fixed) {
      data.fixed = true;
      data.fixedPosition = column.fixed;
    }
    return data;
  }

  exportXLSX = () => {
    const queryOptions = pick(this.currentOptions, ['sort', 'filter']);
    tableExporter.exportXLSX(this.props.credentials, this.props.id, queryOptions);
  };

  render() {
    if (!this.props.tableView) {
      return (
        <div className="app-TableView">
          <div className="app-TableView__loading">
            <Spin />
          </div>
        </div>
      );
    }

    if (!this.props.tableView.isValid) {
      return (
        <div className="app-TableView">
          <Alert
            message="The data this view is using is no longer valid"
            description={
              'Please contact your organization admin or QuickBI ' +
              'support to resolve this issue.'
            }
            type="warning"
          />
        </div>
      );
    }

    const { columnData, dataUrl } = this.props.tableView;
    const isInternalTable = !dataUrl;
    const columnsAreEditable = get(columnData, '[0].id', false);
    const canEditColumns = this.props.canEditColumns && columnsAreEditable;
    return (
      <div className="app-TableView">
        <DataGrid
          columns={this.getColumns()}
          disableRemoteOperations={!isInternalTable}
          exportXLSX={isInternalTable ? this.exportXLSX : undefined}
          loadFunction={this.getLoadFunction()}
          maxDecimals={2}
          pageSize={50}
          showColumnEditor={canEditColumns ? this.props.showColumnEditor : undefined}
        />
        {canEditColumns && <ColumnEditor columns={columnData} tableViewId={this.props.id} />}
      </div>
    );
  }
}
