import { connectRouter } from 'connected-react-router';
import includes from 'lodash/includes';
import { History } from 'history';
import { combineReducers } from 'redux';

import api from './api';
import auth, { logOutActionTypes } from './auth';
import dashboardItems from './data/dashboardItems';
import dashboards from './data/dashboards';
import dataExports from './data/dataExports';
import dataExportsForFront from './data/dataExportsForFront';
import dataSource from './data/dataSource';
import pivots from './data/pivots';
import tableViews from './data/tableViews';
import views from './data/views';
import dataExportDetails from './fox/dataExportDetails';
import editorTabs from './fox/editorTabs';
import sidebar from './front/sidebar';
import modals from './modals';

const rootReducerCreator = (history: History) => {
  const appReducer = combineReducers({
    api,
    auth,
    modals: modals.reducer,
    data: combineReducers({
      dashboardItems,
      dashboards,
      dataExports,
      dataExportsForFront,
      dataSource,
      pivots,
      tableViews,
      views,
    }),
    fox: combineReducers({
      dataExportDetails,
      editorTabs,
    }),
    front: combineReducers({
      sidebar,
    }),
    router: connectRouter(history),
  });

  // Reducer that resets state when certain actions are dispatched. The basic
  // idea is explained well in https://stackoverflow.com/a/35641992. However,
  // resetting router state would cause some weird behavior (resulting
  // sometimes in a redirect loop) so router state is not reseted as advised
  // in https://stackoverflow.com/a/37338532.
  const rootReducer = (state: any, action: any) => {
    const shouldResetState = includes(logOutActionTypes, action.type);
    if (shouldResetState) {
      const resetedState: any = {
        router: state.router,
      };
      return appReducer(resetedState, action);
    }
    return appReducer(state, action);
  };

  return rootReducer;
};

export default rootReducerCreator;
