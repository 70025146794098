import { Modal } from 'antd';
import React from 'react';

import ErrorMessages from 'shared/ErrorMessages';
import { ErrorMessage, TableViewColumn } from 'types';
import ColumnsForm from './ColumnsForm';

export interface OwnProps {
  columns: TableViewColumn[];
  tableViewId: string;
}

export interface InjectedProps {
  readonly errorMessages: ErrorMessage[];
  readonly hideModal: () => void;
  readonly isSaving: boolean;
  readonly isVisible: boolean;
  readonly saveColumns: ({}: {}) => void;
}

type Props = OwnProps & InjectedProps;

export default class ColumnEditor extends React.Component<Props> {
  handleSave = (data: {}) => {
    const { saveColumns, tableViewId } = this.props;
    saveColumns({ tableViewId, data });
  };

  render() {
    return (
      <div className="app-ColumnEditor">
        <Modal
          className="app-ColumnEditor__modal"
          footer={null}
          maskClosable={false}
          onCancel={this.props.hideModal}
          title="Edit table columns"
          visible={this.props.isVisible}
          width="90%"
        >
          <div className="app-ColumnEditor__modal-content">
            <ErrorMessages errorMessages={this.props.errorMessages} />
            <ColumnsForm
              columns={this.props.columns}
              isSaving={this.props.isSaving}
              onCancel={this.props.hideModal}
              onSave={this.handleSave}
            />
          </div>
        </Modal>
      </div>
    );
  }
}
