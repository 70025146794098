import { Select } from 'antd';
import filter from 'lodash/filter';
import map from 'lodash/map';
import React from 'react';
import makeComponentTrashable from 'trashable-react';

import { getDataExportData } from 'services/api/dataExports';
import { Credentials, DashboardFilterOption } from 'types';

const { Option } = Select;

export interface OwnProps {
  readonly credentials: Credentials;
  readonly dashboardId?: string;
  readonly dataExportId?: string;
  readonly id: string;
  readonly label: string;
  readonly onChange: (id: string, value: string[]) => void;
  readonly options?: DashboardFilterOption[];
  readonly value: string[];
}

export interface InjectedProps {
  readonly registerPromise: Function;
}

export type Props = OwnProps & InjectedProps;

export interface State {
  options: DashboardFilterOption[];
}

class DashboardFilter extends React.Component<Props, State> {
  state: State = {
    options: this.props.options || [],
  };

  componentDidMount() {
    const { credentials, dashboardId, dataExportId, registerPromise } = this.props;
    if (dataExportId) {
      const requestAttrs = {
        dashboardId,
        dataExportId,
        format: 'json',
      };
      registerPromise(getDataExportData(credentials, requestAttrs)).then(
        (options: DashboardFilterOption[]) => {
          this.initOptions(options);
        }
      );
    }
  }

  initOptions = (options: DashboardFilterOption[]) => {
    this.setState({ options });
    // Set default filter values only if needed.
    if (options.length && 'active' in options[0]) {
      const defaultValues = map(filter(options, 'active'), 'value');
      this.handleChange(defaultValues);
    }
  };

  handleChange = (value: string[]) => {
    this.props.onChange(this.props.id, value);
  };

  renderDashboardFilterOption = (option: DashboardFilterOption) => {
    return (
      <Option key={option.value} value={option.value}>
        {option.label}
      </Option>
    );
  };

  render = () => {
    return (
      <Select
        allowClear
        className="app-DashboardFilter"
        mode="multiple"
        onChange={this.handleChange}
        placeholder={this.props.label}
        showArrow
        size="large"
        value={this.props.value}
      >
        {this.state.options.map(this.renderDashboardFilterOption)}
      </Select>
    );
  };
}

export default makeComponentTrashable(DashboardFilter);
