import {
  ArgumentAxis,
  CommonSeriesSettings,
  Legend,
  Series,
  Title,
  Tooltip,
  ValueAxis,
} from 'devextreme-react/chart';

// ---
// Api
// ---

export enum ApiErrorType {
  ApiError = 'ApiError',
  BadRequest = 'BadRequest',
  EmailNotFound = 'EmailNotFound',
  Forbidden = 'Forbidden',
  GoogleLoginFailed = 'GoogleLoginFailed',
  InvalidCredentials = 'InvalidCredentials',
  NoCredentials = 'NoCredentials',
  NoGoogleEmail = 'NoGoogleEmail',
  NotFound = 'NotFound',
  PasswordChangeRequired = 'PasswordChangeRequired',
  RateLimitError = 'RateLimitError',
}

export class ApiError extends Error {
  readonly type: ApiErrorType | null;

  constructor(type?: ApiErrorType | null) {
    super(`ApiError(${type || null})`);
    this.type = type || null;
  }
}

export interface ErrorMessage {
  detail?: string;
  source?: {};
  status?: string;
  title: string;
}

export interface ExtendedApiError {
  errors?: ErrorMessage[];
  status: number;
  statusText: string;
}

// ----
// Auth
// ----

export type FoxPermissionLevel = '' | 'view' | 'edit' | 'admin';
export type FrontPermissionLevel = 'view' | 'admin';
export type PermissionLevel = FoxPermissionLevel | FrontPermissionLevel;

export interface Credentials {
  csrfToken: string | null;
  organizationId: string | null;
}

export interface Team {
  id: string;
  name: string;
}

export interface UserOrganization {
  dbms: 'sqlite' | 'postgres' | null;
  defaultPage: 'fox' | 'front' | null;
  foxPermissionLevel: FoxPermissionLevel;
  frontPermissionLevel: FrontPermissionLevel;
  id: string;
  isAdmin: boolean;
  isDefaultMembership: boolean;
  isFoxHidden: boolean;
  isFrontHidden: boolean;
  logoUrl: string;
  name: string;
  slug: string;
  teams: Team[];
}

export interface AuthUser {
  csrfToken: string;
  email: string;
  gsServiceAccountEmail: string;
  id: string;
  name: string;
  organizations: UserOrganization[];
}

// ------------
// Data exports
// ------------

export interface DataExportConnection {
  accessType: 'quickbi' | 'auth' | 'shared_url';
  clientType: string;
  lastFetchedAt: string;
  name: string;
  url: string;
}

export interface DataExport {
  authUrls: { [key: string]: string };
  authorizationHeader?: string;
  createdAt?: string;
  creatorName?: string;
  dataUpdatedAt: string | null;
  description: string;
  downloadUrls: { [key: string]: string };
  fetchSummary: DataExportConnection[];
  group?: {
    type: 'data_view_groups';
    id: string;
  };
  hasAuthAccess: boolean;
  hasFrontAccess: boolean;
  hasLatestRefreshFailed: boolean;
  hasSharedUrlAccess: boolean;
  id: string;
  isParameterized: boolean;
  isValid: boolean;
  name: string;
  refreshErrorAt: string | null;
  rowCount: number | null;
  sharedFormulas: { [key: string]: string };
  sharedUrls: { [key: string]: string };
  sql: string;
  type: string;
  updatedAt?: string;
}

export interface DataExportGroup {
  dataExports: DataExport[];
  id: string;
  name: string;
  type: string;
}

export interface DataExportGroupRaw {
  dataViewIds: string[];
  groupId: string;
  groupName: string;
}

export type DataExportGroupingItem = DataExport | DataExportGroup;

export interface DataExportAttributes {
  description: string;
  name: string;
  sql: string;
}

export interface DataExportSaveData {
  attributes: DataExportAttributes;
  relationships: {};
}

export interface DataExportEditData {
  id: string;
  attributes: DataExportAttributes;
  relationships: {};
}

// ----------
// Dashboards
// ----------

export type DashboardLayoutItemSize =
  | number
  | {
      xs?: number;
      sm?: number;
      md?: number;
      lg?: number;
      xl?: number;
    };

export interface DashboardLayoutItem {
  id?: string;
  size: DashboardLayoutItemSize;
}

export interface DashboardItem {
  dataView?: any;
  description: string;
  id: string;
  name: string;
  specs: any;
}

export type DashboardRow = DashboardLayoutItem[];

export interface DashboardFilterOption {
  active?: boolean | number | string;
  label: string;
  value: string;
}

export type DashboardFilterValue = string[];

export type DashboardFilterValues = { [key: string]: DashboardFilterValue };

export interface DashboardFilter {
  dataExportId?: string;
  filterId: string;
  label: string;
  options?: DashboardFilterOption[];
}

export interface DashboardItemFilter {
  column: string;
  filterId: string;
  operator: string;
}

export interface Dashboard {
  filters: DashboardFilter[];
  id: string;
  layout: DashboardRow[];
}

export interface DashboardEditData {
  id: string;
  attributes: any;
}

export interface DashboardItemEditData {
  id: string;
  attributes: any;
  relationships: any;
}

export interface DashboardChartSpecs {
  aggregation?: {
    field: string;
    method?: 'count' | 'max' | 'mean' | 'min' | 'sum';
    orderBy?: string;
    orderByDirection?: 'asc' | 'desc';
    valueFields: string[];
  };
  argumentAxis?: ArgumentAxis | any;
  commonSeriesSettings?: CommonSeriesSettings;
  dataSource?: string;
  filters?: DashboardItemFilter[];
  legend?: Legend;
  series?: Series[];
  title?: Title;
  tooltip?: Tooltip;
  valueAxis?: ValueAxis | any;
}

export interface DashboardBoxSpecs {
  comparison?: {
    column?: string;
    hidden?: boolean;
    precision?: number;
    suffix?: string;
    type?: 'absolute' | 'relative';
  };
  comparisonStyles?: {};
  size: 'small' | 'default';
  styles?: {};
  title: string;
  titleStyles?: {};
  value?: {
    column?: string;
    prefix?: string;
    suffix?: string;
  };
  valueStyles?: {};
}

// ------------
// Data Sources
// ------------

export interface DataSource {
  sourceTableGroups: SourceTableGroup[];
  updatedAt: string;
  updatedBy: string;
  uploadToken?: string;
  uploadUrl?: string;
}

export interface SourceTableGroup {
  id: string;
  groupType: string;
  key: string;
  mergedWithNewDataAt: string;
  name: string;
  sourceTables: SourceTable[];
  sourceUrl: string;
  syncStartedAt: string;
}

export interface SourceTableGroupSaveData {
  attributes: {
    type: string;
    data: {};
  };
}

export interface SourceTableColumn {
  dataType: string | null;
  isNullable: boolean | null;
  name: string;
}

export interface SourceTable {
  alias: string;
  columns: SourceTableColumn[];
  id: string;
  name: string;
  rowCount: number;
}

// -----------
// Editor tabs
// -----------

export interface EditorTab {
  readonly columns: string[];
  readonly columnTypes: string[];
  readonly dataExportId: string | null;
  readonly errorMessage: string | null;
  readonly id: string;
  readonly initialSql: string;
  readonly name: string;
  readonly needsValidation: boolean;
  readonly sql: string;
  readonly sqlInValidation: string | null;
  readonly validSql: string | null;
}

export interface TabSqlActionData {
  sql: string;
  tabId: string;
}

// -------
// General
// -------

export interface SelectOption {
  label: string;
  value: string;
}

// ------------
// Panel groups
// ------------

export interface SizeDataItem {
  size: number;
}

export type SizeData = SizeDataItem[];

// -----
// Pivot
// -----

export type PivotChartType = '' | 'bar' | 'stackedbar' | 'line';
export type DxAggregateFunc = 'count' | 'sum' | 'avg' | 'min' | 'max' | 'custom';
export type QBIAggregateFunc = 'count' | 'sum' | 'avg' | 'min' | 'max' | 'count_unique';
export type FilterType = 'include' | 'exclude';

export interface PivotAggregate {
  field: null | string; // null is ok with 'count' func.
  func: QBIAggregateFunc;
}

export interface PivotField {
  area: '' | 'row' | 'column';
  dataType: string;
  field: string;
  filterType: FilterType;
  filterValues: any[];
  id?: string; // Exists only if pivot is connected to data view.
  name: string;
  sortOrder: '' | 'asc' | 'desc';
}

export interface PivotFieldToSave {
  id: string;
  area: string;
  filterValues: any[];
  sortOrder: '' | 'asc' | 'desc';
}

export interface Pivot {
  aggregates: PivotAggregate[];
  chartType: PivotChartType;
  dataUrl: string;
  description: string;
  fieldData: PivotField[];
  id: string;
  isValid: boolean;
  name: string;
  showPanel: boolean;
}

export interface DxPivotField {
  area: string;
  areaIndex?: number;
  calculateCustomSummary?: (options: any) => void;
  caption?: string;
  dataField?: null | string;
  dataType?: string;
  expanded?: boolean;
  filterType?: FilterType;
  filterValues?: any[];
  format?: any;
  isMeasure?: boolean;
  sortOrder?: 'asc' | 'desc';
  summaryType?: DxAggregateFunc;
}

export interface PivotSaveData {
  aggregates: PivotAggregate[];
  chartType: PivotChartType;
  fieldData: PivotFieldToSave[];
  showPanel: boolean;
}

export interface PivotEditData {
  id: string;
  attributes: PivotSaveData;
}

export interface DxPivotState {
  fields: DxPivotField[];
}

// -----------
// SQL Editor
// -----------

export interface AutoCompleteItem {
  meta: string;
  value: string;
}
// -----------
// Table views
// -----------

type DataType = 'string' | 'number' | 'date' | 'boolean' | 'object' | 'datetime';

export interface TableViewColumn {
  id: string;
  isDescFilterSorting: boolean;
  field: string;
  name: string;
  dataType: DataType;
  headerGroup: string;
  format: '' | 'number' | 'date' | 'datetime' | 'EUR';
  width: null | number;
  fixed: null | 'left' | 'right';
}

export interface TableView {
  id: string;
  isValid: boolean;
  columnData: TableViewColumn[];
  dataUrl: string;
}

export interface DxColumn {
  caption: string;
  dataField: string;
  dataType: DataType;
  fixed?: boolean;
  fixedPosition?: 'left' | 'right';
  format?: any;
  headerFilter: any;
  minWidth?: number;
  showEditorAlways: boolean;
  width?: number;
}

export interface DxBand {
  caption: string;
  columns: DxColumn[];
}

export type DxColumns = DxColumn[] | DxBand[];

// -----
// Views
// -----

export interface ViewCategory {
  id: string;
  name: string;
  viewGroups: ViewGroup[];
  organization: {
    id: string;
    type: 'organizations';
  };
}

export interface ViewGroup {
  allowedTeams: Team[];
  id: string;
  isAccessRestricted: boolean;
  isHidden: boolean;
  name: string;
  slug: string;
  urlChanger: string | null;
  urlChangerText: string | null;
  views: View[];
}

export interface View {
  dashboard: null | {
    id: string;
    type: 'dashboards';
  };
  dataViewId?: string;
  id: string;
  kind: string;
  pivot: null | {
    id: string;
    type: 'pivots';
  };
  tableView: null | {
    id: string;
    type: 'table_views';
  };
  text: string;
  url: string;
}
