import { Form, FormItem, Input } from 'formik-antd';
import { Alert, Button, Divider } from 'antd';
import { Formik } from 'formik';
import React from 'react';

import * as api from 'services/api';
import GoogleLoginButton from './GoogleLoginButton';
import LoginTopbar from './LoginTopbar';

const Password = Input.Password;

interface Props {
  readonly loginClassicFailed: boolean;
  readonly isPasswordChangeRequired: boolean;
  readonly isRateLimitError: boolean;
  readonly logIn: (credentials: api.LoginCredentials) => void;
  readonly logInGoogle: (token: string) => void;
}

interface Fields {
  username: string;
  password: string;
}

export default class Login extends React.Component<Props> {
  static initial: Fields = {
    username: '',
    password: '',
  };

  validate(values: Fields) {
    const errors: any = {};
    if (!values.username) {
      errors.username = 'Required';
    }
    if (!values.password) {
      errors.password = 'Required';
    }
    return errors;
  }

  getDemoLoginUrl = () => {
    return `${process.env.API_URL}/sessions/demo-login`;
  };

  getResetPasswordUrl = (required: boolean) => {
    const params = required ? '?required=true' : '';
    return `${process.env.API_URL}/password/request_reset${params}`;
  };

  handleDemoClick = () => {
    if (!process.env.DEMO_USER) throw new Error('No demo user');
    if (!process.env.DEMO_PASSWORD) throw new Error('No demo password');
    const username = process.env.DEMO_USER as string;
    const password = process.env.DEMO_PASSWORD as string;
    this.props.logIn({ username, password });
  };

  handleSubmit = (values: Fields) => {
    this.props.logIn(values);
  };

  handleGoogleLoginSuccess = (token: string) => {
    this.props.logInGoogle(token);
  };

  renderForm = () => {
    return (
      <Form className="app-Login__form" layout="vertical">
        {this.props.loginClassicFailed && !this.props.isPasswordChangeRequired && (
          <Alert
            message="Login failed"
            description="Email or password is incorrect."
            type="error"
            showIcon
          />
        )}
        {this.props.isRateLimitError && !this.props.isPasswordChangeRequired && (
          <Alert
            message="Too many failed login attempts"
            description="Please wait a moment before your next login attempt. If the issue persists, please contact our support."
            type="error"
            showIcon
          />
        )}
        <FormItem label="Email" name="username">
          <Input name="username" size="large" />
        </FormItem>
        <FormItem label="Password" name="password">
          <Password name="password" size="large" />
        </FormItem>
        <div className="app-Login__form-actions">
          <a href={this.getResetPasswordUrl(false)} tabIndex={0}>
            Forgot password?
          </a>
          <Button
            className="app-Login__form-submit-button"
            htmlType="submit"
            size="large"
            type="primary"
          >
            Log in
          </Button>
        </div>
      </Form>
    );
  };

  render() {
    if (this.props.isPasswordChangeRequired) {
      window.location.href = this.getResetPasswordUrl(true);
    }
    return (
      <div className="app-Login">
        <LoginTopbar />
        <div className="app-Login__container">
          <div className="app-Login__content">
            <h1 className="app-Login__header">
              Log <span className="app-Login__header-highlight">in</span>
            </h1>
            <Formik
              initialValues={Login.initial}
              onSubmit={this.handleSubmit}
              validate={this.validate}
            >
              {this.renderForm}
            </Formik>
            <Divider className="app-Login__divider">or</Divider>
            <div className="app-Login__google-login-container">
              <GoogleLoginButton onSuccess={this.handleGoogleLoginSuccess} />
            </div>
            <div className="app-Login__contact">
              Need an account? <a href="https://quickbi.io/contact">Contact us</a>.
            </div>
          </div>
        </div>
      </div>
    );
  }
}
