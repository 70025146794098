import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as auth from 'state/auth';
import { InjectedProps } from './TeamAccessSelect';

const selector = createStructuredSelector({
  teams: auth.selectCurrentOrganizationTeams,
});

export default connect<InjectedProps, {}, {}>(selector, {});
