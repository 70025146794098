import { constantCase } from 'change-case';
import upperFirst from 'lodash/upperFirst';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

import * as dashboardItems from 'state/data/dashboardItems';
import * as dashboards from 'state/data/dashboards';
import * as dataExports from 'state/data/dataExports';
import * as dataSource from 'state/data/dataSource';
import * as tableViews from 'state/data/tableViews';
import * as views from 'state/data/views';
import * as editorTabs from 'state/fox/editorTabs';

interface ModalState {
  data: any;
  visible: boolean;
}

interface State {
  [modalType: string]: ModalState;
}

interface ModalSpecification {
  extraHideActions: string[];
  hasData?: boolean;
  type: string;
}

// --------------------
// Modal specifications
// --------------------

/** Specify info needed for each modal here.
 *
 * For each modal the following is exported:
 * - modal type as uppercase:
 *     EXAMPLE_MODAL = 'exampleModal'
 * - hide action:
 *     hideExampleModal = () => hide(modal.type)
 * - show action:
 *     showExampleModal = () => show(modal.type)
 * - visibility selector:
 *     selectIsExampleModalVisible
 * - dataSelector (if modal hasData = true):
 *     selectExampleModalData
 *
 * For each modal specified extraHideActions will cause the modal to close.
 */

const DATA_EXPORT_MODAL_TYPE = 'dataExport';
const modals: ModalSpecification[] = [
  {
    type: 'addGoogleSheet',
    extraHideActions: [`${dataSource.saveSourceTableGroup}.success`],
  },
  {
    type: 'columnEditor',
    extraHideActions: [`${tableViews.saveColumns}.success`],
  },
  {
    type: 'DashboardEditor',
    extraHideActions: [`${dashboards.edit}.success`],
  },
  {
    type: 'DashboardItemEditor',
    extraHideActions: [`${dashboardItems.edit}.success`],
    hasData: true,
  },
  {
    type: DATA_EXPORT_MODAL_TYPE,
    extraHideActions: [
      `${dataExports.edit}.success`,
      `${dataExports.save}.success`,
      `${editorTabs.validateTabSql}.failure`,
    ],
  },
  {
    type: 'dataExportGroupEditor',
    extraHideActions: [`${dataExports.saveGrouping}.success`],
  },
  {
    type: 'dataSourceUpload',
    extraHideActions: [`${dataSource.upload}.success`],
    hasData: true,
  },
  {
    type: 'workspaceEditor',
    extraHideActions: [`${views.save}.success`],
  },
];

const modalTypes: { [modalType: string]: string } = {};

modals.forEach((modal) => {
  const constantCaseModalName = constantCase(modal.type);
  modalTypes[constantCaseModalName] = modal.type;
});

// -------
// Helpers
// -------

function createShowActionWithData(modalType: string) {
  return createAction('app/modals/show', (payload: any) => ({ modalType, data: payload }));
}

function getDataSelector(modalType: string) {
  return createSelector(select, (state: State): any => state[modalType].data);
}

function getInitialModalState(): ModalState {
  return {
    data: {},
    visible: false,
  };
}

function getIsVisibleSelector(modalType: string) {
  return createSelector(select, (state: State): boolean => state[modalType].visible);
}

// -------
// Actions
// -------

const hide = createAction('app/modals/hide');
const show = createAction('app/modals/show', (modalType: string) => ({ modalType }));

// Create hideModalName and showModalName actions for each modal:
const actions: any = {
  hide,
  show,
};
modals.forEach((modal) => {
  const upperFirstModalName = upperFirst(modal.type);
  actions[`hide${upperFirstModalName}`] = () => hide(modal.type);
  if (modal.hasData) {
    actions[`show${upperFirstModalName}`] = createShowActionWithData(modal.type);
  } else {
    actions[`show${upperFirstModalName}`] = () => show(modal.type);
  }
});

// ---------
// Selectors
// ---------

function select(state: any): State {
  return state.modals;
}

// Create selectIsVisible selector for each modal
// and selectModalData selector for each modal where hasData = true:
const selectors: any = {};
modals.forEach((modal) => {
  const upperFirstModalName = upperFirst(modal.type);
  selectors[`selectIs${upperFirstModalName}Visible`] = getIsVisibleSelector(modal.type);
  if (modal.hasData) {
    selectors[`select${upperFirstModalName}Data`] = getDataSelector(modal.type);
  }
});

// -----
// State
// -----

const initialState: State = {};
modals.forEach((modal) => {
  initialState[modal.type] = getInitialModalState();
});

const handlers = {
  // General hide / show action handling
  [String(hide)]: (state: State, action: ReduxActions.Action<any>): State => ({
    ...state,
    [action.payload]: getInitialModalState(),
  }),

  [String(show)]: (state: State, action: ReduxActions.Action<any>): State => {
    const { data, modalType } = action.payload;
    return {
      ...state,
      [modalType]: { data: data || {}, visible: true },
    };
  },

  [`${editorTabs.validateTabSql}.success`]: (
    state: State,
    action: ReduxActions.Action<any>
  ): State => {
    const { isValid } = action.payload;
    if (!isValid) {
      return {
        ...state,
        [DATA_EXPORT_MODAL_TYPE]: getInitialModalState(),
      };
    }
    return state;
  },
};

// Handle modal specific extraHideActions:
modals.forEach((modal) => {
  modal.extraHideActions.forEach((action) => {
    handlers[action] = (state: State): State => ({
      ...state,
      [modal.type]: getInitialModalState(),
    });
  });
});

const reducer = handleActions(handlers, initialState);

export default {
  ...actions,
  ...modalTypes,
  reducer,
  ...selectors,
};
