import produce from 'immer';
import get from 'lodash/get';
import isString from 'lodash/isString';
import keyBy from 'lodash/keyBy';
import map from 'lodash/map';
import values from 'lodash/values';
import { combineActions, createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

import { reset } from 'state/data';
import { DataExport, DataExportGroupRaw, DataExportGroupingItem, SelectOption } from 'types';

interface State {
  byId: { [id: string]: DataExport };
  grouping: [];
}

// -------
// Actions
// -------

export const edit = createAction(
  'app/data/dataExports/edit',
  (payload) => payload.data,
  (payload) => payload.meta
);

export const fetch = createAction('app/data/dataExports/fetch');

export const remove = createAction('app/data/dataExports/remove');

export const save = createAction(
  'app/data/dataExports/save',
  (payload) => payload.data,
  (payload) => payload.meta
);

export const saveGrouping = createAction('app/data/dataExports/saveGroups');

// ---------
// Selectors
// ---------

type DataExportIdSelector = ({}, {}) => string;

export function select(state: any): State {
  return state.data.dataExports;
}

export const selectDataExports = createSelector(select, (state: State): DataExport[] =>
  values(state.byId)
);

export const selectDataExportGrouping = createSelector(
  select,
  (state: State): DataExportGroupingItem[] => {
    const { byId, grouping } = state;
    return map(grouping, (item: string | DataExportGroupRaw) => {
      if (isString(item)) {
        return {
          ...byId[item],
          type: 'dataExport',
        };
      }
      return {
        dataExports: map(item.dataViewIds, (id) => ({
          ...byId[id],
          type: 'dataExport',
        })),
        id: item.groupId,
        name: item.groupName,
        type: 'dataExportGroup',
      };
    });
  }
);

export const selectDataExportGroupOptions = createSelector(
  select,
  (state: State): SelectOption[] => {
    const { grouping } = state;
    const groups = grouping.filter((item: string | DataExportGroupRaw) => !isString(item));
    return map(groups, (item: DataExportGroupRaw) => ({
      label: item.groupName,
      value: item.groupId,
    }));
  }
);

export const selectDataExportFactory = (idSelector: DataExportIdSelector): any =>
  createSelector(select, idSelector, (state: State, dataExportId: string) => {
    if (dataExportId) {
      return state.byId[dataExportId] || null;
    }
    return null;
  });

// -----
// State
// -----

const initialState: State = {
  byId: {},
  grouping: [],
};

const handlers = {
  [`${fetch}.failure`]: (): State => initialState,
  [combineActions(`${fetch}.success`, `${saveGrouping}.success`) as any]: (
    state: State,
    action: any
  ): State => {
    const { dataExports, grouping } = action.payload;
    return {
      grouping,
      byId: keyBy(dataExports, 'id'),
    };
  },
  [`${edit}.success`]: (state: State, action: any): State =>
    produce(state, (draft) => {
      const dataExport = get(action.payload, '[0]');
      if (dataExport) {
        draft.byId[dataExport.id] = dataExport;
      }
    }),
  [String(reset)]: (): State => initialState,
};

export default handleActions(handlers, initialState);
