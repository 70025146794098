import produce from 'immer';
import endsWith from 'lodash/endsWith';
import get from 'lodash/get';
import { ActionFunctionAny } from 'redux-actions';
import { createSelector } from 'reselect';

import * as dashboardItems from 'state/data/dashboardItems';
import * as dashboards from 'state/data/dashboards';
import * as dataExports from 'state/data/dataExports';
import * as dataSource from 'state/data/dataSource';
import * as tableViews from 'state/data/tableViews';
import { save as saveViews } from 'state/data/views';
import modals from 'state/modals';
import { ErrorMessage } from 'types';

interface State {
  [key: string]: {
    readonly errors: ErrorMessage[];
    readonly isPending: boolean;
  };
}

// -------
// Helpers
// -------

function clearErrors(draft: any, action: ActionFunctionAny<any>) {
  if (draft[String(action)]) {
    draft[String(action)].errors = [];
  }
}

function select(state: any): State {
  return state.api;
}

function getIsPendingSelector(action: ActionFunctionAny<any>) {
  return createSelector(select, (state: State): boolean => {
    const actionType = String(action);
    return state[actionType] && state[actionType].isPending;
  });
}

function getErrorsSelector(action: ActionFunctionAny<any>) {
  return createSelector(select, (state: State): ErrorMessage[] => {
    const actionType = String(action);
    return state[actionType] ? state[actionType].errors : [];
  });
}

// ---------
// Selectors
// ---------

// Dashboard items
export const selectIsEditDashboardItemsPending = getIsPendingSelector(dashboardItems.edit);
export const selectEditDashboardItemsErrors = getErrorsSelector(dashboardItems.edit);

// Dashboards
export const selectIsEditDashboardsPending = getIsPendingSelector(dashboards.edit);
export const selectEditDashboardsErrors = getErrorsSelector(dashboards.edit);

export const selectIsFetchDashboardsPending = getIsPendingSelector(dashboards.fetch);
export const selectFetchDashboardsErrors = getErrorsSelector(dashboards.fetch);

// Data exports
export const selectIsFetchDataExportsPending = getIsPendingSelector(dataExports.fetch);

export const selectIsSaveDataExportsPending = getIsPendingSelector(dataExports.save);
export const selectSaveDataExportsErrors = getErrorsSelector(dataExports.save);

export const selectIsEditDataExportsPending = getIsPendingSelector(dataExports.edit);
export const selectEditDataExportsErrors = getErrorsSelector(dataExports.edit);

export const selectIsSaveDataExportGroupingPending = getIsPendingSelector(dataExports.saveGrouping);
export const selectSaveDataExportGroupingErrors = getErrorsSelector(dataExports.saveGrouping);

// Source file upload
export const selectIsDataSourceUploadPending = getIsPendingSelector(dataSource.upload);
export const selectDataSourceUploadErrors = getErrorsSelector(dataSource.upload);

// Source tables
export const selectIsFetchDataSourcesPending = getIsPendingSelector(dataSource.fetch);

export const selectIsSaveSourceTableGroupPending = getIsPendingSelector(
  dataSource.saveSourceTableGroup
);
export const selectSaveSourceTableGroupErrors = getErrorsSelector(dataSource.saveSourceTableGroup);

// Source table sync
export const selectIsSyncSourceTableGroupPending = getIsPendingSelector(
  dataSource.syncSourceTableGroup
);
export const selectSyncSourceTableGroupErrors = getErrorsSelector(dataSource.syncSourceTableGroup);

// Table views
export const selectIsSaveColumnsPending = getIsPendingSelector(tableViews.saveColumns);
export const selectSaveColumnsErrors = getErrorsSelector(tableViews.saveColumns);

// Views
export const selectIsSaveViewsPending = getIsPendingSelector(saveViews);
export const selectSaveViewsErrors = getErrorsSelector(saveViews);

// -----
// State
// -----

const initialState: State = {};

function reducer(state: any = initialState, action: any) {
  return produce(state, (draft: any) => {
    // Handle api actions
    if (action.meta && action.meta.isApiAction) {
      const type = action.type;
      const requestActionType = action.meta.requestActionType;

      // Handle api failure actions
      if (endsWith(type, '.failure')) {
        draft[requestActionType] = {
          errors: get(action.payload, 'errors', []),
          isPending: false,
        };
      } else if (endsWith(type, '.success')) {
        // Handle api success actions
        draft[requestActionType] = {
          errors: [],
          isPending: false,
        };
      } else {
        // Handle api request actions
        draft[requestActionType] = {
          errors: [],
          ...state[requestActionType],
          isPending: true,
        };
      }
    }
    // Reset possible errors if modals are closed
    if (action.type === String(modals.hide)) {
      const modalType = action.payload;

      if (modalType === modals.ADD_GOOGLE_SHEET) {
        clearErrors(draft, dataSource.saveSourceTableGroup);
      }

      if (modalType === modals.COLUMN_EDITOR) {
        clearErrors(draft, tableViews.saveColumns);
      }

      if (modalType === modals.DASHBOARD_EDITOR) {
        clearErrors(draft, dashboards.edit);
      }

      if (modalType === modals.DASHBOARD_ITEM_EDITOR) {
        clearErrors(draft, dashboardItems.edit);
      }

      if (modalType === modals.DATA_EXPORT) {
        clearErrors(draft, dataExports.save);
        clearErrors(draft, dataExports.edit);
      }

      if (modalType === modals.DATA_EXPORT_GROUP_EDITOR) {
        clearErrors(draft, dataExports.saveGrouping);
      }

      if (modalType === modals.DATA_SOURCE_UPLOAD) {
        clearErrors(draft, dataSource.upload);
      }

      if (modalType === modals.WORKSPACE_EDITOR) {
        clearErrors(draft, saveViews);
      }
    }
  });
}

export default reducer;
