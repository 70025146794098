import { connect } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';

import { getDefaultDataAreaField } from 'shared/PivotTable/utils';
import * as auth from 'state/auth';
import * as editorTabs from 'state/fox/editorTabs';
import { OwnProps } from './Pivot';

function selector() {
  function tabIdSelector(state: any, props: OwnProps) {
    return props.tabId;
  }

  const fieldsSelector = createSelector(
    editorTabs.selectColumnsFactory(tabIdSelector),
    (columns: string[]) => {
      const dataAreaFields = [getDefaultDataAreaField()];
      const regularFields = columns.map((column) => ({
        area: 'filter',
        caption: column,
        dataField: column,
        expanded: true,
      }));
      return dataAreaFields.concat(regularFields);
    }
  );

  return createStructuredSelector({
    credentials: auth.selectCredentials,
    dataExportId: editorTabs.selectDataExportIdFactory(tabIdSelector),
    fields: fieldsSelector,
    isValidating: editorTabs.selectIsValidatingFactory(tabIdSelector),
    sql: editorTabs.selectValidSqlFactory(tabIdSelector),
  });
}

export default connect(selector);
