import { Select } from 'antd';
import React from 'react';

import { DataExport, DataExportGroup, DataExportGroupingItem } from 'types';

export interface OwnProps {
  readonly onChange: (value: string) => void;
  readonly value: string;
}

export interface InjectedProps {
  readonly dataExportGrouping: DataExportGroupingItem[];
}

export type Props = OwnProps & InjectedProps;

const { Option, OptGroup } = Select;

export default class DataExportSelect extends React.Component<Props> {
  renderDataExportSelectOption = (dataExport: DataExport) => {
    return (
      <Option disabled={!dataExport.isValid} key={dataExport.id} value={dataExport.id}>
        {dataExport.name}
        {!dataExport.isValid && ' (invalid)'}
      </Option>
    );
  };

  renderDataExportSelectItem = (item: DataExportGroupingItem) => {
    if (item.type === 'dataExportGroup') {
      return (
        <OptGroup key={item.id} label={item.name}>
          {(item as DataExportGroup).dataExports.map(this.renderDataExportSelectOption)}
        </OptGroup>
      );
    }
    if (item.type === 'dataExport') {
      return this.renderDataExportSelectOption(item as DataExport);
    }
  };

  render = () => {
    return (
      <Select
        className="app-DataExportSelect"
        onChange={this.props.onChange}
        size="small"
        value={this.props.value}
      >
        <Option key="no-value" value="">
          -
        </Option>
        {this.props.dataExportGrouping.map(this.renderDataExportSelectItem)}
      </Select>
    );
  };
}
