import fileSaver from 'file-saver';
import qs from 'querystringify';

import * as notifications from 'services/notifications';
import { Credentials } from 'types';

export interface XLSXQueryOptions {
  filter?: string;
  sort?: string;
}

export function exportXLSX(
  credentials: Credentials,
  id: string,
  queryOptions: XLSXQueryOptions
): Promise<any> {
  const query = qs.stringify(queryOptions);
  const params = query ? `?${query}` : '';
  const endpoint = `table-views/${id}/xlsx${params}`;
  const baseUrl = process.env.API_URL;
  const url = `${baseUrl}/organizations/${credentials.organizationId}/${endpoint}`;
  const fetchOptions: any = {
    credentials: 'include',
    headers: {
      Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'X-CSRF-TOKEN': credentials.csrfToken,
    },
  };
  return window.fetch(url, fetchOptions).then((response) => {
    if (response.status === 200) {
      response.blob().then((blob: any) => fileSaver.saveAs(blob, 'data.xlsx'));
    } else {
      notifications.show('excel-export-failure');
    }
  });
}
