import { DownOutlined, MenuOutlined } from '@ant-design/icons';
import { Button, Collapse, Dropdown } from 'antd';
import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import logo from 'assets/images/logo.svg';

const { Panel } = Collapse;
const quickbiUrl = 'https://quickbi.io';

interface Props {}

interface State {
  mobileMenuOpen: boolean;
}

export default class LoginTopbar extends React.Component<Props> {
  state: State = {
    mobileMenuOpen: false,
  };

  toggleMobileMenu = () => {
    this.setState({
      mobileMenuOpen: !this.state.mobileMenuOpen,
    });
  };

  renderNavLink(text: string, path: string) {
    const url = path ? `${quickbiUrl}/${path}` : quickbiUrl;
    return (
      <a className="app-LoginTopbar__navigation-link" href={url}>
        {text}
      </a>
    );
  }

  renderNavLinks() {
    const serviceLinks = (
      <div className="app-LoginTopbar__service-links">
        <div>{this.renderNavLink('Data Connection', 'services-data-connection')}</div>
        <div>{this.renderNavLink('Data Warehouse', 'services-data-warehouse')}</div>
        <div>{this.renderNavLink('Consultancy', 'services-consultancy')}</div>
      </div>
    );

    return (
      <div className="app-LoginTopbar__navigation">
        <Dropdown overlay={serviceLinks} trigger={['click']}>
          <a className="app-LoginTopbar__navigation-link" href="#">
            Services <DownOutlined className="app-LoginTopbar__navigation-icon" />
          </a>
        </Dropdown>
        {this.renderNavLink('About', 'about')}
        {this.renderNavLink('Careers', 'careers')}
        {this.renderNavLink('Contact', 'contact')}
        <Link className="app-LoginTopbar__navigation-link app-LoginTopbar__login-link" to="/">
          Log in
        </Link>
      </div>
    );
  }

  renderMobileMenu() {
    return (
      <div className="app-LoginTopbar__mobile-menu">
        <Collapse activeKey={this.state.mobileMenuOpen ? 'mobile-menu' : ''} bordered={false}>
          <Panel header="" key="mobile-menu" showArrow={false}>
            {this.renderNavLinks()}
          </Panel>
        </Collapse>
      </div>
    );
  }

  renderMobileMenuToggle = () => {
    return (
      <Button
        className={classNames('app-LoginTopbar__mobile-menu-toggle', {
          'app-LoginTopbar__mobile-menu-toggle--open': this.state.mobileMenuOpen,
        })}
        onClick={this.toggleMobileMenu}
        type="default"
      >
        <MenuOutlined className="app-LoginTopbar__mobile-menu-toggle-icon" />
      </Button>
    );
  };

  render() {
    return (
      <div className="app-LoginTopbar">
        <div className="app-LoginTopbar__container">
          <a href={quickbiUrl}>
            <img alt="QuickBI logo" className="app-LoginTopbar__logo" src={logo} />
          </a>
          <div className="app-LoginTopbar__desktop-menu">{this.renderNavLinks()}</div>
          {this.renderMobileMenuToggle()}
        </div>
        {this.renderMobileMenu()}
      </div>
    );
  }
}
