import { SettingOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import classNames from 'classnames';
import CustomStore from 'devextreme/data/custom_store';
import DXDataGrid from 'devextreme-react/ui/data-grid';
import React from 'react';

import { getDefaultDxFormatter } from 'shared/utils';
import * as types from 'types';

const ROW_NUMBER_COLUMN_WIDTH = 44;

const columnChooserOptions: any = {
  enabled: false,
  height: 400,
  mode: 'select',
  title: 'Select visible columns',
};

export interface Props {
  readonly columns: types.DxColumns;
  readonly disableRemoteOperations: boolean;
  readonly exportXLSX?: Function | undefined;
  readonly height?: number | string;
  readonly loadFunction: any;
  readonly maxDecimals?: number;
  readonly pageSize?: number;
  readonly showColumnEditor?: Function | undefined;
  readonly width?: number | string;
}

export default class DataGrid extends React.Component<Props> {
  static defaultProps = {
    disableRemoteOperations: false,
    exportXLSX: undefined,
    height: '100%',
    pageSize: 100,
    showColumnEditor: undefined,
    width: '100%',
  };

  gridRef: any;

  getChangingProps = () => {
    if (this.props.disableRemoteOperations) {
      return {};
    }
    return {
      defaultColumns: this.getColumns(),
      paging: {
        pageSize: this.props.pageSize,
      },
      remoteOperations: {
        filtering: true,
        paging: true,
        sorting: true,
      },
    };
  };

  attachFormatting = (columns: types.DxColumns) => {
    const dxFormatter = getDefaultDxFormatter(this.props.maxDecimals || null);
    columns.forEach((column: types.DxBand | types.DxColumn) => {
      if (Object.prototype.hasOwnProperty.call(column, 'columns')) {
        const bandColumn = column as types.DxBand;
        for (const child of bandColumn.columns) {
          child.format = child.format || dxFormatter;
        }
      } else {
        const regularColumn = column as types.DxColumn;
        regularColumn.format = regularColumn.format || dxFormatter;
      }
    });
    return columns;
  };

  getColumns = () => {
    if (!this.props.columns.length) {
      return undefined;
    }
    const columns: types.DxColumns = this.attachFormatting(this.props.columns);
    const rowNumberColumn = this.getRowNumberColumn();
    // Check if the columns are banded. Banding column contains other columns.
    if (!Object.prototype.hasOwnProperty.call(columns[0], 'columns')) {
      return [rowNumberColumn, ...columns];
    }
    const rowNumberBand = {
      allowHiding: false,
      allowReordering: false,
      allowResizing: false,
      caption: '',
      columns: [rowNumberColumn],
      fixed: true,
      fixedPosition: 'left',
    };
    return [rowNumberBand, ...columns];
  };

  getDataSource = () => {
    const load = (loadOptions: any) => this.props.loadFunction(loadOptions);
    return {
      store: new CustomStore({ load }),
    };
  };

  getRowNumberColumn = () => {
    const getGridInstance = () => {
      return this.gridRef && this.gridRef.instance;
    };
    const rowNumberColumn = {
      alignment: 'right',
      allowHiding: false,
      allowReordering: false,
      allowResizing: false,
      caption: '#',
      cellTemplate(cellElement: any, cellInfo: { rowIndex: number }) {
        // Calculating missed rows uses private API of devextreme.
        // Needed here because we are using virtual scrolling.
        /* eslint-disable-next-line max-len */
        // More info: https://www.devexpress.com/Support/Center/Question/Details/T496284/dxdatagrid-how-to-display-a-row-number-in-data-rows-in-angular
        const missedRowsCount = getGridInstance().getController('data').virtualItemsCount().begin;
        const rowNumber = missedRowsCount + cellInfo.rowIndex + 1;
        cellElement.innerText = rowNumber;
        cellElement.classList.add('app-DataGrid__row-number-cell');
      },
      fixed: true,
      fixedPosition: 'left',
      width: ROW_NUMBER_COLUMN_WIDTH,
    };
    return rowNumberColumn;
  };

  handleSettingsMenuClick = ({ key }: any) => {
    if (key === 'column-chooser') {
      this.gridRef.instance.showColumnChooser();
    } else if (key === 'edit-columns' && this.props.showColumnEditor) {
      this.props.showColumnEditor();
    } else if (key === 'xlsx-export' && this.props.exportXLSX) {
      this.props.exportXLSX();
    } else {
      throw new Error('Invalid key');
    }
  };

  render() {
    const settingsMenu = (
      <Menu className="app-DataGrid-settings-dropdown" onClick={this.handleSettingsMenuClick}>
        {this.props.showColumnEditor && <Menu.Item key="edit-columns">Edit columns</Menu.Item>}
        <Menu.Item key="column-chooser">Select visible columns</Menu.Item>
        {this.props.exportXLSX && <Menu.Item key="xlsx-export">Export to Excel</Menu.Item>}
      </Menu>
    );
    const dataGridClassNames = classNames('app-DataGrid', {
      'app-DataGrid--no-row-number-column': this.props.disableRemoteOperations,
    });
    return (
      <div className={dataGridClassNames}>
        <Dropdown overlay={settingsMenu} trigger={['click']}>
          <SettingOutlined
            className="app-DataGrid-settings"
            style={{ width: ROW_NUMBER_COLUMN_WIDTH - 1 }}
          />
        </Dropdown>
        <DXDataGrid
          allowColumnReordering
          allowColumnResizing
          columnAutoWidth
          columnChooser={columnChooserOptions}
          columnFixing={{ enabled: true }}
          columnResizingMode="widget"
          dataSource={this.getDataSource()}
          filterRow={{ visible: true }}
          headerFilter={{ visible: true }}
          height={this.props.height}
          hoverStateEnabled
          ref={(grid) => {
            this.gridRef = grid;
          }}
          rowAlternationEnabled
          scrolling={{ mode: 'virtual' }}
          sorting={{ mode: 'multiple' }}
          width={this.props.width}
          {...this.getChangingProps()}
        />
      </div>
    );
  }
}
