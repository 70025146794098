import { Action, createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

import { TableView } from 'types';

interface State {
  readonly activeTableView: TableView | null;
  readonly activeTableViewId: string | null;
}

// -------
// Actions
// -------

export const fetch = createAction('app/data/tableViews/fetch', (id: string) => id);

export const saveColumns = createAction('app/data/tableViews/saveColumns');

// ---------
// Selectors
// ---------

function select(state: any): State {
  return state.data.tableViews;
}

export const selectActiveTableView = (tableViewIdSelector: any) => {
  return createSelector(
    tableViewIdSelector,
    select,
    (tableViewId: string, state: State): TableView | null =>
      tableViewId === state.activeTableViewId ? state.activeTableView : null
  );
};

// -----
// State
// -----

type TableViewAction = Action<TableView>;
const initialState: State = {
  activeTableView: null,
  activeTableViewId: null,
};
const handlers = {
  [String(fetch)]: (state: State, action: any): State => {
    return {
      ...state,
      activeTableView: null,
      activeTableViewId: action.payload,
    };
  },
  [`${fetch}.success`]: (state: State, action: TableViewAction): State => {
    const payload = action.payload as TableView;
    return {
      ...state,
      activeTableView: payload,
    };
  },
};
export default handleActions(handlers, initialState);
