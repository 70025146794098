import normalize from 'json-api-normalizer';
import build from 'redux-object';

import { Credentials, DashboardItem, DashboardItemEditData } from 'types';
import { makeApiRequest } from './utils';

export function editDashboardItem(
  credentials: Credentials,
  data: DashboardItemEditData
): Promise<DashboardItem> {
  const jsonAPIFormattedData = {
    data: {
      id: data.id,
      type: 'generic_dashboard_items',
      attributes: data.attributes,
      relationships: data.relationships,
    },
  };
  return makeApiRequest<any>(
    credentials,
    `generic_dashboard_items/${data.id}`,
    'PATCH',
    jsonAPIFormattedData
  ).then((json: any) => {
    if (json.data) {
      return build(normalize(json), 'genericDashboardItems', data.id, { eager: true });
    }
    return json;
  });
}

export function getDashboardItemData(
  credentials: Credentials,
  id: string,
  options: {} = {}
): Promise<any> {
  return makeApiRequest<any>(
    credentials,
    `generic_dashboard_items/${id}/get_data`,
    'POST',
    options
  ).then((json: any) => json.data.attributes.data);
}
