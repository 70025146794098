import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

import { reset } from 'state/data';
import { DataSource, SourceTable, SourceTableGroup } from 'types';

type State = DataSource | {};

// -------
// Actions
// -------

export const deleteSourceTableGroup = createAction('app/data/dataSource/deleteSourceTableGroup');

export const fetch = createAction('app/data/dataSource/fetch');

export const saveSourceTableGroup = createAction('app/data/dataSource/saveSourceTableGroup');

export const syncSourceTableGroup = createAction('app/data/dataSource/syncSourceTableGroup');

export const upload = createAction('app/data/dataSource/upload');

// ---------
// Selectors
// ---------

export function select(state: any): State {
  return state.data.dataSource;
}

export const selectSourceTableGroups = createSelector(
  select,
  (state: State): SourceTableGroup[] => {
    const sourceTableGroups = get(state, 'sourceTableGroups', []);
    return sortBy(sourceTableGroups, (group) => group.name.toLowerCase());
  }
);

export const selectSourceTables = createSelector(select, (state: State): SourceTable[] => {
  let sourceTables: SourceTable[] = [];
  get(state, 'sourceTableGroups', []).forEach((group: SourceTableGroup) => {
    sourceTables = sourceTables.concat(group.sourceTables);
  });
  return sortBy(sourceTables, 'name');
});

export const selectTableNames = createSelector(selectSourceTables, (sourceTables): string[] => {
  const names: string[] = [];
  sourceTables.forEach((sourceTable) => {
    names.push(sourceTable.name);
  });
  return uniq(names);
});

export const selectColumnNames = createSelector(selectSourceTables, (sourceTables): string[] => {
  const names: string[] = [];
  sourceTables.forEach((sourceTable) => {
    names.push(...sourceTable.columns.map((column) => column.name));
  });
  return uniq(names);
});

export const selectDataSourceUploadInfo = createSelector(select, (dataSource: DataSource): {} => ({
  uploadToken: get(dataSource, 'uploadToken', ''),
  uploadUrl: get(dataSource, 'uploadUrl', ''),
}));

// -----
// State
// -----

const initialState: State = {};

const handlers = {
  [String(fetch)]: (): State => ({}),
  [`${fetch}.success`]: (state: State, action: any): State => action.payload,
  [String(reset)]: (): State => initialState,
};

export default handleActions(handlers, initialState);
