import { createAction } from 'redux-actions';
import { createLogic } from 'redux-logic';

import * as api from 'services/api';
import * as notifications from 'services/notifications';
import * as auth from 'state/auth';
import * as dashboardItems from 'state/data/dashboardItems';
import * as dashboards from 'state/data/dashboards';
import * as dataExports from 'state/data/dataExports';
import * as dataExportsForFront from 'state/data/dataExportsForFront';
import * as dataSource from 'state/data/dataSource';
import * as editorTabs from 'state/fox/editorTabs';
import * as pivots from 'state/data/pivots';
import * as tableViews from 'state/data/tableViews';
import * as views from 'state/data/views';
import { Credentials, ExtendedApiError } from 'types';

function createApiLogic(apiAction: any, apiFunction: any) {
  return createLogic({
    type: String(apiAction),
    transform({ action }, next) {
      action.meta = {
        ...action.meta,
        isApiAction: true,
        requestActionType: action.type,
      };
      next(action);
    },
    process({ action, getState }: { action: any; getState: any }, dispatch, done) {
      const state = getState();
      const credentials: Credentials = auth.selectCredentials(state);

      const successAction = createAction(
        `${action.type}.success`,
        (payload: any) => payload,
        () => action.meta
      );

      const failureAction = createAction(
        `${action.type}.failure`,
        (payload: ExtendedApiError) => payload,
        () => action.meta
      );

      apiFunction(credentials, action.payload)
        .then((data: any) => {
          dispatch(successAction(data));
        })
        .catch((error: ExtendedApiError) => {
          if (error.status === 401) {
            dispatch(auth.reset());
          }
          if (error.status === 404) {
            notifications.show('not-found-failure');
          }
          if (error.status === 0) {
            notifications.show('request-failure');
          }
          dispatch(failureAction(error));
        })
        .then(() => done());
    },
  });
}

export default [
  createApiLogic(dashboardItems.edit, api.editDashboardItem),
  createApiLogic(dashboards.edit, api.editDashboard),
  createApiLogic(dashboards.fetch, api.getDashboard),
  createApiLogic(dataExports.edit, api.editDataExport),
  createApiLogic(dataExports.fetch, api.getDataExportsForFox),
  createApiLogic(dataExportsForFront.fetch, api.getDataExportsForFront),
  createApiLogic(dataExports.remove, api.deleteDataExport),
  createApiLogic(dataExports.save, api.saveDataExport),
  createApiLogic(dataExports.saveGrouping, api.saveDataExportGrouping),
  createApiLogic(dataSource.deleteSourceTableGroup, api.deleteSourceTableGroup),
  createApiLogic(dataSource.fetch, api.getDataSource),
  createApiLogic(dataSource.saveSourceTableGroup, api.saveSourceTableGroup),
  createApiLogic(dataSource.syncSourceTableGroup, api.syncSourceTableGroup),
  createApiLogic(dataSource.upload, api.uploadDataSource),
  createApiLogic(editorTabs.validateTabSql, api.validateSql),
  createApiLogic(pivots.edit, api.editPivot),
  createApiLogic(pivots.fetch, api.getPivot),
  createApiLogic(tableViews.fetch, api.getTableView),
  createApiLogic(tableViews.saveColumns, api.saveColumns),
  createApiLogic(views.fetch, api.getViews),
  createApiLogic(views.save, api.saveViews),
];
