import { decamelizeKeys } from 'humps';
import normalize from 'json-api-normalizer';
import build from 'redux-object';

import { csvToObjectArray } from 'shared/utils';
import { Credentials, Pivot, PivotEditData } from 'types';
import { makeApiRequest, makeApiRequestForText } from './utils';

export function getPivot(credentials: Credentials, id: string): Promise<Pivot> {
  return makeApiRequest<any>(credentials, `pivots/${id}`).then((json: any) =>
    build(normalize(json), 'pivots', id)
  );
}

type Value = number | string;
interface PivotItem {
  [key: string]: Value;
}

export function getFullPivotData(credentials: Credentials, id: string): Promise<PivotItem[]> {
  return makeApiRequestForText<any>(credentials, `pivots/${id}/data?format=csv`).then(
    csvToObjectArray
  );
}

export function editPivot(credentials: Credentials, data: PivotEditData): Promise<Pivot> {
  const jsonAPIFormattedData = {
    data: {
      id: data.id,
      attributes: decamelizeKeys(data.attributes),
      type: 'pivots',
    },
  };
  return makeApiRequest<any>(credentials, `pivots/${data.id}`, 'PATCH', jsonAPIFormattedData);
}
