import { Alert, Spin } from 'antd';
import get from 'lodash/get';
import React from 'react';

import PivotTable from 'shared/PivotTable';
import { getFullPivotData } from 'services/api/pivots';
import * as types from 'types';

export interface OwnProps {
  readonly id: string;
}

export interface InjectedProps {
  readonly canEdit: boolean;
  readonly credentials: types.Credentials;
  readonly editPivot: (data: types.PivotEditData) => void;
  readonly fetchPivot: (id: string) => void;
  readonly fields: types.DxPivotField[];
  readonly fieldIdsByField: { [index: string]: string };
  readonly isSidebarCollapsed: boolean;
  readonly pivot: types.Pivot | null;
}

type Props = OwnProps & InjectedProps;

export default class PivotView extends React.Component<Props> {
  UNSAFE_componentWillMount() {
    this.props.fetchPivot(this.props.id);
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.id !== this.props.id) {
      this.props.fetchPivot(this.props.id);
    }
  }

  editPivot = (attributes: types.PivotSaveData) => {
    if (!this.props.pivot) return;
    const data: types.PivotEditData = {
      attributes,
      id: this.props.pivot.id,
    };
    this.props.editPivot(data);
  };

  getLoadFunction = () => {
    const { credentials, fields, id } = this.props;
    if (fields) {
      return () => getFullPivotData(credentials, id);
    }
    throw new Error("Couldn't get pivot data source.");
  };

  render() {
    const { pivot } = this.props;
    if (!pivot) {
      return (
        <div className="app-PivotView__loading">
          <Spin />
        </div>
      );
    }

    if (!pivot.isValid) {
      return (
        <div className="app-PivotView">
          <Alert
            message="The data this view is using is no longer valid"
            description={
              'Please contact your organization admin or QuickBI ' +
              'support to resolve this issue.'
            }
            type="warning"
          />
        </div>
      );
    }

    const fieldData = pivot.fieldData;
    const isPivotEditable = Boolean(get(fieldData, '[0].id', false));
    const canEdit = this.props.canEdit && isPivotEditable;
    return (
      <div className="app-PivotView">
        <PivotTable
          canEdit={canEdit}
          chartType={pivot.chartType}
          editPivot={this.editPivot}
          fields={this.props.fields}
          fieldIdsByField={this.props.fieldIdsByField}
          loadFunction={this.getLoadFunction()}
          maxDecimals={2}
          showPanel={pivot.showPanel}
          width={this.props.isSidebarCollapsed ? '100%' : undefined}
        />
      </div>
    );
  }
}
