import { Input, Radio, Select } from 'antd';
import classNames from 'classnames';
import React from 'react';
import { SortableHandle } from 'react-sortable-hoc';

import { TableViewColumn } from 'types';
import { RadioChangeEvent } from 'antd/lib/radio';

const Option = Select.Option;
const RadioGroup = Radio.Group;

const DragHandle = SortableHandle(() => <div className="app-Column__drag-handle" />);

export type ColumnChangeHandler = (columnId: string, data: {}) => void;

interface Props {
  column: TableViewColumn;
  onColumnChange: ColumnChangeHandler;
}

export default class Column extends React.Component<Props> {
  handleChange = (data: {}) => {
    const { column, onColumnChange } = this.props;
    onColumnChange(column.id, data);
  };

  handleDataTypeChange = (dataType: string) => {
    this.handleChange({ dataType });
  };

  handleFilterSortingChange = (event: RadioChangeEvent) => {
    const isDescFilterSorting = event.target.value === 'desc';
    this.handleChange({ isDescFilterSorting });
  };

  handleFixedChange = (fixed: string) => {
    this.handleChange({ fixed });
  };

  handleFormatChange = (format: string) => {
    this.handleChange({ format });
  };

  handleHeaderGroupChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const headerGroup = event.target.value;
    this.handleChange({ headerGroup });
  };

  handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value;
    this.handleChange({ name });
  };

  handleWidthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const width = parseInt(event.target.value, 10) || null;
    this.handleChange({ width });
  };

  render() {
    const { column } = this.props;
    return (
      <div className="app-Column">
        <div className="app-Column__header">
          <DragHandle />
          <span>{column.field}</span>
        </div>
        <div className="app-Column__row">
          <Input
            className={classNames('app-Column__input', {
              'app-Column__input--error': !column.name,
            })}
            name="name"
            onChange={this.handleNameChange}
            value={column.name}
          />
        </div>
        <div className="app-Column__row">
          <Input
            name="headerGroup"
            onChange={this.handleHeaderGroupChange}
            value={column.headerGroup}
          />
        </div>
        <div className="app-Column__row">
          <Select onChange={this.handleFixedChange} value={column.fixed || ''}>
            <Option value="">-</Option>
            <Option value="left">left</Option>
            <Option value="right">right</Option>
          </Select>
        </div>
        <div className="app-Column__row">
          <Input
            name="width"
            onChange={this.handleWidthChange}
            type="number"
            value={column.width || ''}
          />
        </div>
        <div className="app-Column__row">
          <Select onChange={this.handleDataTypeChange} value={column.dataType}>
            <Option value="string">text</Option>
            <Option value="number">number</Option>
            <Option value="boolean">boolean</Option>
            <Option value="date">date</Option>
            <Option value="datetime">datetime</Option>
          </Select>
        </div>
        <div className="app-Column__row">
          <Select onChange={this.handleFormatChange} value={column.format}>
            <Option value="">-</Option>
            <Option value="number">number</Option>
            <Option value="date">date</Option>
            <Option value="datetime">datetime</Option>
            <Option value="EUR">Euro</Option>
          </Select>
        </div>
        <div className="app-Column__row">
          <RadioGroup
            onChange={this.handleFilterSortingChange}
            value={column.isDescFilterSorting ? 'desc' : 'asc'}
          >
            <Radio value="desc">desc</Radio>
            <Radio value="asc">asc</Radio>
          </RadioGroup>
        </div>
      </div>
    );
  }
}
