import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as auth from 'state/auth';

export const selector = createStructuredSelector({
  isAuthenticated: auth.selectIsAuthenticated,
  isAuthStarted: auth.selectIsAuthStarted,
  isFetchingSession: auth.selectIsFetchingSession,
});

const actions = {
  loadAuth: auth.load,
};

export default connect(selector, actions);
