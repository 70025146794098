import { decamelizeKeys } from 'humps';
import includes from 'lodash/includes';
import startsWith from 'lodash/startsWith';

// -------------------------
// Internal helper functions
// -------------------------

function getUUIDOrNull(item: { id: string | null }) {
  if (!item.id || startsWith(item.id, 'generated')) {
    return null;
  }
  return item.id;
}

function parseViewKind(kind: string) {
  const kinds: { [index: string]: string } = {
    tableInternal: 'table',
    pivotInternal: 'pivot',
    dashboard: 'dashboard',
  };
  return kinds[kind] || 'iframe';
}

function getViewCategoryData(viewCategory: any) {
  return {
    id: getUUIDOrNull(viewCategory),
    name: viewCategory.name,
  };
}

function getViewGroupData(viewGroup: any) {
  const isAccessRestricted = viewGroup.isAccessRestricted;

  return {
    isAccessRestricted,
    allowedTeamIds: isAccessRestricted ? viewGroup.allowedTeamIds : [],
    id: getUUIDOrNull(viewGroup),
    name: viewGroup.name,
    urlChanger: viewGroup.urlChanger || null,
    urlChangerText: viewGroup.urlChangerText,
  };
}

function getViewData(view: any) {
  const dataViewId = includes(['tableInternal', 'pivotInternal'], view.kind)
    ? view.dataViewId
    : null;
  const url = includes(['iframe'], view.kind) ? view.url : '';
  return {
    dataViewId,
    url,
    id: getUUIDOrNull(view),
    kind: parseViewKind(view.kind),
    text: view.text,
  };
}

// ------------------
// Exported functions
// ------------------

export function generateNodeId() {
  const time = new Date().getTime();
  return `generated-id-${time}`;
}

export function getNodeKey({ node }: { node: any }) {
  return node.id;
}

export function buildFullData(sidebarTreeData: any, viewGroups: any) {
  const data = sidebarTreeData.map((viewCategory: any) => ({
    ...getViewCategoryData(viewCategory),
    viewGroups: viewCategory.children.map((viewGroupNode: any) => {
      const viewGroup = viewGroups[viewGroupNode.id];
      return {
        ...getViewGroupData(viewGroup),
        views: viewGroup.views.map(getViewData),
      };
    }),
  }));
  return decamelizeKeys(data);
}

export function getExpandedViewKind(view: any) {
  if (view.kind === 'table' || view.kind === 'pivot') {
    const postFix = view.url === '' ? 'Internal' : 'External';
    return view.kind + postFix;
  }
  return view.kind;
}
