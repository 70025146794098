import { Form, Input, Select } from 'antd';
import includes from 'lodash/includes';
import React from 'react';

import DataExportSelect from 'shared/DataExportSelect';

interface Props {
  readonly onChange: any;
  readonly view: any;
}

const { Option } = Select;
const FormItem = Form.Item;

const formItemProps = {
  className: 'app-ViewEditor__form-item',
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

export default class ViewEditor extends React.Component<Props> {
  handleDataViewIdChange = (dataViewId: string) => {
    this.props.onChange({ dataViewId });
  };

  handleKindChange = (kind: string) => {
    this.props.onChange({ kind });
  };

  handleNameChange = (event: any) => {
    const name = event.target.value;
    this.props.onChange({ text: name });
  };

  handleUrlChange = (event: any) => {
    const url = event.target.value;
    this.props.onChange({ url });
  };

  renderDataExportSelect = () => {
    return (
      <FormItem label="Data Export" {...formItemProps}>
        <DataExportSelect
          onChange={this.handleDataViewIdChange}
          value={this.props.view.dataViewId || ''}
        />
      </FormItem>
    );
  };

  renderNameInput = () => {
    const { view } = this.props;
    return (
      <FormItem label="Name" {...formItemProps}>
        <Input onChange={this.handleNameChange} size="small" value={view.text} />
      </FormItem>
    );
  };

  renderTypeSelect = () => {
    const { view } = this.props;
    return (
      <FormItem label="Type" {...formItemProps}>
        <Select onChange={this.handleKindChange} size="small" value={view.kind || 'iframe'}>
          <Option value="iframe">Embed</Option>
          <Option value="tableInternal">Internal Table</Option>
          <Option value="pivotInternal">Internal Pivot</Option>
          <Option value="dashboard">Dashboard</Option>
        </Select>
      </FormItem>
    );
  };

  renderUrlIdInput = () => {
    const { view } = this.props;
    return (
      <FormItem label="Url" {...formItemProps}>
        <Input onChange={this.handleUrlChange} size="small" value={view.url} />
      </FormItem>
    );
  };

  render() {
    const { view } = this.props;

    return (
      <div className="app-ViewEditor">
        <Form layout="horizontal">
          {this.renderNameInput()}
          {this.renderTypeSelect()}
          {includes(['iframe'], view.kind) && this.renderUrlIdInput()}
          {includes(['tableInternal', 'pivotInternal'], view.kind) && this.renderDataExportSelect()}
        </Form>
      </div>
    );
  }
}
