import { connect } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';

import { calculateCountUniqueSummary, getDefaultDataAreaField } from 'shared/PivotTable/utils';
import * as auth from 'state/auth';
import * as pivots from 'state/data/pivots';
import * as sidebar from 'state/front/sidebar';
import * as types from 'types';
import { OwnProps } from './PivotView';

function selectPivotId(state: any, props: OwnProps) {
  return props.id;
}

const selectPivot = pivots.selectActivePivot(selectPivotId);

function getRegularFields(pivot: types.Pivot): types.DxPivotField[] {
  return pivot.fieldData.map((field) => {
    const data: types.DxPivotField = {
      area: field.area === '' ? 'filter' : field.area,
      caption: field.name,
      dataField: field.field,
      dataType: field.dataType,
      expanded: true,
      filterType: field.filterType,
    };
    if (field.sortOrder !== '') {
      data.sortOrder = field.sortOrder;
    }
    if (field.filterValues.length) {
      data.filterValues = field.filterValues;
    }
    return data;
  });
}

function getDataAreaFields(pivot: types.Pivot): types.DxPivotField[] {
  const fields = pivot.aggregates.map((aggregate) => {
    const field: types.DxPivotField = {
      area: 'data',
      dataField: aggregate.field,
      isMeasure: true,
    };
    if (aggregate.func) {
      if (aggregate.func === 'count_unique') {
        field.summaryType = 'custom';
        field.calculateCustomSummary = calculateCountUniqueSummary;
      } else {
        field.summaryType = aggregate.func;
      }
    }
    return field;
  });
  return fields.length ? fields : [getDefaultDataAreaField()];
}

const fieldsSelector = createSelector(selectPivot, (pivot: types.Pivot | null) => {
  if (!pivot || !pivot.fieldData) return undefined;

  const dataAreaFields = getDataAreaFields(pivot);
  const regularFields = getRegularFields(pivot);
  return dataAreaFields.concat(regularFields);
});

const fieldIdsByFieldSelector = createSelector(selectPivot, (pivot: types.Pivot | null) => {
  if (!pivot || !pivot.fieldData) return undefined;

  const data: { [index: string]: string | undefined } = {};
  for (const field of pivot.fieldData) {
    data[field.field] = field.id;
  }
  return data;
});

const selector = createStructuredSelector({
  canEdit: auth.selectIsAdminFront,
  credentials: auth.selectCredentials,
  id: selectPivotId,
  isSidebarCollapsed: sidebar.selectIsCollapsed,
  fields: fieldsSelector,
  fieldIdsByField: fieldIdsByFieldSelector,
  pivot: selectPivot,
});

const actions = {
  editPivot: pivots.edit,
  fetchPivot: pivots.fetch,
};

export default connect(selector, actions);
