import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as api from 'state/api';
import * as auth from 'state/auth';
import * as dataSource from 'state/data/dataSource';
import modals from 'state/modals';

export const selector = createStructuredSelector({
  errorMessages: api.selectSaveSourceTableGroupErrors,
  gsServiceAccountEmail: auth.selectGsServiceAccountEmail,
  isSaving: api.selectIsSaveSourceTableGroupPending,
  isVisible: modals.selectIsAddGoogleSheetVisible,
});

const actions = {
  hideModal: modals.hideAddGoogleSheet,
  saveSourceTableGroup: dataSource.saveSourceTableGroup,
};

export default connect(selector, actions);
