import { EditOutlined } from '@ant-design/icons';
import { Button, Checkbox, Popover, Select, Typography } from 'antd';
import compact from 'lodash/compact';
import find from 'lodash/find';
import React from 'react';

import { Team } from 'types';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

const { Option } = Select;
const { Text } = Typography;

export interface OwnProps {
  readonly allowedTeamIds: string[];
  readonly isAccessRestricted: boolean;
  readonly onChange: (data: {}) => void;
}

export interface InjectedProps {
  readonly teams: Team[];
}

export type Props = OwnProps & InjectedProps;

export default class TeamAccessSelect extends React.Component<Props> {
  handleAllowedTeamIdsChange = (allowedTeamIds: string[]) => {
    this.props.onChange({ allowedTeamIds });
  };

  handleIsAccessRestrictedChange = (event: CheckboxChangeEvent) => {
    this.props.onChange({ isAccessRestricted: event.target.checked });
  };

  renderEditor = () => {
    if (!this.props.teams.length) {
      return <div>Please contact QuickBI support to restrict visibility.</div>;
    }
    return (
      <div className="app-TeamAccessSelect__edit">
        <Checkbox
          className="app-TeamAccessSelect__checkbox"
          checked={this.props.isAccessRestricted}
          onChange={this.handleIsAccessRestrictedChange}
        >
          Restrict access
          {this.props.isAccessRestricted && ' to'}
        </Checkbox>
        {this.props.isAccessRestricted && (
          <Select
            className="app-TeamAccessSelect__select"
            mode="multiple"
            onChange={this.handleAllowedTeamIdsChange}
            size="small"
            value={this.props.allowedTeamIds}
          >
            {this.props.teams.map(this.renderOption)}
          </Select>
        )}
      </div>
    );
  };

  renderOption = (team: Team) => {
    return (
      <Option key={team.id} value={team.id}>
        {team.name}
      </Option>
    );
  };

  renderVisibilityText = () => {
    const { allowedTeamIds, isAccessRestricted, teams } = this.props;
    if (!isAccessRestricted) {
      return <span>everyone</span>;
    }

    if (!allowedTeamIds.length) {
      return <Text strong>no one</Text>;
    }

    const visibleTeams = allowedTeamIds.map((teamId: string) => {
      const team = find(teams, ['id', teamId]);
      return team ? team.name : '';
    });
    const teamNames = compact(visibleTeams).join(', ');
    return <Text strong>{teamNames}</Text>;
  };

  render = () => {
    return (
      <div className="app-TeamAccessSelect">
        <Popover content={this.renderEditor()} placement="bottomLeft" trigger="click">
          <div className="app-TeamAccessSelect__visibility-text">
            Visible to {this.renderVisibilityText()}
          </div>
          <Button
            className="app-TeamAccessSelect__edit-button"
            icon={<EditOutlined />}
            size="small"
            shape="circle"
          />
        </Popover>
      </div>
    );
  };
}
