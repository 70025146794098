import { combineActions, createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

interface State {
  [id: string]: any;
}

// -------
// Actions
// -------

export const edit = createAction('app/data/dashboards/edit');

export const fetch = createAction('app/data/dashboards/fetch');

// ---------
// Selectors
// ---------

type DashboardIdSelector = ({}, {}) => string;

export function select(state: any): State {
  return state.data.dashboards;
}

export const selectDashboardFactory = (idSelector: DashboardIdSelector): any =>
  createSelector(select, idSelector, (state: State, dashboardId: string) => {
    if (dashboardId) {
      return state[dashboardId] || null;
    }
    return null;
  });

// -----
// State
// -----

const initialState: State = {};

const handlers = {
  [combineActions(`${fetch}.success`, `${edit}.success`) as any]: (
    state: State,
    action: any
  ): State => {
    const dashboard = action.payload;
    return {
      ...state,
      [dashboard.id]: dashboard,
    };
  },
};

export default handleActions(handlers, initialState);
