import { Modal } from 'antd';
import yaml from 'js-yaml';
import React from 'react';

import ErrorMessages from 'shared/ErrorMessages';
import SpecsEditor from 'shared/SpecsEditor';
import { Dashboard, DashboardEditData, ErrorMessage } from 'types';

export interface OwnProps {
  readonly dashboard: Dashboard;
}

export interface InjectedProps {
  readonly editDashboard: (data: DashboardEditData) => void;
  readonly errorMessages: ErrorMessage[];
  readonly hideModal: () => void;
  readonly isSaving: boolean;
  readonly isVisible: boolean;
  readonly specs: string;
}

type Props = OwnProps & InjectedProps;

interface State {
  specs: string;
}

export default class DashboardEditor extends React.Component<Props, State> {
  state: State = {
    specs: this.props.specs,
  };

  componentDidUpdate(prevProps: Props) {
    if (prevProps.specs !== this.props.specs) {
      this.setState({ specs: this.props.specs });
    }
  }

  handleChange = (specs: string) => {
    this.setState({ specs });
  };

  handleSave = () => {
    const { dashboard } = this.props;
    const attributes = yaml.load(this.state.specs);
    this.props.editDashboard({ attributes, id: dashboard.id });
  };

  render() {
    return (
      <div className="app-DashboardEditor">
        <Modal
          className="app-DashboardEditor__modal"
          confirmLoading={this.props.isSaving}
          destroyOnClose
          maskClosable={false}
          okText="Save"
          onCancel={this.props.hideModal}
          onOk={this.handleSave}
          title="Edit dashboard specs"
          visible={this.props.isVisible}
          width={1000}
        >
          <div className="app-DashboardEditor__modal-content">
            <ErrorMessages errorMessages={this.props.errorMessages} />
            <SpecsEditor
              id={this.props.dashboard.id}
              onChange={this.handleChange}
              value={this.state.specs}
            />
          </div>
        </Modal>
      </div>
    );
  }
}
