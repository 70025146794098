import includes from 'lodash/includes';

import { PermissionLevel } from 'types';

export function canView(permissionLevel: PermissionLevel) {
  return includes(['view', 'edit', 'admin'], permissionLevel);
}

export function canEdit(permissionLevel: PermissionLevel) {
  return includes(['edit', 'admin'], permissionLevel);
}

export function isAdmin(permissionLevel: PermissionLevel) {
  return permissionLevel === 'admin';
}
