import mapValues from 'lodash/mapValues';
import { connect } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';

import * as api from 'state/api';
import * as auth from 'state/auth';
import * as dataExportsForFront from 'state/data/dataExportsForFront';
import * as views from 'state/data/views';
import modals from 'state/modals';
import { Team } from 'types';
import { getExpandedViewKind } from './utils';

const sidebarTreeDataSelector = createSelector(views.selectViewCategories, (viewCategories) =>
  viewCategories.map((category) => ({
    id: category.id,
    name: category.name,
    type: 'category',
    expanded: true,
    children: category.viewGroups.map((group) => ({
      id: group.id,
      name: group.name,
      type: 'group',
    })),
  }))
);

const viewGroupsSelector = createSelector(
  views.selectViewGroups,
  auth.selectCurrentOrganizationTeams,
  (viewGroups, teams) =>
    mapValues(viewGroups, (group: any) => {
      const allowedTeamIds = group.isAccessRestricted
        ? group.allowedTeams.map((team: Team) => team.id)
        : teams.map((team) => team.id);

      const views = group.views.map((view: any) => ({
        ...view,
        kind: getExpandedViewKind(view),
      }));

      return {
        ...group,
        allowedTeamIds,
        views,
      };
    })
);

const selector = createStructuredSelector({
  errorMessages: api.selectSaveViewsErrors,
  isSaving: api.selectIsSaveViewsPending,
  isVisible: modals.selectIsWorkspaceEditorVisible,
  sidebarTreeData: sidebarTreeDataSelector,
  teams: auth.selectCurrentOrganizationTeams,
  viewGroups: viewGroupsSelector,
});

const actions = {
  fetchDataExports: dataExportsForFront.fetch,
  hideEditor: modals.hideWorkspaceEditor,
  saveViews: views.save,
  showEditor: modals.showWorkspaceEditor,
};

export default connect(selector, actions);
