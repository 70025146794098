import { Button, Col, Row, Typography } from 'antd';
import React from 'react';

const { Paragraph } = Typography;

interface Props {
  readonly groupKey: string;
  readonly onCancel: () => void;
  readonly uploadToken: string;
  readonly uploadUrl: string;
}

export default class ApiUpload extends React.Component<Props> {
  getApiFields = () => {
    const { groupKey } = this.props;
    const fields = {
      file: {
        name: 'file',
        type: 'file',
        value: '<SQLite database file>',
        description: 'The file you want to upload as a data source',
      },
      fileType: {
        name: 'file_type',
        type: 'string',
        value: 'sqlite',
        description:
          'Type of the file you want to upload as a data source. ' +
          'Currently we only support SQLite database files.',
      },
      groupKey: {
        name: 'group_key',
        type: 'string',
        value: groupKey,
        description: 'Group key needed to update the correct source table group.',
      },
      token: {
        name: 'token',
        type: 'string',
        value: this.props.uploadToken,
        description: 'Token needed to authorize the upload. ' + 'Unique to each organization.',
      },
    };
    if (!groupKey) {
      return [fields.file, fields.fileType, fields.token];
    }
    return [fields.file, fields.fileType, fields.groupKey, fields.token];
  };

  render() {
    const apiFields = this.getApiFields();
    return (
      <div className="app-ApiUpload">
        <div className="app-ApiUpload__section">
          <h3>Instructions</h3>
          <div className="app-ApiUpload__help-text">
            To Upload a data source file through our API make a POST request with proper form data
            to the following URL. Please note that you also need to specify the file type and give
            your organization's upload token.
          </div>
        </div>

        <div className="app-ApiUpload__section">
          <h3>URL</h3>
          <Paragraph code copyable>
            {this.props.uploadUrl}
          </Paragraph>
        </div>

        <div className="app-ApiUpload__section">
          <h3>Form Data</h3>
          <Row className="app-ApiUpload__api-description-header-row" gutter={20}>
            <Col span={3}>
              <div className="app-ApiUpload__header-name">Field</div>
            </Col>
            <Col span={8}>
              <div className="app-ApiUpload__header-value">Value</div>
            </Col>
            <Col span={13}>
              <div className="app-ApiUpload__header-description">Description</div>
            </Col>
          </Row>
          {apiFields.map((field) => (
            <Row className="app-ApiUpload__api-description-row" gutter={20} key={field.name}>
              <Col span={3}>
                <div className="app-ApiUpload__field-name">{field.name}</div>
                <div className="app-ApiUpload__field-type">{field.type}</div>
              </Col>
              <Col span={8}>
                <div className="app-ApiUpload__field-value">{field.value}</div>
              </Col>
              <Col span={13}>
                <div className="app-ApiUpload__field-description">{field.description}</div>
              </Col>
            </Row>
          ))}
        </div>

        <div className="app-ApiUpload__actions">
          <Button onClick={this.props.onCancel}>Close</Button>
        </div>
      </div>
    );
  }
}
