import filter from 'lodash/filter';
import React from 'react';
import { Link } from 'react-router-dom';
import TimeAgo from 'react-timeago';

import { DataExportConnection } from 'types';

export interface Props {
  accessType: DataExportConnection['accessType'];
  connections: DataExportConnection[];
}

function humanizeClientType(clientType: string) {
  switch (clientType) {
    case 'google-sheet-formula':
    case 'quick-sheet':
      return 'Google Sheet';
    case 'powerbi':
      return 'Power BI';
    case 'tableau':
      return 'Tableau';
    default:
      return clientType;
  }
}

export default class DataExportConnections extends React.Component<Props> {
  renderFrontLink = (connection: DataExportConnection) => {
    const viewType = connection.clientType.split('-')[2];
    const pathParts = connection.url.split('/reports/');
    const path = pathParts.length > 1 ? `reports/${pathParts[1]}` : '';
    if (!path) {
      return (
        <div>
          {connection.name} ({viewType})
        </div>
      );
    }
    return (
      <div>
        <Link to={path}>{connection.name}</Link> ({viewType})
      </div>
    );
  };

  renderLastFetchedAt = (lastFetchedAt: string) => {
    return (
      <div className="app-DataExportConnections__last-fetched-at">
        Last fetched <TimeAgo date={`${lastFetchedAt}Z`} />
      </div>
    );
  };

  renderFrontConnection = (connection: DataExportConnection) => {
    return (
      <li className="app-DataExportConnections__connection" key={connection.url}>
        {this.renderFrontLink(connection)}
        {this.renderLastFetchedAt(connection.lastFetchedAt)}
      </li>
    );
  };

  renderConnectionsInfo = (connection: DataExportConnection) => {
    return (
      <li className="app-DataExportConnections__connection" key={connection.lastFetchedAt}>
        <div>{humanizeClientType(connection.clientType)}</div>
        {this.renderLastFetchedAt(connection.lastFetchedAt)}
      </li>
    );
  };

  renderConnection = (connection: DataExportConnection) => {
    if (connection.accessType === 'quickbi') {
      return this.renderFrontConnection(connection);
    }
    return this.renderConnectionsInfo(connection);
  };

  render() {
    const { connections, accessType } = this.props;
    const connectionsToRender = filter(connections, (conn) => conn.accessType === accessType);

    if (!connectionsToRender.length) {
      const noConnectionsMessage =
        accessType === 'quickbi' ? 'No connections' : 'No connections in the last month';
      return (
        <ul className="app-DataExportConnections app-DataExportConnections--empty">
          <li>{noConnectionsMessage}</li>
        </ul>
      );
    }

    return (
      <ul className="app-DataExportConnections">
        {Boolean(connectionsToRender.length) && connectionsToRender.map(this.renderConnection)}
      </ul>
    );
  }
}
