import filter from 'lodash/filter';
import get from 'lodash/get';
import mapValues from 'lodash/mapValues';
import sortBy from 'lodash/sortBy';
import { createAction, handleActions } from 'redux-actions';
import build from 'redux-object';
import { createSelector } from 'reselect';

import { ViewCategory } from 'types';

type State = any;

// -------
// Actions
// -------

export const fetch = createAction('app/data/views/fetch');

export const save = createAction('app/data/views/save');

// ---------
// Selectors
// ---------

function select(state: any): State {
  return state.data.views;
}

export const selectViewCategories = createSelector(select, (state: State): ViewCategory[] => {
  const ids = get(state, ['meta', '/view-categories', 'data'], []).map(
    (item: ViewCategory) => item.id
  );
  const rv = ids.map((id: string) => build(state, 'viewCategories', id));
  return sortBy(rv, ['position']);
});

export const selectViewGroups = createSelector(select, (state: State): {} =>
  mapValues(state.viewGroups, (group) => build(state, 'viewGroups', group.id))
);

export const selectAccessibleViewCategories = createSelector(
  selectViewCategories,
  (viewCategories) => {
    const accessible = viewCategories.map((category) => ({
      ...category,
      viewGroups: category.viewGroups.filter((group) => !group.isHidden),
    }));
    return filter(accessible, (category) => category.viewGroups.length);
  }
);

// -----
// State
// -----

const initialState: State = {};

const handlers = {
  [String(fetch)]: (): State => initialState,
  [`${fetch}.success`]: (state: State, action: any): State => action.payload,
};

export default handleActions(handlers, initialState);
