import { Button } from 'antd';
import React from 'react';
import AceEditor from 'react-ace';

import 'brace/ext/language_tools';
import 'brace/mode/sql';
import 'brace/theme/sqlserver';

import { formatSql } from 'services/sql';
import { AutoCompleteItem, TabSqlActionData } from 'types';
import SqliteMode from './SqliteMode';
import { getCompleter } from './utils';

import './sqliteSnippets';

const ACTION_BAR_HEIGHT = 40;

export interface OwnProps {
  readonly height: number;
  readonly tabId: string;
  readonly width: number;
}

export interface InjectedProps {
  readonly autoCompleteWordList: AutoCompleteItem[];
  readonly canEditDataExports: boolean;
  readonly canQuery: boolean;
  readonly changeSql: (data: TabSqlActionData) => void;
  readonly isDataExport: boolean;
  readonly isValidating: boolean;
  readonly needsValidation: boolean;
  readonly showDataExportModal: () => void;
  readonly sql: string;
  readonly validateSql: (data: TabSqlActionData) => void;
}

type Props = OwnProps & InjectedProps;

export default class SqlEditor extends React.Component<Props> {
  editorComponent: any;
  originalCompleters: [] = [];

  componentDidMount() {
    const editor = this.editorComponent.editor;
    const sqliteMode = new SqliteMode();
    editor.getSession().setMode(sqliteMode);
    this.originalCompleters = editor.completers;
    if (this.props.autoCompleteWordList.length) {
      this.updateCompleters();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.autoCompleteWordList !== this.props.autoCompleteWordList) {
      this.updateCompleters();
    }
  }

  getKeyBindings = () => {
    return [
      // Custom key-bindings
      {
        name: 'execute SQL',
        bindKey: { win: 'Ctrl-Shift-e', mac: 'Command-Shift-e' },
        exec: this.validateSql,
      },

      // Ace key-bindings with new keys
      {
        name: 'addCursorAbove',
        bindKey: { win: 'Ctrl-Shift-Up', mac: 'Ctrl-Shift-Up' },
        exec: 'addCursorAbove',
      },
      {
        name: 'addCursorBelow',
        bindKey: { win: 'Ctrl-Shift-Down', mac: 'Ctrl-Shift-Down' },
        exec: 'addCursorBelow',
      },
      {
        name: 'selectMoreBefore',
        bindKey: { win: 'Ctrl-Shift-Left', mac: 'Ctrl-Shift-Left' },
        exec: 'selectMoreBefore',
      },
      {
        name: 'selectMoreAfter',
        bindKey: { win: 'Ctrl-Shift-Right', mac: 'Ctrl-Shift-Right' },
        exec: 'selectMoreAfter',
      },
      {
        name: 'splitIntoLines',
        bindKey: { win: 'Ctrl-Shift-L', mac: 'Ctrl-Shift-L' },
        exec: 'splitIntoLines',
      },
      {
        name: 'alignCursors',
        bindKey: { win: 'Ctrl-Shift-A', mac: 'Ctrl-Shift-A' },
        exec: 'alignCursors',
      },
      {
        name: 'findAll',
        bindKey: { win: 'Ctrl-Shift-K', mac: 'Ctrl-Shift-G' },
        exec: 'findAll',
      },
    ];
  };

  handleEditorChange = (sql: string) => {
    this.props.changeSql({
      sql,
      tabId: this.props.tabId,
    });
  };

  handleOpenDataExportModal = () => {
    if (this.props.needsValidation) {
      this.validateSql();
    }
    this.props.showDataExportModal();
  };

  formatSql = () => {
    const sql = formatSql(this.props.sql);
    this.handleEditorChange(sql);
  };

  updateCompleters = () => {
    const editor = this.editorComponent.editor;
    const tableNameCompleter = getCompleter(this.props.autoCompleteWordList);
    editor.completers = [...this.originalCompleters, tableNameCompleter];
  };

  validateSql = () => {
    const { sql, tabId, validateSql } = this.props;
    validateSql({ sql, tabId });
  };

  render() {
    const { height, isDataExport, isValidating, sql, tabId, width } = this.props;
    return (
      <div className="app-SqlEditor">
        <AceEditor
          commands={this.getKeyBindings() as any}
          editorProps={{ $blockScrolling: true }}
          enableBasicAutocompletion
          enableLiveAutocompletion
          focus
          height={`${height - ACTION_BAR_HEIGHT}px`}
          highlightActiveLine={false}
          mode="sql"
          name={`sql-editor-for-tab-${tabId}`}
          onChange={this.handleEditorChange}
          ref={(ace) => {
            this.editorComponent = ace;
          }}
          setOptions={{ enableSnippets: true }}
          showPrintMargin={false}
          theme="sqlserver"
          value={sql}
          width={`${width}px`}
        />
        <div className="app-SqlEditor__action-bar" style={{ height: ACTION_BAR_HEIGHT }}>
          {this.props.canQuery && (
            <Button
              className="app-SqlEditor__run-button"
              disabled={isValidating}
              onClick={this.validateSql}
              size="small"
              type="primary"
            >
              {isValidating ? 'Running...' : 'Run SQL'}
            </Button>
          )}
          {this.props.canEditDataExports && (
            <Button
              className="app-SqlEditor__save-button"
              onClick={this.handleOpenDataExportModal}
              size="small"
            >
              {isDataExport ? 'Edit data export' : 'Save as data export'}
            </Button>
          )}
          {this.props.canQuery && (
            <Button
              className="app-SqlEditor__format-button"
              disabled={isValidating}
              onClick={this.formatSql}
              size="small"
            >
              Format SQL
            </Button>
          )}
        </div>
      </div>
    );
  }
}
