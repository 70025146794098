import { PlusOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, Tooltip } from 'antd';
import React from 'react';

import AddGoogleSheet from './AddGoogleSheet';
import DataSourceUpload from './DataSourceUpload';

export interface Props {
  readonly showDataSourceUploadModal: () => void;
  readonly showAddGoogleSheetModal: () => void;
  readonly usesPostgres: boolean;
}

export default class AddDataSource extends React.Component<Props> {
  handleMenuClick = (event: any) => {
    if (event.key === 'googleSheet') {
      this.props.showAddGoogleSheetModal();
    } else if (event.key === 'fileUpload') {
      this.props.showDataSourceUploadModal();
    } else {
      throw new Error('Invalid AddDataSource menu key');
    }
  };

  renderDropdownMenu = () => {
    return (
      <Menu className="app-AddDataSource__dropdown" onClick={this.handleMenuClick}>
        <Menu.Item key="googleSheet">Google Sheet</Menu.Item>
        <Menu.Item key="fileUpload">File Upload</Menu.Item>
      </Menu>
    );
  };

  render() {
    if (this.props.usesPostgres) {
      return (
        <div className="app-AddDataSource">
          <Tooltip title="Contact support@quickbi.io to add new data sources">
            <Button
              className="app-AddDataSource__button"
              disabled
              icon={<PlusOutlined />}
              shape="circle"
              size="small"
            />
          </Tooltip>
        </div>
      );
    }

    return (
      <div className="app-AddDataSource">
        <Dropdown overlay={this.renderDropdownMenu()} placement="topCenter" trigger={['click']}>
          <Button
            className="app-AddDataSource__button"
            icon={<PlusOutlined />}
            shape="circle"
            size="small"
          />
        </Dropdown>
        <AddGoogleSheet />
        <DataSourceUpload />
      </div>
    );
  }
}
