import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as auth from 'state/auth';
import modals from 'state/modals';

const selector = createStructuredSelector({
  usesPostgres: auth.selectUsesPostgres,
});

const actions = {
  showDataSourceUploadModal: modals.showDataSourceUpload,
  showAddGoogleSheetModal: modals.showAddGoogleSheet,
};

export default connect(selector, actions);
