import throttle from 'lodash/throttle';
import React from 'react';
import PanelGroup from 'react-panelgroup';

import { TAB_BAR_HEIGHT, TOPBAR_HEIGHT } from 'shared/constants';
import { SizeData } from 'types';
import DataDisplay from './DataDisplay';
import SqlEditor from './SqlEditor';

const INITIAL_SQL_EDITOR_HEIGHT = 250;

function getInitialDataDisplayHeight() {
  return window.innerHeight - INITIAL_SQL_EDITOR_HEIGHT - TOPBAR_HEIGHT - TAB_BAR_HEIGHT;
}

export interface Props {
  readonly tabId: string;
  readonly width: number;
}

interface State {
  dataDisplayHeight: number;
  sqlEditorHeight: number;
}

export default class EditorTab extends React.Component<Props, State> {
  state: State = {
    sqlEditorHeight: INITIAL_SQL_EDITOR_HEIGHT,
    dataDisplayHeight: getInitialDataDisplayHeight(),
  };

  handlePanelGroupUpdate = throttle((sizeData: SizeData) => {
    this.setState({
      sqlEditorHeight: sizeData[0].size,
      dataDisplayHeight: sizeData[1].size,
    });
  }, 50);

  render() {
    return (
      <PanelGroup
        borderColor="transparent"
        direction="column"
        onUpdate={this.handlePanelGroupUpdate}
        panelWidths={[
          { minSize: 50, size: this.state.sqlEditorHeight },
          { minSize: 50, size: this.state.dataDisplayHeight },
        ]}
      >
        <SqlEditor
          height={this.state.sqlEditorHeight}
          tabId={this.props.tabId}
          width={this.props.width}
        />
        <DataDisplay
          height={this.state.dataDisplayHeight}
          tabId={this.props.tabId}
          width={this.props.width}
        />
      </PanelGroup>
    );
  }
}
