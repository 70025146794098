import every from 'lodash/every';

import { formatEuros, formatNumber } from 'shared/utils';
import * as types from 'types';

export function getColumnFormat(column: types.TableViewColumn) {
  if (column.format === 'EUR') {
    // See https://js.devexpress.com/Documentation/16_1/Guide/
    // Data_Visualization/Common/Data_Formatting/#Currency_Formats
    // for a good general way to handle currency formats. The current
    // solution was just the fastest for now.
    return { formatter: formatEuros };
  }
  if (column.format === 'number') {
    return { formatter: formatNumber };
  }
  if (column.format === 'date') {
    return 'd.M.yyyy';
  }
  if (column.format === 'datetime') {
    return 'd.M.yyyy HH:mm:ss';
  }
  return null;
}

// If all given columns have same value for fixedPosition
// return them, else empty object.
export function getCommonFixedAttrs(columns: types.DxColumn[]) {
  const first = {
    fixed: columns[0].fixed,
    fixedPosition: columns[0].fixedPosition,
  };
  const hasCommonFixedAttrs = first.fixed && every(columns, first);
  return hasCommonFixedAttrs ? first : {};
}
