import { Spin } from 'antd';
import React from 'react';

import { postSql } from 'services/api/sql';
import PivotTable from 'shared/PivotTable';
import { Credentials, DxPivotField } from 'types';

export interface OwnProps {
  readonly height: number;
  readonly tabId: string;
  readonly width: number;
}

export interface InjectedProps {
  readonly credentials: Credentials;
  readonly dataExportId: string | null;
  readonly fields: DxPivotField[];
  readonly isValidating: boolean;
  readonly sql: string;
}

type Props = OwnProps & InjectedProps;

export default class Pivot extends React.Component<Props> {
  getLoadFunction = () => {
    const { credentials, dataExportId, sql } = this.props;
    const options = {
      sql,
      data_view_id: dataExportId || undefined,
    };
    return () => postSql(credentials, options);
  };

  render() {
    if (this.props.isValidating) {
      return (
        <div className="app-Pivot">
          <div className="app-Pivot-loading">
            <Spin />
          </div>
        </div>
      );
    }

    return (
      <div className="app-Pivot">
        <PivotTable
          fields={this.props.fields}
          height={this.props.height}
          loadFunction={this.getLoadFunction()}
          maxDecimals={5}
          width={this.props.width}
        />
      </div>
    );
  }
}
