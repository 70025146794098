import {
  BarsOutlined,
  LockOutlined,
  LogoutOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { Layout, Menu, Tooltip } from 'antd';
import classNames from 'classnames';
import { History } from 'history';
import isEqual from 'lodash/isEqual';
import React from 'react';

import { FRONT_PAGE_PATH, FRONT_PAGE_SIDEBAR_WIDTH } from 'shared/constants';
import { openHelpModal } from 'shared/modal';
import { isMobile } from 'shared/utils';
import { ViewCategory, ViewGroup, UserOrganization } from 'types';
import WorkspaceEditor from '../WorkspaceEditor';

const { SubMenu, Item } = Menu;

function getKey(type: string, slug: string) {
  return `${type}-${slug}`;
}

// Return the selected view's category in a list. Others should be closed by default.
function getDefaultOpenKeys(viewCategories: ViewCategory[], selectedKeys: string[]) {
  if (isMobile()) {
    // Using empty array on mobile makes sure that submenu popup is not shown
    // when page is loaded on mobile.
    return [];
  }
  // Use the first category if nothing is selected. This happens on initial load.
  if (selectedKeys.length === 0 && viewCategories.length > 0) {
    return [viewCategories[0].id];
  }

  const selectedId = selectedKeys[0];
  for (const viewCategory of viewCategories) {
    for (const viewGroup of viewCategory.viewGroups) {
      if (selectedId === getKey('view', viewGroup.slug)) {
        return [viewCategory.id];
      }
    }
  }
  return [];
}

export interface Props {
  readonly collapse: () => void;
  readonly collapsed: boolean;
  readonly currentOrganization: UserOrganization | null;
  readonly fetchViews: () => void;
  readonly history: History;
  readonly location: any;
  readonly onLogOut: () => void;
  readonly showWorkspaceEditor: boolean;
  readonly toggleCollapse: () => void;
  readonly username: string | null;
  readonly viewCategories: ViewCategory[];
}

interface State {
  collapsedOpenKeys: string[];
  openKeys: string[];
}

export default class Sidebar extends React.Component<Props, State> {
  state: State = {
    collapsedOpenKeys: [],
    openKeys: [],
  };

  UNSAFE_componentWillMount() {
    if (this.props.currentOrganization) {
      this.props.fetchViews();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.currentOrganization !== this.props.currentOrganization) {
      this.props.fetchViews();
    }
    if (!isEqual(prevProps.viewCategories, this.props.viewCategories)) {
      this.initOpenKeys();
    }
  }

  componentDidMount() {
    if (isMobile()) {
      this.props.collapse();
    }
  }

  initOpenKeys() {
    const openKeys = getDefaultOpenKeys(this.props.viewCategories, this.getSelectedKeys());
    this.setState({ openKeys });
  }

  // Return list of selected keys for Menu component. Example: if current path
  // is '/dashboards/sales' return ['dashboard-sales'].
  getSelectedKeys() {
    const pathname = this.props.location.pathname;
    const viewPathRegex = new RegExp(
      '^/w/' + // /w/
        '[^/]+' + // <organization slug>
        // eslint-disable-next-line no-useless-escape
        `\/${FRONT_PAGE_PATH}\/` + // /<path-to-front-page>/
        '([^/]+)' + // <view group slug>
        '/' // /
    );
    const match = viewPathRegex.exec(pathname);
    if (match) {
      const slug = match[1];
      return [getKey('view', slug)];
    }
    return [];
  }

  handleOpenChange = (openKeys: any) => {
    if (this.props.collapsed) {
      this.setState({ collapsedOpenKeys: openKeys });
    } else {
      this.setState({ openKeys });
    }
  };

  handleSelect = ({ key }: any) => {
    if (key === 'help') {
      openHelpModal();
      return;
    }
    if (key === 'logout') {
      this.props.onLogOut();
      return;
    }
    const match = /(\w+)-(.+)/.exec(key);
    if (!match) {
      throw new Error('Invalid key for menu item.');
    }
    const org = this.props.currentOrganization as UserOrganization;
    const viewGroupSlug: string = match[2];
    this.props.history.push(`/w/${org.slug}/${FRONT_PAGE_PATH}/${viewGroupSlug}/0`);
    if (isMobile()) {
      this.props.collapse();
    }
  };

  renderLockIcon = (viewGroup: ViewGroup) => {
    const allowedTeams = viewGroup.allowedTeams.map((team) => team.name);
    return (
      <Tooltip placement="right" title={`Restricted to ${allowedTeams.join(', ')}`}>
        <LockOutlined className="app-Sidebar__vg-lock-icon" />
      </Tooltip>
    );
  };

  renderViews() {
    if (!this.props.viewCategories.length) {
      return null;
    }
    return this.props.viewCategories.map((viewCategory) => (
      <SubMenu
        key={viewCategory.id}
        title={
          <span>
            <BarsOutlined />
            <span>{viewCategory.name}</span>
          </span>
        }
      >
        {viewCategory.viewGroups.map((viewGroup) => (
          <Item key={getKey('view', viewGroup.slug)}>
            {viewGroup.name}
            {viewGroup.isAccessRestricted && this.renderLockIcon(viewGroup)}
          </Item>
        ))}
      </SubMenu>
    ));
  }

  renderMenu() {
    if (!this.props.viewCategories.length) {
      return null;
    }
    const openKeys = this.props.collapsed ? this.state.collapsedOpenKeys : this.state.openKeys;
    const selectedKeys = this.getSelectedKeys();
    return (
      <Menu
        className="app-Sidebar-menu"
        openKeys={openKeys}
        mode="inline"
        onOpenChange={this.handleOpenChange}
        onSelect={this.handleSelect}
        selectedKeys={selectedKeys}
      >
        {this.renderViews()}
      </Menu>
    );
  }

  renderSider() {
    return (
      <Layout.Sider
        className="app-Sidebar-not-mobile"
        collapsed={this.props.collapsed}
        collapsible
        onCollapse={this.props.toggleCollapse}
        width={FRONT_PAGE_SIDEBAR_WIDTH}
      >
        {this.renderMenu()}
        {this.props.currentOrganization &&
          this.props.showWorkspaceEditor &&
          !this.props.collapsed && <WorkspaceEditor />}
      </Layout.Sider>
    );
  }

  renderMobileMenu() {
    if (!this.props.viewCategories.length) {
      return null;
    }
    const selectedKeys = this.getSelectedKeys();
    return (
      <Menu
        defaultOpenKeys={getDefaultOpenKeys(this.props.viewCategories, selectedKeys)}
        mode="inline"
        onSelect={this.handleSelect}
        selectedKeys={selectedKeys}
      >
        {this.renderViews()}
        <Item key="help">
          <QuestionCircleOutlined />
          Help
        </Item>
        <Item className="app-Sidebar-logout" key="logout">
          <LogoutOutlined />
          Log out {this.props.username}
        </Item>
      </Menu>
    );
  }

  renderMobileSider() {
    const className = classNames('app-Sidebar-mobile', {
      'app-Sidebar-mobile-collapsed': this.props.collapsed,
    });
    return (
      <Layout.Sider className={className} trigger={null} width={FRONT_PAGE_SIDEBAR_WIDTH}>
        {this.renderMobileMenu()}
      </Layout.Sider>
    );
  }

  render() {
    return (
      <div className="app-Sidebar">
        {this.renderSider()}
        {this.renderMobileSider()}
      </div>
    );
  }
}
