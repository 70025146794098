import { GoogleOAuthProvider } from '@react-oauth/google';
import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import { hot } from 'react-hot-loader';
import { Provider } from 'react-redux';
import { Route } from 'react-router-dom';

import App from 'screens/App';
import store, { history } from 'store';

const AppContainer = () => (
  <Provider store={store}>
    <GoogleOAuthProvider clientId={process.env.GOOGLE_CLIENT_ID as string}>
      <ConnectedRouter history={history}>
        <Route component={App} />
      </ConnectedRouter>
    </GoogleOAuthProvider>
  </Provider>
);

export default hot(module)(AppContainer);
