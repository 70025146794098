import throttle from 'lodash/throttle';
import React from 'react';
import DocumentTitle from 'react-document-title';
import PanelGroup from 'react-panelgroup';

import { FOX_PAGE_NAME } from 'shared/constants';
import NoPermissionPage from 'shared/NoPermissionPage';
import { SizeData, UserOrganization } from 'types';
import AddDataSource from './AddDataSource';
import DataExportDetails from './DataExportDetails';
import DataExportGroupEditor from './DataExportGroupEditor';
import DataExportModal from './DataExportModal';
import DataExports from './DataExports';
import EditorTabs from './EditorTabs';
import SourceTables from './SourceTables';

interface OwnProps {}

interface InjectedProps {
  readonly canEditDataExports: boolean;
  readonly canAddDataSources: boolean;
  readonly canView: boolean;
  readonly currentOrganization: UserOrganization | null;
  readonly fetchDataSource: () => void;
}

type Props = OwnProps & InjectedProps;

interface State {
  leftSidebarWidth: number;
  contentWidth: number;
  rightSidebarWidth: number;
}

const initialLeftSidebarWidth = 200;
const initialRightSidebarWidth = 200;
const initialContentWidth: number =
  window.innerWidth - initialLeftSidebarWidth - initialRightSidebarWidth;

export default class FoxPage extends React.Component<Props, State> {
  state: State = {
    leftSidebarWidth: initialLeftSidebarWidth,
    contentWidth: initialContentWidth,
    rightSidebarWidth: initialRightSidebarWidth,
  };

  componentDidMount() {
    if (this.props.canView) {
      this.props.fetchDataSource();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.currentOrganization !== this.props.currentOrganization ||
      prevProps.canView !== this.props.canView
    ) {
      if (this.props.canView) {
        this.props.fetchDataSource();
      }
    }
  }

  handlePanelGroupUpdate = throttle((sizeData: SizeData) => {
    this.setState({
      leftSidebarWidth: sizeData[0].size,
      contentWidth: sizeData[1].size,
      rightSidebarWidth: sizeData[2].size,
    });
  }, 50);

  render() {
    if (!this.props.canView) return <NoPermissionPage />;

    const panelWidths = [
      { minSize: 50, resize: 'dynamic', size: this.state.leftSidebarWidth },
      { minSize: 50, resize: 'stretch', size: this.state.contentWidth },
      { minSize: 50, resize: 'dynamic', size: this.state.rightSidebarWidth },
    ];
    return (
      <div className="app-FoxPage">
        <DocumentTitle title={`${FOX_PAGE_NAME} - QuickBI`} />
        <PanelGroup
          borderColor="transparent"
          direction="row"
          onUpdate={this.handlePanelGroupUpdate}
          panelWidths={panelWidths}
        >
          <div className="app-FoxPage__left-sidebar">
            <SourceTables />
            {this.props.canAddDataSources && <AddDataSource />}
          </div>
          <EditorTabs width={this.state.contentWidth} />
          <div className="app-FoxPage__right-sidebar">
            <DataExportDetails />
            <h2 className="app-FoxPage__sidebar-header">
              <div>Data exports</div>
              {this.props.canEditDataExports && <DataExportGroupEditor />}
            </h2>
            <DataExports />
          </div>
        </PanelGroup>
        {this.props.canEditDataExports && <DataExportModal />}
      </div>
    );
  }
}
