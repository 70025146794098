import 'devextreme/viz/chart';
import renderer from 'devextreme/core/renderer';

export default function getChartRenderer(domNode: any) {
  return renderer(domNode)
    .dxChart({
      commonSeriesSettings: {
        type: 'bar',
      },
      tooltip: {
        enabled: true,
      },
      margin: {
        top: 10,
        right: 10,
        bottom: 10,
        left: 10,
      },
    })
    .dxChart('instance');
}
