import { Collapse, Spin } from 'antd';
import React from 'react';

import * as types from 'types';
import DataExport from './DataExport';

const Panel = Collapse.Panel;

export interface OwnProps {}

export interface InjectedProps {
  readonly canEdit: boolean;
  readonly canView: boolean;
  readonly currentOrganization: types.UserOrganization | null;
  readonly dataExportGrouping: types.DataExportGroupingItem[];
  readonly deleteDataExport: () => void;
  readonly fetchDataExports: (options: {}) => void;
  readonly isFetching: boolean;
  readonly selectedDataExportId: string;
  readonly showSqlInEditor: (sql: string) => void;
  readonly toggleDataExportDetails: (dataExportId: string) => void;
}

type Props = OwnProps & InjectedProps;

export default class DataExports extends React.Component<Props> {
  componentDidMount() {
    if (this.props.canView) {
      this.props.fetchDataExports({ isFox: true });
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.currentOrganization !== this.props.currentOrganization ||
      prevProps.canView !== this.props.canView
    ) {
      if (this.props.canView) {
        this.props.fetchDataExports({ isFox: true });
      }
    }
  }

  renderDataExport = (dataExport: types.DataExport) => {
    return (
      <DataExport
        canEdit={this.props.canEdit}
        dataExport={dataExport}
        deleteDataExport={this.props.deleteDataExport}
        key={dataExport.id}
        selected={dataExport.id === this.props.selectedDataExportId}
        showSqlInEditor={this.props.showSqlInEditor}
        toggleDataExportDetails={this.props.toggleDataExportDetails}
      />
    );
  };

  renderDataExportGroup = (dataExportGroup: types.DataExportGroup) => {
    return (
      <Collapse bordered={false} className="app-DataExports__group" key={dataExportGroup.id}>
        <Panel header={<div>{dataExportGroup.name}</div>} key={dataExportGroup.id}>
          {dataExportGroup.dataExports.map(this.renderDataExport)}
        </Panel>
      </Collapse>
    );
  };

  renderDataExportGroupingItem = (item: types.DataExportGroupingItem) => {
    if (item.type === 'dataExportGroup') {
      return this.renderDataExportGroup(item as types.DataExportGroup);
    }
    if (item.type === 'dataExport') {
      return this.renderDataExport(item as types.DataExport);
    }
  };

  render() {
    return (
      <div className="app-DataExports">
        {this.props.isFetching && (
          <div className="app-DataExports__loading-wrapper">
            <div className="app-DataExports__loading">
              <Spin />
            </div>
          </div>
        )}
        {this.props.dataExportGrouping.map(this.renderDataExportGroupingItem)}
      </div>
    );
  }
}
