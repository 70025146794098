import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: process.env.SENTRY_DSN,
});

export const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: (action: any) => action.type,
  attachReduxState: false,
});
