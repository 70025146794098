import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as auth from 'state/auth';
import * as views from 'state/data/views';
import * as sidebar from 'state/front/sidebar';

const selector = createStructuredSelector({
  collapsed: sidebar.selectIsCollapsed,
  currentOrganization: auth.selectCurrentOrganization,
  showWorkspaceEditor: auth.selectIsAdminFront,
  username: auth.selectUsername,
  viewCategories: views.selectAccessibleViewCategories,
});

const actions = {
  collapse: sidebar.collapse,
  fetchViews: views.fetch,
  onLogOut: auth.logOut,
  toggleCollapse: sidebar.toggle,
};

export default connect(selector, actions);
