import { SqlQueryOptions } from 'services/api/sql';
import { DataQueryOptions } from 'services/api/tableViews';

interface DxSortItem {
  desc: boolean;
  selector: string;
}

function getSorting(dxSortItems: DxSortItem[]) {
  const sortList = dxSortItems.map((item: DxSortItem) =>
    item.desc ? `-${item.selector}` : item.selector
  );
  return sortList.join(',');
}

export function parseLoadOptions(loadOptions: any, type = 'sql') {
  let options: SqlQueryOptions | DataQueryOptions;
  if (type === 'sql') {
    options = {
      limit: loadOptions.take,
      offset: loadOptions.skip,
    };
  } else {
    options = {
      format: 'obj',
      'page[limit]': loadOptions.take,
      'page[offset]': loadOptions.skip,
    };
  }
  if (loadOptions.requireTotalCount) {
    options.total_count = true;
  }
  if (loadOptions.sort && loadOptions.sort.length) {
    options.sort = getSorting(loadOptions.sort);
  }
  if (loadOptions.filter && loadOptions.filter.length) {
    options.filter = JSON.stringify(loadOptions.filter);
  }
  return options;
}
