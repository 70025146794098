import React from 'react';
import { DeleteOutlined, EditOutlined, SettingOutlined } from '@ant-design/icons';
import { Alert, Button, Dropdown, Menu } from 'antd';

import { DataExport } from 'types';

export interface Props {
  readonly canEdit: boolean;
  readonly dataExport: DataExport;
  readonly onDelete: (id: string) => void;
  readonly showSqlInEditor: (values: {}) => void;
}

interface State {
  isDeleteConfirmVisible: boolean;
}

export default class DataExports extends React.Component<Props, State> {
  state: State = {
    isDeleteConfirmVisible: false,
  };

  componentDidUpdate(prevProps: Props) {
    if (prevProps.dataExport.id !== this.props.dataExport.id) {
      this.hideDeleteConfirm();
    }
  }

  handleDelete = () => {
    this.props.onDelete(this.props.dataExport.id);
    this.hideDeleteConfirm();
  };

  handleMenuClick = (event: any) => {
    if (event.key === 'showSQL') {
      const { id, name, sql } = this.props.dataExport;
      this.props.showSqlInEditor({
        name,
        sql,
        dataExportId: id,
      });
    } else if (event.key === 'delete' && this.props.canEdit) {
      this.showDeleteConfirm();
    } else {
      throw new Error('Invalid DataExportSettings menu key');
    }
  };

  hideDeleteConfirm = () => {
    this.setState({ isDeleteConfirmVisible: false });
  };

  showDeleteConfirm = () => {
    this.setState({ isDeleteConfirmVisible: true });
  };

  renderDeleteConfirm = () => {
    const content = (
      <div className="app-DataExportSettings__delete-confirm-content">
        <p>This action can not be undone!</p>
        <Button onClick={this.hideDeleteConfirm}>Cancel</Button>
        <Button danger onClick={this.handleDelete}>
          Delete
        </Button>
      </div>
    );

    return (
      <Alert
        className="app-DataExportSettings__delete-confirm"
        message="Are you sure you want to delete this data export?"
        description={content}
        type="warning"
        showIcon
      />
    );
  };

  render() {
    const menu = (
      <Menu className="app-DataExportSettings__dropdown" onClick={this.handleMenuClick}>
        <Menu.Item key="showSQL">
          <EditOutlined />
          Show SQL
        </Menu.Item>
        {this.props.canEdit && (
          <Menu.Item key="delete">
            <DeleteOutlined />
            Delete
          </Menu.Item>
        )}
      </Menu>
    );
    return (
      <div className="app-DataExportSettings">
        <Dropdown overlay={menu} trigger={['click']}>
          <Button icon={<SettingOutlined />} shape="circle" />
        </Dropdown>
        {this.state.isDeleteConfirmVisible && this.renderDeleteConfirm()}
      </div>
    );
  }
}
