import { Modal } from 'antd';
import get from 'lodash/get';
import React from 'react';

import ErrorMessage from 'shared/ErrorMessage';
import * as types from 'types';
import ApiUpload from './ApiUpload';
import FileUpload from './FileUpload';

interface OwnProps {}

interface InjectedProps {
  readonly errorMessages: types.ErrorMessage[];
  readonly groupKey: string;
  readonly hideModal: () => void;
  readonly isUploading: boolean;
  readonly isVisible: boolean;
  readonly uploadFile: (formData: any) => void;
  readonly uploadInfo: { uploadToken: string; uploadUrl: string };
}

type Props = OwnProps & InjectedProps;

interface State {
  uploadMethod: 'manual' | 'api';
}

export default class DataSourceUpload extends React.Component<Props, State> {
  state: State = {
    uploadMethod: 'manual',
  };

  selectApiUpload = () => {
    this.setState({ uploadMethod: 'api' });
  };

  selectManualUpload = () => {
    this.setState({ uploadMethod: 'manual' });
  };

  renderError = (error: types.ErrorMessage) => {
    if (error.status === '409' && error.title === 'TableNameAlreadyInUse') {
      const tableName = get(error, 'meta.table_name', '');
      return (
        <ErrorMessage
          key={error.title}
          message={`Table "${tableName}" already in use`}
          description={`Uploading data failed because table "${tableName}" already exists in your source tables.
            Please change the name of table "${tableName}" in your file and try again.`}
        />
      );
    }

    return (
      <ErrorMessage key={error.title} message={error.title} description={error.detail || ''} />
    );
  };

  renderErrors = () => {
    return (
      <div className="app-ErrorMessages">{this.props.errorMessages.map(this.renderError)}</div>
    );
  };

  renderUploadMethodSelect = () => {
    const selectMethodLink =
      this.state.uploadMethod === 'manual' ? (
        <a onClick={this.selectApiUpload}>Use API endpoint to upload</a>
      ) : (
        <a onClick={this.selectManualUpload}>Use manual file upload</a>
      );

    return <div className="app-DataSourceUpload__method-select">{selectMethodLink}</div>;
  };

  render() {
    const { groupKey } = this.props;
    return (
      <div className="app-DataSourceUpload">
        <Modal
          className="app-DataSourceUpload__modal"
          confirmLoading={this.props.isUploading}
          destroyOnClose
          footer={null}
          maskClosable={false}
          onCancel={this.props.hideModal}
          title={groupKey ? 'Reupload data source' : 'Upload data source'}
          visible={this.props.isVisible}
          width={800}
        >
          <div className="app-DataSourceUpload__modal-content">
            {this.renderErrors()}
            {this.state.uploadMethod === 'manual' && (
              <FileUpload
                groupKey={groupKey}
                isUploading={this.props.isUploading}
                onCancel={this.props.hideModal}
                uploadFile={this.props.uploadFile}
              />
            )}
            {this.state.uploadMethod === 'api' && (
              <ApiUpload
                groupKey={groupKey}
                onCancel={this.props.hideModal}
                uploadToken={this.props.uploadInfo.uploadToken}
                uploadUrl={this.props.uploadInfo.uploadUrl}
              />
            )}
            {this.renderUploadMethodSelect()}
          </div>
        </Modal>
      </div>
    );
  }
}
