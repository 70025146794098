import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as auth from 'state/auth';
import * as tableViews from 'state/data/tableViews';
import modals from 'state/modals';
import { OwnProps } from './TableView';

function selectTableViewId(state: any, props: OwnProps) {
  return props.id;
}

const selectTableView = tableViews.selectActiveTableView(selectTableViewId);

export const selector = createStructuredSelector({
  canEditColumns: auth.selectIsAdminFront,
  credentials: auth.selectCredentials,
  id: selectTableViewId,
  tableView: selectTableView,
});

const actions = {
  fetchTableView: tableViews.fetch,
  showColumnEditor: modals.showColumnEditor,
};

export default connect(selector, actions);
