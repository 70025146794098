import uniq from 'lodash/uniq';

const coreFunctions = [
  'abs',
  // 'changes',
  'char',
  'coalesce',
  'glob',
  'hex',
  'ifnull',
  'instr',
  // 'last_insert_rowid',
  'length',
  'like',
  // 'likelihood',
  // 'likely',
  // 'load_extension',
  'lower',
  'ltrim',
  'max',
  'min',
  'nullif',
  'printf',
  'quote',
  'random',
  'randomblob',
  'replace',
  'round',
  'rtrim',
  'soundex',
  // 'sqlite_compileoption_get',
  // 'sqlite_compileoption_used',
  // 'sqlite_offset',
  // 'sqlite_source_id',
  'sqlite_version',
  'substr',
  // 'total_changes',
  'trim',
  'typeof',
  'unicode',
  // 'unlikely',
  'upper',
  // 'zeroblob',
];

const dateTimeFunctions = ['date', 'time', 'datetime', 'julianday', 'strftime'];

const aggregateFunctions = [
  'avg',
  'count',
  'count',
  'group_concat',
  'group_concat',
  'max',
  'min',
  'sum',
  'total',
];

const functions = uniq(coreFunctions.concat(dateTimeFunctions, aggregateFunctions));

export default functions.join('|');
