import { Button, Col } from 'antd';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import React from 'react';

import { getDashboardItemData } from 'services/api/dashboardItems';
import { parseFiltersForApi } from 'services/dashboard';
import * as types from 'types';
import DashboardBox from './DashboardBox';
import DashboardChart from './DashboardChart';

export interface OwnProps {
  readonly filterValues: types.DashboardFilterValues;
  readonly id?: string;
  readonly size: types.DashboardLayoutItemSize;
}

export interface InjectedProps {
  readonly canEdit: boolean;
  readonly credentials: types.Credentials;
  readonly dashboardItem: types.DashboardItem;
  readonly showDashboardItemEditor: (data: {}) => void;
}

export type Props = OwnProps & InjectedProps;

export default class DashboardItem extends React.Component<Props> {
  getColProps = () => {
    const { size } = this.props;
    if (isNumber(size)) {
      return { span: size };
    }
    return size;
  };

  getLoadFunction = () => {
    const { credentials, dashboardItem } = this.props;
    if (!dashboardItem.dataView) {
      return null;
    }
    return (params: any) => getDashboardItemData(credentials, dashboardItem.id, params);
  };

  getLoadParams = () => {
    const { dashboardItem, filterValues } = this.props;
    const itemFilters = dashboardItem.specs.filters;

    if (!itemFilters) return {};

    const filtersToUse: any[] = [];
    itemFilters.forEach((itemFilter: types.DashboardItemFilter) => {
      const values = filterValues[itemFilter.filterId];
      if (values && values.length) {
        const { column, operator } = itemFilter;
        filtersToUse.push({ column, operator, values });
      }
    });

    if (!filtersToUse.length) return {};

    return {
      filter: parseFiltersForApi(filtersToUse),
    };
  };

  handleEditClick = () => {
    this.props.showDashboardItemEditor({ id: this.props.id });
  };

  renderBox = () => {
    const { specs } = this.props.dashboardItem;
    return (
      <DashboardBox
        loadFunction={this.getLoadFunction()}
        loadParams={this.getLoadParams()}
        specs={specs}
      />
    );
  };

  renderChart = () => {
    const { id, size } = this.props;
    const { specs } = this.props.dashboardItem;
    return (
      <DashboardChart
        id={id as string}
        loadFunction={this.getLoadFunction()}
        loadParams={this.getLoadParams()}
        size={size}
        specs={specs}
      />
    );
  };

  render() {
    const { specs } = this.props.dashboardItem;
    const hasSpecs = !isEmpty(specs);
    return (
      <Col {...this.getColProps()}>
        <div className={classNames('app-DashboardItem', { 'app-DashboardItem--empty': !hasSpecs })}>
          {this.props.canEdit && (
            <Button className="app-DashboardItem__edit-button" onClick={this.handleEditClick}>
              Edit
            </Button>
          )}
          {hasSpecs && specs.type === 'box' && this.renderBox()}
          {hasSpecs && specs.type !== 'box' && this.renderChart()}
        </div>
      </Col>
    );
  }
}
