import { Button, Col, Row, Modal } from 'antd';
import get from 'lodash/get';
import React from 'react';

import ErrorMessages from 'shared/ErrorMessages';
import { ErrorMessage, Team } from 'types';
import SidebarEditor from './SidebarEditor';
import { buildFullData } from './utils';
import ViewGroupEditor from './ViewGroupEditor';

export interface InjectedProps {
  readonly errorMessages: ErrorMessage[];
  readonly fetchDataExports: () => void;
  readonly hideEditor: any;
  readonly isSaving: boolean;
  readonly isVisible: boolean;
  readonly saveViews: any;
  readonly showEditor: any;
  readonly sidebarTreeData: any;
  readonly teams: Team[];
  readonly viewGroups: any;
}

type Props = InjectedProps;

export default class WorkspaceEditor extends React.Component<Props> {
  state: any = {
    activeViewGroupId: null,
    sidebarTreeData: [],
    viewGroups: {},
  };

  componentDidUpdate(prevProps: Props) {
    if (this.props.isVisible && !prevProps.isVisible) {
      this.props.fetchDataExports();
    }
  }

  addNewViewGroup = (id: string) => {
    const newViewGroup = {
      id,
      allowedTeamIds: [],
      isAccessRestricted: false,
      name: 'New page',
      urlChanger: null,
      urlChangerText: '',
      views: [],
    };
    this.updateViewGroup(id, newViewGroup);
  };

  showModal = () => {
    const { sidebarTreeData, viewGroups } = this.props;
    this.setState({
      sidebarTreeData,
      viewGroups,
      activeViewGroupId: this.getDefaultActiveViewGroupId(sidebarTreeData),
    });
    this.props.showEditor();
  };

  changeActiveViewGroupId = (id: string) => {
    this.setState({ activeViewGroupId: id });
  };

  getDefaultActiveViewGroupId(sidebarTreeData: any) {
    return get(sidebarTreeData, '[0].children[0].id', null);
  }

  getActiveViewGroup = () => {
    const { viewGroups, activeViewGroupId }: any = this.state;
    return activeViewGroupId && viewGroups ? viewGroups[activeViewGroupId] : null;
  };

  removeViewGroup = (id: string) => {
    this.setState({
      viewGroups: {
        ...this.state.viewGroups,
        [id]: undefined,
      },
    });
  };

  updateViewGroup = (id: string, data: any) => {
    const previous = this.state.viewGroups[id] || {};
    const viewGroup = {
      ...previous,
      ...data,
    };
    this.setState({
      viewGroups: {
        ...this.state.viewGroups,
        [id]: viewGroup,
      },
    });
  };

  handleSave = () => {
    const { sidebarTreeData, viewGroups } = this.state;
    const data = buildFullData(sidebarTreeData, viewGroups);
    this.props.saveViews(data);
  };

  handleSidebarChange = (treeData: any) => {
    this.setState({ sidebarTreeData: treeData });
  };

  handleCancel = () => {
    this.props.hideEditor();
  };

  render() {
    return (
      <div className="app-WorkspaceEditor">
        <Button onClick={this.showModal}>Edit workspace</Button>
        <Modal
          className="app-WorkspaceEditor__modal"
          confirmLoading={this.props.isSaving}
          maskClosable={false}
          okText="Save"
          onCancel={this.handleCancel}
          onOk={this.handleSave}
          title="Edit workspace"
          visible={this.props.isVisible}
          width={1090}
        >
          <ErrorMessages errorMessages={this.props.errorMessages} />
          <Row gutter={32}>
            <Col className="app-WorkspaceEditor__sidebar-col" span={13}>
              <SidebarEditor
                activeViewGroupId={this.state.activeViewGroupId}
                addNewViewGroup={this.addNewViewGroup}
                onViewGroupClick={this.changeActiveViewGroupId}
                onChange={this.handleSidebarChange}
                removeViewGroup={this.removeViewGroup}
                sidebarTreeData={this.state.sidebarTreeData}
                updateViewGroup={this.updateViewGroup}
                viewGroups={this.state.viewGroups}
              />
            </Col>
            <Col span={11}>
              <ViewGroupEditor
                updateViewGroup={this.updateViewGroup}
                viewGroup={this.getActiveViewGroup()}
              />
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
}
