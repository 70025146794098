import { DashboardBoxSpecs } from 'types';

// ---------------------
// getBoxComparisonSpecs
// ---------------------

const COMPARISON_DEFAULTS = {
  hidden: false,
  precision: 0,
  type: 'absolute' as const,
};

export function getBoxComparisonSpecs(specs: DashboardBoxSpecs['comparison']) {
  return {
    ...COMPARISON_DEFAULTS,
    suffix: specs && specs.type === 'relative' ? '%' : '',
    ...specs,
  };
}

// ----------------
// getBoxDifference
// ----------------

export function getBoxDifference(
  value: number,
  comparisonValue: number,
  type: 'absolute' | 'relative'
) {
  if (type === 'relative') {
    return ((value - comparisonValue) / comparisonValue) * 100;
  }
  return value - comparisonValue;
}
