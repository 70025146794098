import { AutoCompleteItem } from 'types';

export function getCompleter(wordList: AutoCompleteItem[]) {
  return {
    getCompletions(editor: any, session: any, pos: any, prefix: any, callback: any) {
      callback(
        null,
        wordList.map((item) => ({
          value: item.value,
          meta: item.meta || '',
        }))
      );
    },
  };
}
