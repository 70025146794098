import { Form, FormItem, Input, Select } from 'formik-antd';
import { Button, Modal } from 'antd';
import { Formik } from 'formik';
import React from 'react';
import * as yup from 'yup';

import ErrorMessage from 'shared/ErrorMessage';
import * as types from 'types';

const { TextArea } = Input;
const { Option } = Select;

interface EditDataExportParams {
  data: types.DataExportEditData;
  meta: { tabId: string };
}

interface SaveDataExportParams {
  data: types.DataExportSaveData;
  meta: { tabId: string };
}

export interface OwnProps {}

export interface InjectedProps {
  readonly dataExport: types.DataExport | null;
  readonly dataExportGroupOptions: types.SelectOption[];
  readonly editDataExport: (params: EditDataExportParams) => void;
  readonly errorMessages: types.ErrorMessage[];
  readonly hideModal: () => void;
  readonly isSaving: boolean;
  readonly isVisible: boolean;
  readonly saveDataExport: (params: SaveDataExportParams) => void;
  readonly sql: string;
  readonly tabId: string;
  readonly usesPostgres: boolean;
}

type Props = OwnProps & InjectedProps;

interface Fields {
  dataExportGroup: string;
  description: string;
  name: string;
}

const postgresValidationSchema = yup.object().shape({
  name: yup
    .string()
    .max(50, 'Max length is 50 characters')
    .matches(/^[a-z0-9_]+$/, 'Can only contain lowercase letters a-z, numbers and underscores')
    .matches(/^(?![\d])/, 'Can not start with a number')
    .required('Required'),
  description: yup.string(),
  dataExportGroup: yup.string(),
});

const sqliteValidationSchema = yup.object().shape({
  name: yup.string().max(50, 'Max length is 50 characters').required('Required'),
  description: yup.string(),
  dataExportGroup: yup.string(),
});

export default class DataExportModal extends React.Component<Props> {
  handleSave = (values: Fields) => {
    const { dataExport, editDataExport, saveDataExport, sql, tabId } = this.props;
    const { dataExportGroup, ...remainingValues } = values;
    const attributes = {
      ...remainingValues,
      sql,
    };
    const meta = { tabId, refetchAll: true };
    const relationships = {
      group: {
        data: dataExportGroup ? { type: 'data_view_groups', id: dataExportGroup } : null,
      },
    };
    if (dataExport) {
      const data = { attributes, relationships, id: dataExport.id };
      editDataExport({ data, meta });
    } else {
      const data = { attributes, relationships };
      saveDataExport({ data, meta });
    }
  };

  getInitialValues = () => {
    const { dataExport } = this.props;
    if (dataExport) {
      return {
        dataExportGroup: dataExport.group ? dataExport.group.id : '',
        description: dataExport.description,
        name: dataExport.name,
      };
    }
    return {
      dataExportGroup: '',
      description: '',
      name: '',
    };
  };

  getTitle = () => {
    const { dataExport } = this.props;
    if (dataExport) {
      return `Edit data export "${dataExport.name}"`;
    }
    return 'Save SQL query as data export';
  };

  renderError = (error: types.ErrorMessage) => {
    if (error.status === '400' && error.title === 'NameInUse') {
      return (
        <ErrorMessage
          key={error.title}
          message={`Data export name already in use`}
          description={`Saving data export failed because there already exists a data export
            or a data source table with the same name.
            Please change the data export name and try again.`}
        />
      );
    }

    if (error.status === '400' && error.title === 'ValidationError') {
      return (
        <ErrorMessage
          key={error.title}
          message={`Invalid data export name`}
          description={`Data export name can only contain lowercase letters a-z, numbers and underscores.
            The name must start with a lowercase letter or an underscore and must be under 50 characters long.
            Please change the data export name and try again.`}
        />
      );
    }

    return (
      <ErrorMessage key={error.title} message={error.title} description={error.detail || ''} />
    );
  };

  renderErrors = () => {
    return (
      <div className="app-ErrorMessages">{this.props.errorMessages.map(this.renderError)}</div>
    );
  };

  renderForm = () => {
    return (
      <Form className="app-DataExportModal__form" layout="vertical">
        <FormItem label="Name" name="name">
          <Input name="name" />
        </FormItem>
        <FormItem label="Description" name="description">
          <TextArea name="description" rows={4} />
        </FormItem>
        <FormItem label="Data Export Group" name="dataExportGroup">
          <Select name="dataExportGroup">
            <Option key="no-value" value="">
              -
            </Option>
            {this.props.dataExportGroupOptions.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </FormItem>
        <div className="app-DataExportModal__footer">
          <Button onClick={this.props.hideModal}>Cancel</Button>
          <Button disabled={this.props.isSaving} htmlType="submit" type="primary">
            {this.props.isSaving ? 'Saving...' : 'Save'}
          </Button>
        </div>
      </Form>
    );
  };

  render() {
    const validationSchema = this.props.usesPostgres
      ? postgresValidationSchema
      : sqliteValidationSchema;
    return (
      <div className="app-DataExportModal">
        <Modal
          className="app-DataExportModal__modal"
          confirmLoading={this.props.isSaving}
          destroyOnClose
          footer={null}
          maskClosable={false}
          onCancel={this.props.hideModal}
          title={this.getTitle()}
          visible={this.props.isVisible}
        >
          <div className="app-DataExportModal__modal-content">
            {this.renderErrors()}
            <Formik
              enableReinitialize
              initialValues={this.getInitialValues()}
              validationSchema={validationSchema}
              onSubmit={this.handleSave}
            >
              {this.renderForm}
            </Formik>
          </div>
        </Modal>
      </div>
    );
  }
}
