import { Alert, Tabs } from 'antd';
import React from 'react';
import debounceRender from 'react-debounce-render';

import { TAB_BAR_HEIGHT } from 'shared/constants';
import Pivot from './Pivot';
import Table from './Table';

const DebouncedTable = debounceRender(Table, 500);
const TabPane = Tabs.TabPane;

export interface OwnProps {
  readonly height: number;
  readonly tabId: string;
  readonly width: number;
}

export interface InjectedProps {
  readonly errorMessage: string;
  readonly showData: boolean;
}

type Props = OwnProps & InjectedProps;

export default class DataDisplay extends React.Component<Props> {
  render() {
    if (this.props.errorMessage) {
      return (
        <div className="app-DataDisplay">
          <Alert
            className="app-DataDisplay__error-message"
            message={this.props.errorMessage}
            type="error"
          />
        </div>
      );
    }

    if (!this.props.showData) {
      return <div className="app-DataDisplay" />;
    }

    return (
      <div className="app-DataDisplay">
        <Tabs
          animated={false}
          size="small"
          tabBarStyle={{ height: TAB_BAR_HEIGHT, marginBottom: 0 }}
          type="line"
        >
          <TabPane tab="Table" key="1">
            <DebouncedTable
              height={this.props.height - TAB_BAR_HEIGHT}
              tabId={this.props.tabId}
              width={this.props.width}
            />
          </TabPane>
          <TabPane tab="Pivot" key="2">
            <Pivot
              height={this.props.height - TAB_BAR_HEIGHT}
              tabId={this.props.tabId}
              width={this.props.width}
            />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}
