import { createLogic } from 'redux-logic';

import * as dataExports from 'state/data/dataExports';
import * as dataSource from 'state/data/dataSource';
import * as tableViews from 'state/data/tableViews';
import * as views from 'state/data/views';

export const afterDataExportEditSuccess = createLogic({
  type: [`${dataExports.edit}.success`],
  process({ action }, dispatch, done) {
    if (action.meta.refetchAll) {
      dispatch(dataExports.fetch({ isFox: true }));
    }
    done();
  },
});

export const refetchDataExports = createLogic({
  type: [
    `${dataExports.remove}.success`,
    `${dataExports.save}.success`,
    `${dataSource.deleteSourceTableGroup}.success`,
    `${dataSource.saveSourceTableGroup}.success`,
    `${dataSource.syncSourceTableGroup}.success`,
    `${dataSource.upload}.success`,
  ],
  process(deps, dispatch, done) {
    dispatch(dataExports.fetch({ isFox: true }));
    done();
  },
});

export const refetchDataSource = createLogic({
  type: [
    `${dataSource.deleteSourceTableGroup}.success`,
    `${dataSource.saveSourceTableGroup}.success`,
    `${dataSource.syncSourceTableGroup}.success`,
    `${dataSource.syncSourceTableGroup}.failure`,
    `${dataSource.upload}.success`,
  ],
  process(deps, dispatch, done) {
    dispatch(dataSource.fetch());
    done();
  },
});

export const refetchTableView = createLogic({
  type: `${tableViews.saveColumns}.success`,
  process({ action }, dispatch, done) {
    const { id } = action.payload.data;
    dispatch(tableViews.fetch(id));
    done();
  },
});

export const refetchViews = createLogic({
  type: `${views.save}.success`,
  process(deps, dispatch, done) {
    dispatch(views.fetch());
    done();
  },
});

export default [
  afterDataExportEditSuccess,
  refetchDataExports,
  refetchDataSource,
  refetchTableView,
  refetchViews,
];
