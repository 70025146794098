import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import * as auth from 'state/auth';
import * as data from 'state/data';
import * as editorTabs from 'state/fox/editorTabs';

interface Params {
  readonly slug: string;
}

interface Props extends RouteComponentProps<Params> {}

function selectUrl(state: any, props: Props) {
  return props.match.url;
}

export const selector = createStructuredSelector({
  canView: auth.selectCanViewFoxOrFront,
  organization: auth.selectCurrentOrganization,
  url: selectUrl,
});

const actions = {
  resetData: data.reset,
  resetEditorTabs: editorTabs.reset,
};

export default connect(selector, actions);
