import startsWith from 'lodash/startsWith';

import { DataExport, DataExportGroupingItem } from 'types';

function getUUIDOrNull(id: string) {
  if (startsWith(id, 'generated')) {
    return null;
  }
  return id;
}

export function generateNodeId() {
  const time = new Date().getTime();
  return `generated-id-${time}`;
}

export function getNodeKey({ node }: { node: any }) {
  return node.id;
}

export function buildFullData(treeData: any[]) {
  const grouping = treeData.map((item: DataExportGroupingItem) => {
    if (item.type === 'dataExportGroup') {
      return {
        group_id: getUUIDOrNull(item.id),
        group_name: item.name,
        data_view_ids: (item as any).children.map((item: DataExport) => item.id),
      };
    }
    return item.id;
  });
  return { grouping };
}
