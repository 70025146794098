import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as auth from 'state/auth';
import * as dashboardItems from 'state/data/dashboardItems';
import modals from 'state/modals';
import { OwnProps } from './DashboardItem';

function selector() {
  function selectDashboardItemId(state: any, props: OwnProps) {
    return props.id || '';
  }

  return createStructuredSelector({
    canEdit: auth.selectIsAdminFront,
    credentials: auth.selectCredentials,
    dashboardItem: dashboardItems.selectDashboardItemFactory(selectDashboardItemId),
    id: selectDashboardItemId,
  });
}

const actions = {
  showDashboardItemEditor: modals.showDashboardItemEditor,
};

export default connect(selector, actions);
