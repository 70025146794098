import { DashboardFilterValue } from 'types';

// -------
// Typings
// -------

interface Filter {
  column: string;
  operator: string;
  values: DashboardFilterValue;
}

// -------
// Helpers
// -------

function parseFilter(filter: Filter) {
  const parsedFilter: any[] = [];
  const operator = filter.operator || '=';
  filter.values.forEach((value: string, valueIndex: number) => {
    parsedFilter.push([filter.column, operator, value]);
    if (filter.values.length > valueIndex + 1) {
      parsedFilter.push('or');
    }
  });
  return parsedFilter.length === 1 ? parsedFilter[0] : parsedFilter;
}

// ------------------
// Exported functions
// ------------------

// Parses dashboard filters to a format supported by the API
export function parseFiltersForApi(filtersToUse: Filter[]) {
  const parsedFilters: any[] = [];
  filtersToUse.forEach((filter: any, filterIndex: number) => {
    parsedFilters.push(parseFilter(filter));
    if (filtersToUse.length > filterIndex + 1) {
      parsedFilters.push('and');
    }
  });
  return parsedFilters.length === 1 ? parsedFilters[0] : parsedFilters;
}
