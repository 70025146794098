// This file contains selectors utilizing state.router which is added by
// connected-react-router.

import { createSelector } from 'reselect';

export function selectPathname(state: any): string {
  return state.router.location.pathname;
}

export const selectOrganizationSlug = createSelector(selectPathname, (pathname: string) => {
  const regex = new RegExp(
    '^/w/' + // /w/
      '([^/]+)' // <organization slug>
  );
  const match = regex.exec(pathname);
  if (match) {
    return match[1];
  }
  return null;
});

export const selectCurrentPage = createSelector(selectPathname, (pathname: string) => {
  const regex = new RegExp(
    '^/w/' + // /w/
      '[^/]+/' + // <organization slug>/
      '([^/]+)' // <current page>
  );
  const match = regex.exec(pathname);
  if (match) {
    return match[1];
  }
  return '';
});
