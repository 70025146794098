import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as editorTabs from 'state/fox/editorTabs';

export const selector = createStructuredSelector({
  activeTabId: editorTabs.selectActiveTabId,
  tabs: editorTabs.selectTabs,
});

const actions = {
  addTab: editorTabs.openTab,
  changeActiveTab: editorTabs.changeActiveTab,
  removeTab: editorTabs.removeTab,
};

export default connect(selector, actions);
