import { ArrowRightOutlined } from '@ant-design/icons';
import { Button, Modal, Radio, Switch, Tabs, Typography } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import classNames from 'classnames';
import React from 'react';

import CopyInput from 'shared/CopyInput';
import { DataExport } from 'types';
import DataExportConnections from './DataExportConnections';

const { Button: RadioButton, Group: RadioGroup } = Radio;
const { TabPane } = Tabs;
const { Paragraph, Text } = Typography;

export interface Props {
  readonly canEdit: boolean;
  readonly dataExport: DataExport;
  readonly onChange: (values: {}) => void;
}

interface State {
  format: string;
  modalVisible: boolean;
  system: string;
}

const DEFAULT_SYSTEM = 'powerbi';
const DEFAULT_FORMAT = 'jsonObject';

export default class AuthAccess extends React.Component<Props, State> {
  state: State = {
    format: DEFAULT_FORMAT,
    modalVisible: false,
    system: DEFAULT_SYSTEM,
  };

  changeFormat = (event: RadioChangeEvent) => {
    const format = event.target.value;
    this.setState({ format });
  };

  changeSystem = (system: string) => {
    this.setState({ system });
  };

  handleAuthAccessChange = (checked: boolean) => {
    this.props.onChange({ has_auth_access: checked });
  };

  hideModal = () => {
    this.setState({ modalVisible: false });
  };

  showModal = ({ system = DEFAULT_SYSTEM }: { system: string }) => {
    this.setState({
      system,
      modalVisible: true,
    });
  };

  showOtherModal = () => {
    this.showModal({ system: 'other' });
  };

  showPowerBiModal = () => {
    this.showModal({ system: 'powerbi' });
  };

  // --------------
  // render helpers
  // --------------

  renderAccessSwitch = () => {
    const { canEdit, dataExport } = this.props;
    const disableParameterized = dataExport.isParameterized && !dataExport.hasAuthAccess;
    return (
      <Switch
        checked={dataExport.hasAuthAccess}
        disabled={!canEdit || disableParameterized}
        key={`${dataExport.id}-auth-access`}
        onChange={this.handleAuthAccessChange}
        size="small"
      />
    );
  };

  renderAction = (label: string, content: any) => {
    return (
      <table className="app-AuthAccess__action">
        <tbody>
          <tr>
            <th>{label}</th>
            <td>{content}</td>
          </tr>
        </tbody>
      </table>
    );
  };

  renderEnableStep = () => {
    return (
      <li>
        <h3>Enable external access to this data export with token authentication</h3>
        <Paragraph type="secondary">
          This will enable a secret URL and an auth token that any external system can use to access
          this data export.
        </Paragraph>
        {this.renderAction('Enable access:', this.renderAccessSwitch())}
      </li>
    );
  };

  renderCopyText = (text?: string) => {
    if (!text) return '-';
    return <CopyInput isPassword text={text} />;
  };

  // ---------
  // info tabs
  // ---------

  renderPowerBiTab = () => {
    const { authorizationHeader, authUrls, isParameterized } = this.props.dataExport;

    const urlRootEnd = '/data-exports/';
    const [urlRootStart, relativePath] = authUrls.powerbi
      ? authUrls.powerbi.split(urlRootEnd)
      : ['***', '***'];
    const urlRoot = urlRootStart + urlRootEnd;
    const url = authUrls.powerbi || `***${urlRootEnd}***`;
    const header = authorizationHeader || '*****';

    if (isParameterized) {
      return (
        <p>
          This data export requires a logged in QuickBI user to filter data and can't be used to
          create a Power BI data connector.
        </p>
      );
    }

    return (
      <ol className="app-AuthAccess__steps">
        {this.renderEnableStep()}
        <li>
          <h3>Create a data connector in Power BI Desktop</h3>
          <ul className="app-AuthAccess__substeps">
            <li>
              <Paragraph>
                In Power BI select "Get Data" <ArrowRightOutlined /> "From Web"{' '}
                <ArrowRightOutlined /> "Advanced".
              </Paragraph>
            </li>
            <li>
              <Paragraph>Input the following secret URL to "URL parts".</Paragraph>
              {this.renderAction('Secret URL:', this.renderCopyText(authUrls.powerbi))}
            </li>
            <li>
              <Paragraph>
                For "HTTP Request Header Parameters" select "Authorization" and input the following
                header.
              </Paragraph>
              {this.renderAction('Authorization header:', this.renderCopyText(authorizationHeader))}
            </li>
            <li>
              <Paragraph>In the next view make sure "anonymous access" is selected.</Paragraph>
            </li>
          </ul>
        </li>
        <li>
          <h3>If needed, make connection also work in PowerBI Service</h3>
          <ul className="app-AuthAccess__substeps">
            <li>
              <Paragraph>
                In Power BI Desktop select "Transform Data" <ArrowRightOutlined /> "Advanced
                Editor".
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                Move the part of the secret URL after <Text code>data-exports/</Text>
                to a separate <Text code>RelativePath</Text> attribute by replacing
              </Paragraph>
              <Paragraph code>
                Web.Contents("{url}", [Headers=[Authorization="{header}"]])
              </Paragraph>
              <Paragraph>with</Paragraph>
              <Paragraph code>
                Web.Contents("{urlRoot}", [Headers=[Authorization="{header}"], RelativePath="
                {relativePath}"])
              </Paragraph>
            </li>
            <li>
              <Paragraph>In Power BI Service change privacy level to "None".</Paragraph>
            </li>
          </ul>
        </li>
      </ol>
    );
  };

  renderOtherTab = () => {
    const { authorizationHeader, authUrls, isParameterized } = this.props.dataExport;

    if (isParameterized) {
      return (
        <p>
          This data export requires a logged in QuickBI user to filter data and can't be used to
          create custom connections to external systems.
        </p>
      );
    }

    const { format } = this.state;
    return (
      <ol className="app-AuthAccess__steps">
        {this.renderEnableStep()}
        <li>
          <h3>Choose a format</h3>
          <Paragraph>Select in which format to get the data from this data export.</Paragraph>
          {this.renderAction(
            'Format:',
            <RadioGroup
              buttonStyle="solid"
              onChange={this.changeFormat}
              value={format}
              size="small"
            >
              <RadioButton key="jsonObject" value="jsonObject">
                JSON
              </RadioButton>
              <RadioButton key="jsonArray" value="jsonArray">
                JSON (arrays)
              </RadioButton>
              <RadioButton key="csv" value="csv">
                CSV
              </RadioButton>
              <RadioButton key="xlsx" value="xlsx">
                XLSX
              </RadioButton>
              <RadioButton key="html" value="html">
                HTML
              </RadioButton>
            </RadioGroup>
          )}
        </li>
        <li>
          <h3>Access data through secret URL</h3>
          <Paragraph>
            Access data directly in the selected format by using the secret URL and the
            Authorization header.
          </Paragraph>
          {this.renderAction('Secret URL:', this.renderCopyText(authUrls[format]))}
          {this.renderAction('Authorization header:', this.renderCopyText(authorizationHeader))}
        </li>
      </ol>
    );
  };

  // ----------
  // info modal
  // ----------

  renderInfoModal = () => {
    const { name } = this.props.dataExport;
    return (
      <Modal
        className="app-AuthAccess__modal"
        footer={
          <Button type="primary" onClick={this.hideModal}>
            Ok
          </Button>
        }
        onCancel={this.hideModal}
        title={`Connect external system to data export "${name}" with token authentication`}
        visible={this.state.modalVisible}
        width={650}
        zIndex={1100}
      >
        <Tabs activeKey={this.state.system} animated={false} onChange={this.changeSystem}>
          <TabPane tab="Power BI" key="powerbi">
            {this.renderPowerBiTab()}
          </TabPane>
          <TabPane tab="Other" key="other">
            {this.renderOtherTab()}
          </TabPane>
        </Tabs>
      </Modal>
    );
  };

  // ------
  // render
  // ------

  render() {
    const enabled = this.props.dataExport.hasAuthAccess;
    const connections = this.props.dataExport.fetchSummary;
    return (
      <div className={classNames('app-AuthAccess', { 'app-AuthAccess--enabled': enabled })}>
        <div className="app-AuthAccess__header">
          <h4>External access with authentication</h4>
          {this.renderAccessSwitch()}
        </div>
        <div className="app-AuthAccess__buttons">
          <Button onClick={this.showPowerBiModal} size="small">
            Power BI
          </Button>
          <Button onClick={this.showOtherModal} size="small">
            Other
          </Button>
        </div>
        <DataExportConnections accessType="auth" connections={connections} />
        {this.renderInfoModal()}
      </div>
    );
  }
}
