import { Switch, Tooltip } from 'antd';
import classNames from 'classnames';
import some from 'lodash/some';
import React from 'react';

import { DataExport } from 'types';
import DataExportConnections from './DataExportConnections';

export interface Props {
  readonly canEdit: boolean;
  readonly dataExport: DataExport;
  readonly onChange: (values: {}) => void;
}

export default class FrontAccess extends React.Component<Props> {
  handleFrontAccessChange = (checked: boolean) => {
    this.props.onChange({ has_front_access: checked });
  };

  render() {
    const enabled = this.props.dataExport.hasFrontAccess;
    const connections = this.props.dataExport.fetchSummary;
    const hasFrontConnections = some(connections, (conn) => conn.accessType === 'quickbi');
    const tooltipTitle = hasFrontConnections
      ? 'Remove connections first to be able to disable access'
      : '';
    return (
      <div className={classNames('app-FrontAccess', { 'app-FrontAccess--enabled': enabled })}>
        <div className="app-FrontAccess__header">
          <h4>QuickBI Reports</h4>
          <Tooltip title={tooltipTitle}>
            <Switch
              checked={enabled}
              disabled={hasFrontConnections || !this.props.canEdit}
              key={`${this.props.dataExport.id}-front-access`}
              onChange={this.handleFrontAccessChange}
              size="small"
            />
          </Tooltip>
        </div>
        <DataExportConnections accessType="quickbi" connections={connections} />
      </div>
    );
  }
}
