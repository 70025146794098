import produce from 'immer';
import find from 'lodash/find';
import get from 'lodash/get';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { v4 as uuidv4 } from 'uuid';

import * as dataExports from 'state/data/dataExports';
import { EditorTab, TabSqlActionData } from 'types';

interface State {
  activeTabId: string;
  tabIds: string[];
  tabsById: { [key: string]: EditorTab };
}

// -------
// Helpers
// -------

let tabIndex = 1;

function getInitialTabState(initialValues: {} = {}): EditorTab {
  const id = uuidv4();
  const name = `Tab ${tabIndex}`;
  const initialSql = 'dataExportId' in initialValues ? get(initialValues, 'sql', '') : '';
  tabIndex += 1;
  const defaultValues = {
    id,
    initialSql,
    name,
    columns: [],
    columnTypes: [],
    dataExportId: null,
    errorMessage: null,
    needsValidation: true,
    sql: '',
    sqlInValidation: null,
    validSql: null,
  };
  return { ...defaultValues, ...initialValues };
}

// -------
// Actions
// -------

export const changeActiveTab = createAction('app/fox/editorTabs/changeActiveTab');

export const changeTabSql = createAction('app/fox/editorTabs/changeTabSql');

export const openTab = createAction('app/fox/editorTabs/openTab');

export const removeTab = createAction('app/fox/editorTabs/removeTab');

export const reset = createAction('app/fox/editorTabs/reset');

export const validateTabSql = createAction(
  'app/fox/editorTabs/validateTabSql',
  (data: TabSqlActionData) => data.sql,
  (data: TabSqlActionData) => data
);

// ---------
// Selectors
// ---------

function select(state: any): State {
  return state.fox.editorTabs;
}

export const selectActiveTab = createSelector(
  select,
  (state: State): EditorTab => state.tabsById[state.activeTabId]
);

export const selectActiveTabId = createSelector(
  select,
  (state: State): string => state.activeTabId
);

export const selectTabIds = createSelector(select, (state: State): string[] => state.tabIds);

export const selectTabs = createSelector(select, (state: State): EditorTab[] =>
  state.tabIds.map((tabId) => state.tabsById[tabId])
);

export const selectActiveTabSql = createSelector(select, (state: State): string =>
  get(state, ['tabsById', state.activeTabId, 'sql'], '')
);

// Tab specific selector factories
// -------------------------------

type TabIdSelector = ({}, {}) => string;

export const selectTabFactory = (tabIdSelector: TabIdSelector): any =>
  createSelector(
    select,
    tabIdSelector,
    (state: State, tabId: string) => state.tabsById[tabId] || {}
  );

export const selectColumnsFactory = (tabIdSelector: TabIdSelector): any =>
  createSelector(selectTabFactory(tabIdSelector), (tab: EditorTab) => tab.columns);

export const selectColumnTypesFactory = (tabIdSelector: TabIdSelector): any =>
  createSelector(selectTabFactory(tabIdSelector), (tab: EditorTab) => tab.columnTypes);

export const selectErrorMessageFactory = (tabIdSelector: TabIdSelector): any =>
  createSelector(selectTabFactory(tabIdSelector), (tab: EditorTab) => tab.errorMessage);

export const selectDataExportIdFactory = (tabIdSelector: TabIdSelector): any =>
  createSelector(selectTabFactory(tabIdSelector), (tab: EditorTab) => tab.dataExportId);

export const selectIsDataExportFactory = (tabIdSelector: TabIdSelector): any =>
  createSelector(selectTabFactory(tabIdSelector), (tab: EditorTab) => Boolean(tab.dataExportId));

export const selectIsSqlQueriedFactory = (tabIdSelector: TabIdSelector): any =>
  createSelector(selectTabFactory(tabIdSelector), (tab: EditorTab) =>
    Boolean(tab.validSql || tab.sqlInValidation)
  );

export const selectIsValidatingFactory = (tabIdSelector: TabIdSelector): any =>
  createSelector(selectTabFactory(tabIdSelector), (tab: EditorTab) => Boolean(tab.sqlInValidation));

export const selectNeedsValidationFactory = (tabIdSelector: TabIdSelector): any =>
  createSelector(selectTabFactory(tabIdSelector), (tab: EditorTab) => tab.needsValidation);

export const selectSqlFactory = (tabIdSelector: TabIdSelector): any =>
  createSelector(selectTabFactory(tabIdSelector), (tab: EditorTab) => tab.sql);

export const selectValidSqlFactory = (tabIdSelector: TabIdSelector): any =>
  createSelector(selectTabFactory(tabIdSelector), (tab: EditorTab) => tab.validSql);

// -----
// State
// -----

const initialTab = getInitialTabState();
const tabId = initialTab.id;
const initialState: State = {
  activeTabId: tabId,
  tabIds: [tabId],
  tabsById: {
    [tabId]: initialTab,
  },
};

const handlers = {
  [String(changeActiveTab)]: (state: State, action: any): State => ({
    ...state,
    activeTabId: action.payload,
  }),
  [String(changeTabSql)]: (state: State, action: any): State =>
    produce(state, (draft) => {
      const { sql, tabId } = action.payload;
      draft.tabsById[tabId] = {
        ...state.tabsById[tabId],
        sql,
        needsValidation: true,
      };
    }),
  [String(openTab)]: (state: State, action: any): State => {
    const initialValues = action.payload || {};
    const existingTab =
      initialValues.dataExportId &&
      find(state.tabsById, (tab) => tab.dataExportId === initialValues.dataExportId);
    return produce(state, (draft) => {
      if (existingTab) {
        draft.activeTabId = existingTab.id;
      } else {
        const newTab = getInitialTabState(initialValues);
        const tabId = newTab.id;
        const currentFirstTab = state.tabsById[state.tabIds[0]];
        const shouldReplaceFirstTab =
          state.tabIds.length === 1 &&
          initialValues.sql &&
          currentFirstTab.sql === '' &&
          currentFirstTab.name === 'Tab 1';
        draft.activeTabId = tabId;
        draft.tabsById[tabId] = newTab;
        if (shouldReplaceFirstTab) {
          draft.tabIds = [tabId];
        } else {
          draft.tabIds.push(tabId);
        }
      }
    });
  },
  [String(removeTab)]: (state: State, action: any): State => {
    const tabId = action.payload;
    return produce(state, (draft) => {
      draft.tabIds = draft.tabIds.filter((id) => id !== tabId);
      delete draft.tabsById[tabId];
      if (draft.activeTabId === tabId) {
        draft.activeTabId = get(draft, 'tabIds[0]', '');
      }
    });
  },
  [String(reset)]: (): State => initialState,
  [String(validateTabSql)]: (state: State, action: any): State =>
    produce(state, (draft) => {
      const { sql, tabId } = action.meta;
      draft.tabsById[tabId] = {
        ...state.tabsById[tabId],
        sqlInValidation: sql,
        validSql: null,
      };
    }),
  [`${validateTabSql}.failure`]: (state: State, action: any): State =>
    produce(state, (draft) => {
      const { tabId } = action.meta;
      draft.tabsById[tabId] = {
        ...state.tabsById[tabId],
        columns: [],
        columnTypes: [],
        errorMessage: 'Something went wrong. Please try again shortly.',
        sqlInValidation: null,
        validSql: null,
      };
    }),
  [`${validateTabSql}.success`]: (state: State, action: any): State =>
    produce(state, (draft) => {
      const { columns, columnTypes, errorMessage, formattedSql, isValid } = action.payload;
      const { tabId } = action.meta;
      const tab = state.tabsById[tabId];
      draft.tabsById[tabId] = {
        ...tab,
        columns,
        columnTypes,
        errorMessage: isValid ? null : errorMessage,
        needsValidation: !isValid,
        sql: isValid ? formattedSql : tab.sql,
        sqlInValidation: null,
        validSql: isValid ? formattedSql : null,
      };
    }),
  [`${dataExports.edit}.success`]: (state: State, action: any): State =>
    produce(state, (draft) => {
      const { tabId } = action.meta;
      const name = get(action.payload, '[0].name');
      const sql = get(action.payload, '[0].sql');
      draft.tabsById[tabId] = {
        ...state.tabsById[tabId],
        name,
        sql,
        initialSql: sql,
      };
    }),
  [`${dataExports.save}.success`]: (state: State, action: any): State =>
    produce(state, (draft) => {
      const { tabId } = action.meta;
      const dataExportId = get(action.payload, '[0].id');
      const name = get(action.payload, '[0].name');
      const sql = get(action.payload, '[0].sql');
      draft.tabsById[tabId] = {
        ...state.tabsById[tabId],
        dataExportId,
        name,
        sql,
        initialSql: sql,
      };
    }),
};

export default handleActions(handlers, initialState);
