import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as api from 'state/api';
import * as tableViews from 'state/data/tableViews';
import modals from 'state/modals';

export const selector = createStructuredSelector({
  errorMessages: api.selectSaveColumnsErrors,
  isSaving: api.selectIsSaveColumnsPending,
  isVisible: modals.selectIsColumnEditorVisible,
});

const actions = {
  hideModal: modals.hideColumnEditor,
  saveColumns: tableViews.saveColumns,
};

export default connect(selector, actions);
