import 'brace/mode/sql';

import dataTypes from './dataTypes';
import functions from './functions';
import keywords from './keywords';
import qbiKeywords from './qbiKeywords';

export class SqliteHighlightRules extends (<any>window).ace.acequire(
  'ace/mode/text_highlight_rules'
).TextHighlightRules {
  $rules: any;

  constructor() {
    super();

    const keywordMapper = this.createKeywordMapper(
      {
        'support.function': functions,
        keyword: keywords,
        'constant.language': '',
        'storage.type': dataTypes,
      },
      'identifier',
      false
    );

    this.$rules = {
      start: [
        {
          token: 'keyword.qbi', // our custom syntax
          regex: qbiKeywords,
        },
        {
          token: 'comment',
          regex: '--.*$',
        },
        {
          token: 'comment',
          start: '/\\*',
          end: '\\*/',
        },
        {
          token: 'string', // " string
          regex: '".*?"',
        },
        {
          token: 'string', // ' string
          regex: "'.*?'",
        },
        {
          token: 'string', // ` string
          regex: '`.*?`',
        },
        {
          token: 'constant.numeric', // float
          regex: '[+-]?\\d+(?:(?:\\.\\d*)?(?:[eE][+-]?\\d+)?)?\\b',
        },
        {
          token: keywordMapper,
          regex: '[a-zA-Z_$][a-zA-Z0-9_$]*\\b',
        },
        {
          token: 'keyword.operator',
          regex: '\\+|\\-|\\/|\\/\\/|%|<@>|@>|<@|&|\\^|~|<|>|<=|=>|==|!=|<>|=',
        },
        {
          token: 'paren.lparen',
          regex: '[\\(]',
        },
        {
          token: 'paren.rparen',
          regex: '[\\)]',
        },
        {
          token: 'text',
          regex: '\\s+',
        },
      ],
    };
    this.normalizeRules();
  }
}

export default class SqliteMode extends (<any>window).ace.acequire('ace/mode/sql').Mode {
  HighlightRules: any;
  constructor() {
    super();
    this.HighlightRules = SqliteHighlightRules;
  }
}
