import { Button } from 'antd';
import React from 'react';

import { downloadDataExportData } from 'services/api';
import { DataExport } from 'types';

export interface Props {
  dataExport: DataExport;
}

export default class DataExportDownload extends React.Component<Props> {
  getDownloadHandler = (format: string) => {
    const { dataExport } = this.props;
    return () => downloadDataExportData(dataExport, format);
  };

  renderDownloadButton = (format: string, label: string) => {
    const { dataExport } = this.props;
    return (
      <Button
        className="app-DataExportDownload__button"
        disabled={!dataExport.isValid}
        onClick={this.getDownloadHandler(format)}
        size="small"
        type="default"
      >
        {label}
      </Button>
    );
  };

  render() {
    return (
      <div className="app-DataExportDownload">
        {this.renderDownloadButton('csv', 'CSV')}
        {this.renderDownloadButton('xlsx', 'XLSX')}
        {this.renderDownloadButton('jsonObject', 'JSON')}
        {this.renderDownloadButton('jsonArray', 'JSON (arrays)')}
      </div>
    );
  }
}
