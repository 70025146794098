import normalize from 'json-api-normalizer';
import build from 'redux-object';

import { Credentials, DataSource, SourceTableGroup, SourceTableGroupSaveData } from 'types';
import { makeApiRequest, uploadFile } from './utils';

export function deleteSourceTableGroup(
  credentials: Credentials,
  data: { id: string }
): Promise<SourceTableGroup> {
  const endpoint = `source-table-groups/${data.id}`;
  return makeApiRequest<any>(credentials, endpoint, 'DELETE');
}

export function getDataSource(credentials: Credentials): Promise<DataSource> {
  return makeApiRequest<any>(credentials, 'source-data').then((json: any) => {
    if (json.data) {
      const id = json.data.id;
      return build(normalize(json), 'sourceData', id, { eager: true });
    }
    return {};
  });
}

export function saveSourceTableGroup(
  credentials: Credentials,
  data: SourceTableGroupSaveData
): Promise<SourceTableGroup> {
  const jsonAPIFormattedData = {
    data: {
      attributes: data.attributes,
      type: 'source_table_groups',
    },
  };
  return makeApiRequest<any>(credentials, 'source-table-groups', 'POST', jsonAPIFormattedData);
}

export function syncSourceTableGroup(
  credentials: Credentials,
  data: { id: string }
): Promise<SourceTableGroup> {
  const endpoint = `source-table-groups/${data.id}/sync`;
  return makeApiRequest<any>(credentials, endpoint, 'POST');
}

export function uploadDataSource(credentials: Credentials, formData: FormData): Promise<any> {
  return uploadFile(credentials, 'source-files', formData);
}
