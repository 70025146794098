import React from 'react';
import {
  SortableContainer,
  SortableContainerProps,
  SortableElement,
  SortableElementProps,
} from 'react-sortable-hoc';

import Column, { ColumnChangeHandler } from './Column';
import { TableViewColumn } from 'types';

interface SortableColumnProps extends SortableElementProps {
  column: TableViewColumn;
  onColumnChange: ColumnChangeHandler;
}

const SortableColumn: React.ComponentClass<SortableColumnProps> = SortableElement(Column);

interface SortableColumnsProps extends SortableContainerProps {
  columnIds: string[];
  columns: { [id: string]: TableViewColumn };
  onColumnChange: ColumnChangeHandler;
}

const SortableColumns: React.ComponentClass<SortableColumnsProps> = SortableContainer(
  ({ columnIds, columns, onColumnChange }: SortableColumnsProps) => {
    return (
      <div className="app-ColumnsForm__container">
        {columnIds.map((columnId: string, index: number) => (
          <SortableColumn
            key={`item-${columnId}`}
            index={index}
            column={columns[columnId]}
            onColumnChange={onColumnChange}
          />
        ))}
      </div>
    );
  }
);

export default SortableColumns;
