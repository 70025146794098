import { Credentials, ExtendedApiError } from 'types';

// -------
// Helpers
// -------

function getExtendedApiError(response: Response, json: any = {}): ExtendedApiError {
  return {
    ...json,
    status: response.status,
    statusText: response.statusText,
  };
}

function getNoCredentialsError() {
  return {
    status: 401,
    statusText: 'No Credentials',
  };
}

function getUrl(credentials: Credentials, endpoint: string) {
  const baseUrl = process.env.API_URL;
  return `${baseUrl}/organizations/${credentials.organizationId}/${endpoint}`;
}

// These should only happen if there is some kind of connection error or if
// API doesn't return a response at all (500 internal server errors).
function handleFetchFailure() {
  const error = {
    status: 0,
    statusText: 'FETCH FAILURE',
  };
  return Promise.reject(error);
}

function handleJSONResponse(response: Response) {
  if (response.status === 204) {
    return response;
  }
  return response.json().then((json: any) => {
    if (response.ok) {
      return json;
    }
    const error = getExtendedApiError(response, json);
    return Promise.reject(error);
  });
}

function handleTextResponse(response: Response) {
  if (response.status === 204) {
    return response;
  }
  return response.text().then((text: any) => {
    if (response.ok) {
      return text;
    }
    const error = getExtendedApiError(response);
    return Promise.reject(error);
  });
}

// ------------------
// Exported functions
// ------------------

export function makeApiRequest<T>(
  credentials: Credentials,
  endpoint: string,
  method = 'GET',
  data?: {}
): Promise<T> {
  if (!credentials.csrfToken) {
    return Promise.reject(getNoCredentialsError());
  }

  const url = getUrl(credentials, endpoint);
  const body = data ? JSON.stringify(data) : undefined;
  const csrfToken = credentials.csrfToken as string;
  const headers: any = {
    'Content-Type': 'application/vnd.api+json',
  };
  if (method !== 'GET') {
    headers['X-CSRF-TOKEN'] = csrfToken;
  }
  const options = {
    method,
    body,
    headers,
    credentials: 'include' as RequestCredentials,
  };

  return window.fetch(url, options).catch(handleFetchFailure).then(handleJSONResponse);
}

export function makeApiRequestForText<T>(credentials: Credentials, endpoint: string): Promise<T> {
  if (!credentials.csrfToken) {
    return Promise.reject(getNoCredentialsError());
  }

  const url = getUrl(credentials, endpoint);
  const options = {
    credentials: 'include' as RequestCredentials,
    method: 'GET',
  };

  return window.fetch(url, options).catch(handleFetchFailure).then(handleTextResponse);
}

export function uploadFile(credentials: Credentials, endpoint: string, formData: any) {
  if (!credentials.csrfToken) {
    return Promise.reject(getNoCredentialsError());
  }

  const url = getUrl(credentials, endpoint);
  const options = {
    body: formData,
    credentials: 'include' as RequestCredentials,
    method: 'POST',
    headers: {
      'X-CSRF-TOKEN': credentials.csrfToken,
    },
  };

  return window.fetch(url, options).catch(handleFetchFailure).then(handleJSONResponse);
}
