import yaml from 'js-yaml';
import { connect } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';

import * as api from 'state/api';
import * as dashboards from 'state/data/dashboards';
import modals from 'state/modals';
import { OwnProps } from './DashboardEditor';

function selectDashboard(state: any, props: OwnProps) {
  return props.dashboard;
}

const specsSelector = createSelector(selectDashboard, (dashboard) => {
  if (!dashboard) return '';
  return yaml.dump({
    filters: dashboard.filters,
    layout: dashboard.layout,
  });
});

export const selector = createStructuredSelector({
  dashboard: selectDashboard,
  errorMessages: api.selectEditDashboardsErrors,
  isSaving: api.selectIsEditDashboardsPending,
  isVisible: modals.selectIsDashboardEditorVisible,
  specs: specsSelector,
});

const actions = {
  editDashboard: dashboards.edit,
  hideModal: modals.hideDashboardEditor,
};

export default connect(selector, actions);
