import normalize from 'json-api-normalizer';
import build from 'redux-object';

import * as notifications from 'services/notifications';
import { Credentials, DataExport, DataExportEditData, DataExportSaveData } from 'types';
import { makeApiRequest } from './utils';

// -------
// Helpers
// -------

function getFilename(dataExport: DataExport, format: string) {
  if (format === 'jsonArray') {
    return `${dataExport.name}_arrays.json`;
  }
  if (format === 'jsonObject') {
    return `${dataExport.name}.json`;
  }
  return `${dataExport.name}.${format}`;
}

function parseDataExportGroupingResponse(json: any) {
  const groupingData = build(normalize(json), 'dataViewGroupings')[0];
  const dataExports = build(groupingData, 'dataViews');
  return {
    dataExports,
    grouping: groupingData.grouping,
  };
}

// ------------------
// Exported functions
// ------------------

export function deleteDataExport(credentials: Credentials, id: string): Promise<DataExport> {
  return makeApiRequest<any>(credentials, `data-views/${id}`, 'DELETE');
}

export function editDataExport(
  credentials: Credentials,
  data: DataExportEditData
): Promise<DataExport> {
  const jsonAPIFormattedData = {
    data: {
      id: data.id,
      attributes: data.attributes,
      relationships: data.relationships,
      type: 'data_views',
    },
  };
  return makeApiRequest<any>(
    credentials,
    `data-views/${data.id}`,
    'PATCH',
    jsonAPIFormattedData
  ).then((json: any) => build(normalize(json), 'dataViews'));
}

export function getDataExportsForFox(credentials: Credentials): Promise<{}> {
  return makeApiRequest<any>(credentials, 'data-view-grouping?fox=true').then((json: any) => {
    if (json.data) {
      return parseDataExportGroupingResponse(json);
    }
    return json;
  });
}

export function getDataExportsForFront(credentials: Credentials): Promise<{}> {
  return makeApiRequest<any>(credentials, 'data-view-grouping').then((json: any) => {
    if (json.data) {
      return parseDataExportGroupingResponse(json);
    }
    return json;
  });
}

export function saveDataExport(
  credentials: Credentials,
  data: DataExportSaveData
): Promise<DataExport> {
  const jsonAPIFormattedData = {
    data: {
      attributes: data.attributes,
      relationships: data.relationships,
      type: 'data_views',
    },
  };
  return makeApiRequest<any>(
    credentials,
    'data-views',
    'POST',
    jsonAPIFormattedData
  ).then((json: any) => build(normalize(json), 'dataViews'));
}

export function saveDataExportGrouping(
  credentials: Credentials,
  data: DataExportSaveData
): Promise<{}> {
  const jsonAPIFormattedData = {
    data: {
      type: 'data_view_groupings',
      id: credentials.organizationId,
      attributes: data,
    },
  };
  return makeApiRequest<any>(credentials, 'data-view-grouping', 'PATCH', jsonAPIFormattedData).then(
    (json: any) => {
      if (json.data) {
        return parseDataExportGroupingResponse(json);
      }
      return json;
    }
  );
}

export function downloadDataExportData(dataExport: DataExport, format: string): Promise<any> {
  const baseUrl = process.env.API_URL;
  const endpoint = dataExport.downloadUrls[format];
  const url = `${baseUrl}${endpoint}`;
  const filename = getFilename(dataExport, format);
  const fetchOptions: any = {
    credentials: 'include' as RequestCredentials,
    headers: {
      // Needed to get API to return errors in JSON format and not in HTML.
      'Content-Type': 'application/json',
    },
  };
  return window
    .fetch(url, fetchOptions)
    .then((response) => {
      if (response.status === 200) {
        response.blob().then((blob: any) => saveAs(blob, filename));
      } else {
        notifications.show('download-failure');
      }
    })
    .catch(() => {
      notifications.show('download-failure');
    });
}

export function getDataExportData(
  credentials: Credentials,
  {
    dashboardId,
    dataExportId,
    format,
  }: { dashboardId?: string; dataExportId: string; format: string }
): Promise<any> {
  const queryString = dashboardId ? `?dbid=${dashboardId}` : '';
  const url = `data-views/${dataExportId}/data.${format}${queryString}`;
  return makeApiRequest<any>(credentials, url).then((json) => ('data' in json ? json.data : []));
}
