import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as auth from 'state/auth';

const selector = createStructuredSelector({
  loginClassicFailed: auth.selectDidLoginClassicFail,
  isPasswordChangeRequired: auth.selectIsPasswordChangeRequired,
  isRateLimitError: auth.selectIsRateLimitError,
});

const actions = {
  logIn: auth.logIn,
  logInGoogle: auth.logInGoogle,
};

export default connect(selector, actions);
