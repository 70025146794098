import { Modal } from 'antd';
import yaml from 'js-yaml';
import React from 'react';

import DataExportSelect from 'shared/DataExportSelect';
import ErrorMessages from 'shared/ErrorMessages';
import SpecsEditor from 'shared/SpecsEditor';
import { DashboardItem, DashboardItemEditData, ErrorMessage } from 'types';

export interface OwnProps {}

export interface InjectedProps {
  readonly dashboardItem: DashboardItem;
  readonly dataExportId: string;
  readonly editDashboardItem: (data: DashboardItemEditData) => void;
  readonly errorMessages: ErrorMessage[];
  readonly fetchDataExports: () => void;
  readonly hideModal: () => void;
  readonly isSaving: boolean;
  readonly isVisible: boolean;
  readonly specs: string;
}

type Props = OwnProps & InjectedProps;

interface State {
  dataExportId: string;
  specs: string;
}

export default class DashboardItemEditor extends React.Component<Props, State> {
  state = {
    dataExportId: '',
    specs: this.props.specs,
  };

  componentDidUpdate(prevProps: Props) {
    if (prevProps.dataExportId !== this.props.dataExportId) {
      this.setState({ dataExportId: this.props.dataExportId });
    }
    if (prevProps.specs !== this.props.specs) {
      this.setState({ specs: this.props.specs });
    }
    if (this.props.isVisible && !prevProps.isVisible) {
      this.props.fetchDataExports();
    }
  }

  handleSpecsChange = (specs: string) => {
    this.setState({ specs });
  };

  handleDataExportIdChange = (dataExportId: string) => {
    this.setState({ dataExportId });
  };

  handleSave = () => {
    const { dashboardItem } = this.props;
    const { dataExportId } = this.state;
    const specs = yaml.load(this.state.specs);
    const attributes = {
      specs,
      name: '',
      description: '',
    };
    const relationships = {
      data_view: {
        data: dataExportId ? { type: 'data_views', id: dataExportId } : null,
      },
    };
    this.props.editDashboardItem({
      attributes,
      relationships,
      id: dashboardItem.id,
    });
  };

  render() {
    return (
      <div className="app-DashboardItemEditor">
        <Modal
          className="app-DashboardItemEditor__modal"
          confirmLoading={this.props.isSaving}
          destroyOnClose
          maskClosable={false}
          okText="Save"
          onCancel={this.props.hideModal}
          onOk={this.handleSave}
          title="Edit dashboard item specs"
          visible={this.props.isVisible}
          width={1000}
        >
          <div className="app-DashboardItemEditor__modal-content">
            <ErrorMessages errorMessages={this.props.errorMessages} />
            <div className="app-DashboardItemEditor__data-export">
              <div className="app-DashboardItemEditor__data-export-label">Data export</div>
              <div className="app-DashboardItemEditor__data-export-select">
                <DataExportSelect
                  onChange={this.handleDataExportIdChange}
                  value={this.state.dataExportId}
                />
              </div>
            </div>
            {this.props.dashboardItem && (
              <SpecsEditor
                height={446}
                id={this.props.dashboardItem.id}
                onChange={this.handleSpecsChange}
                value={this.state.specs}
              />
            )}
          </div>
        </Modal>
      </div>
    );
  }
}
