import ace from 'brace';

// Fix TypeScript error
const anyAce: any = ace;

// Define custom snippets
const snippets = [
  "snippet strftime\n	strftime('${1:timestring}', ${2:modifier})\n",
  "snippet replace\n	replace(${1:string}, '${2:search_value}', '${3:new_value}')\n",
  'snippet S*\n	SELECT * FROM ${1:table}\n',
];

anyAce.define('ace/snippets/sql', ['require', 'exports', 'module'], (e: any, t: any) => {
  (t.snippetText = snippets.join('')), (t.scope = 'sql');
});
