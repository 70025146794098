import { Tabs } from 'antd';
import throttle from 'lodash/throttle';
import React from 'react';

import { EditorTab as IEditorTab } from 'types';
import EditorTab from './EditorTab';

const TabPane = Tabs.TabPane;

export interface OwnProps {
  readonly width: number;
}

export interface InjectedProps {
  readonly activeTabId: string;
  readonly addTab: () => void;
  readonly removeTab: (tabId: string) => void;
  readonly changeActiveTab: (tabId: string) => void;
  readonly tabs: IEditorTab[];
}

type Props = OwnProps & InjectedProps;

export default class EditorTabs extends React.Component<Props> {
  handleTabsEdit = (targetKey: string, action: 'add' | 'remove') => {
    if (action === 'add') {
      this.handleAddTab();
    }
    if (action === 'remove') {
      this.props.removeTab(targetKey);
    }
  };

  handleAddTab = throttle(() => this.props.addTab(), 400);

  renderTabName = (tab: IEditorTab) => {
    const sqlChanged = tab.initialSql !== tab.sql;
    return (
      <span>
        {tab.name}
        {sqlChanged && ' *'}
      </span>
    );
  };

  render() {
    const canCloseTabs = this.props.tabs.length > 1;
    return (
      <div className="app-EditorTabs" style={{ width: this.props.width }}>
        <Tabs
          activeKey={this.props.activeTabId}
          animated={false}
          onChange={this.props.changeActiveTab}
          onEdit={this.handleTabsEdit}
          tabBarGutter={0}
          type="editable-card"
        >
          {this.props.tabs.map((tab) => (
            <TabPane closable={canCloseTabs} key={tab.id} tab={this.renderTabName(tab)}>
              <EditorTab tabId={tab.id} width={this.props.width} />
            </TabPane>
          ))}
        </Tabs>
      </div>
    );
  }
}
