import get from 'lodash/get';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { createSelector, createStructuredSelector } from 'reselect';

import * as views from 'state/data/views';
import { UserOrganization } from 'types';

interface IParams {
  readonly viewGroupSlug: string;
  readonly viewIndex: string;
}

interface IProps extends RouteComponentProps<IParams> {
  readonly organization: UserOrganization;
}

function selectOrganization(state: any, props: IProps) {
  return props.organization;
}

function selectViewGroupSlug(state: any, props: IProps) {
  return props.match.params.viewGroupSlug;
}

function selectviewIndex(state: any, props: IProps) {
  return props.match.params.viewIndex || '0';
}

const selectViewGroup = createSelector(
  selectOrganization,
  selectViewGroupSlug,
  views.selectViewCategories,
  (organization, viewGroupSlug, viewCategories) => {
    for (const viewCategory of viewCategories) {
      for (const viewGroup of viewCategory.viewGroups) {
        if (viewGroup.slug === viewGroupSlug && viewCategory.organization.id === organization.id) {
          return viewGroup;
        }
      }
    }
    return null;
  }
);

const selectView = createSelector(selectViewGroup, selectviewIndex, (viewGroup, viewIndex) => {
  return get(viewGroup, `views[${viewIndex}]`, null);
});

export const selector = createStructuredSelector({
  view: selectView,
});

export default connect(selector);
