import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as auth from 'state/auth';
import * as dataSource from 'state/data/dataSource';

const selector = createStructuredSelector({
  canEditDataExports: auth.selectCanEditDataExports,
  canAddDataSources: auth.selectCanAddDataSources,
  canView: auth.selectCanViewFox,
  currentOrganization: auth.selectCurrentOrganization,
});

const actions = {
  fetchDataSource: dataSource.fetch,
};

export default connect<{}, {}, {}>(selector, actions);
