import { UserOutlined } from '@ant-design/icons';
import { Avatar, Dropdown, Menu, Switch } from 'antd';
import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import { FOX_PAGE_NAME, FOX_PAGE_PATH, FRONT_PAGE_NAME, FRONT_PAGE_PATH } from 'shared/constants';
import { openHelpModal } from 'shared/modal';
import OrganizationSelector from './OrganizationSelector';

export interface InjectedProps {
  readonly currentPage: string;
  readonly hasNavLinks: boolean;
  readonly onLogOut: () => void;
  readonly url: string;
  readonly username: string | null;
}

export default class Topbar extends React.Component<InjectedProps> {
  handleUserMenuClick = ({ key }: any) => {
    if (key === 'help') {
      openHelpModal();
    } else if (key === 'logout') {
      this.props.onLogOut();
    } else {
      throw new Error('Invalid key');
    }
  };

  renderNavLinks = () => {
    const foxUrl = `${this.props.url}/${FOX_PAGE_PATH}`;
    const frontUrl = `${this.props.url}/${FRONT_PAGE_PATH}`;
    const isFoxPage = this.props.currentPage === FOX_PAGE_PATH;
    return (
      <div className="app-Topbar__navigation">
        <Link
          className={classNames('app-Topbar__navigation-link-fox', {
            'app-Topbar__navigation-link-fox--active': isFoxPage,
          })}
          to={foxUrl}
        >
          <span>{FOX_PAGE_NAME}</span>
        </Link>
        <Link className="app-Topbar__switch" to={isFoxPage ? frontUrl : foxUrl}>
          <Switch checked={!isFoxPage} />
        </Link>
        <Link
          className={classNames('app-Topbar__navigation-link-front', {
            'app-Topbar__navigation-link-front--active': !isFoxPage,
          })}
          to={frontUrl}
        >
          <span>{FRONT_PAGE_NAME}</span>
        </Link>
      </div>
    );
  };

  renderUserMenu = () => {
    const baseApiUrl = process.env.API_URL;
    const userSettingsUrl = `${baseApiUrl}/user-settings`;

    const userMenu = (
      <Menu className="app-Topbar-user-dropdown" onClick={this.handleUserMenuClick}>
        <Menu.Item key="help">Help</Menu.Item>
        <Menu.Item key="settings">
          <a className="app-OrganizationSelector-item-link" href={userSettingsUrl}>
            Settings
          </a>
        </Menu.Item>
        <Menu.Item key="logout">Log out</Menu.Item>
      </Menu>
    );
    return (
      <Dropdown overlay={userMenu} trigger={['click']}>
        <div className="app-Topbar-user">
          <div className="app-Topbar-user-name">{this.props.username}</div>
          <Avatar className="app-Topbar-user-avatar" icon={<UserOutlined />} />
        </div>
      </Dropdown>
    );
  };

  render() {
    return (
      <div className="app-Topbar">
        <div className="app-Topbar__left-menu">
          <OrganizationSelector collapsed={false} mobile={false} />
        </div>
        <div className="app-Topbar__center-menu">
          {this.props.hasNavLinks && this.renderNavLinks()}
        </div>
        <div className="app-Topbar__right-menu">{this.renderUserMenu()}</div>
      </div>
    );
  }
}
