import { connect } from 'react-redux';
// import { createStructuredSelector } from 'reselect';

import * as editorTabs from 'state/fox/editorTabs';

// Use empty createStructuredSelector to keep type checks happy.
// const selector = createStructuredSelector({});

const actions = {
  showSqlInEditor: editorTabs.openTab,
};

export default connect(null, actions);
