import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  showHomeLink?: boolean;
}

export class NotFoundPage extends React.Component<Props> {
  static defaultProps = {
    showHomeLink: true,
  };

  render() {
    const { showHomeLink } = this.props;
    return (
      <div className="app-NotFoundPage">
        <h2 className="app-NotFoundPage-header">Sorry! This page was not found.</h2>
        {showHomeLink && <Link to="/">Go to home page</Link>}
      </div>
    );
  }
}

export default NotFoundPage;
