import sqlFormatter from 'sql-formatter-plus';

const CUSTOM_SQL_SEPARATOR = '### WHERE ###';

const FORMAT_OPTIONS = {
  indent: '    ',
  uppercase: true,
};

export function formatSql(sql: string) {
  const sqlParts = sql.split(CUSTOM_SQL_SEPARATOR);
  const formattedSql = sqlFormatter.format(sqlParts[0], FORMAT_OPTIONS);
  if (sqlParts.length > 1) {
    return [formattedSql, '\n\n', CUSTOM_SQL_SEPARATOR, sqlParts[1]].join('');
  }
  return formattedSql;
}
