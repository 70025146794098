import { connect } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';

import * as auth from 'state/auth';
import { UserOrganization } from 'types';

const selectDefaultOrganizationPath = createSelector(
  auth.selectDefaultOrganization,
  (org: UserOrganization | null) => {
    const slug = org && org.slug;
    if (slug) return `/w/${slug}`;
    return null;
  }
);

export const selector = createStructuredSelector({
  redirectPath: selectDefaultOrganizationPath,
});

export default connect(selector);
