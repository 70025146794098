import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Cascader } from 'antd';
import classNames from 'classnames';
import capitalize from 'lodash/capitalize';
import filter from 'lodash/filter';
import find from 'lodash/find';
import React from 'react';

import { calculateCountUniqueSummary, getDefaultDataAreaField } from 'shared/PivotTable/utils';
import { getDefaultDxFormatter } from 'shared/utils';
import { DxPivotField } from 'types';

// Always show at most 2 decimals in measure field cells even if PivotTable
// wouldn't have any maximum for other fields.
const MAX_DECIMALS = 2;

const MAX_ITEMS = 3;

interface Props {
  readonly grid: any;
  readonly hidden: boolean;
}

export default class MeasureSelect extends React.Component<Props> {
  getDataSource() {
    return this.props.grid.getDataSource();
  }

  getFields(): DxPivotField[] {
    return this.getDataSource().fields();
  }

  getDataFields(): DxPivotField[] {
    const fields = this.getFields();
    return filter(fields, (field: any) => field.area === 'data');
  }

  getNonDataFields(): DxPivotField[] {
    const fields = this.getFields();
    return filter(fields, (field: any) => field.area !== 'data');
  }

  addItem = () => {
    const dataFields = this.getDataFields();
    const nonDataFields = this.getNonDataFields();
    const newField: DxPivotField = getDefaultDataAreaField();
    const fields = dataFields.concat([newField]).concat(nonDataFields);
    this.updateFields(fields);
  };

  removeItem = () => {
    const dataFields = this.getDataFields().slice(0, -1);
    const nonDataFields = this.getNonDataFields();
    const fields = dataFields.concat(nonDataFields);
    this.updateFields(fields);
  };

  getMeasureOptions() {
    const nonDataFields = this.getNonDataFields();
    const numberTypes = ['int', 'real', 'number'];
    const numberFields = filter(nonDataFields, (field: any) =>
      numberTypes.includes(field.dataType)
    );
    const numberFieldOptions = numberFields.map((field: DxPivotField) => ({
      value: field.dataField,
      label: field.caption,
    }));
    const countUniqueFields = filter(
      nonDataFields,
      // Dates behave differently from others: DevExtreme can split them
      // to year, quarter and month fields. To avoid JS error related to
      // this just exclude date type for now.
      (field: any) => field.dataType !== 'date'
    );
    const countUniqueOptions = countUniqueFields.map((field: DxPivotField) => ({
      value: field.dataField,
      label: field.caption,
    }));

    const options: any[] = [
      {
        value: 'count',
        label: 'Count',
      },
    ];

    if (countUniqueOptions.length) {
      // Note that "count_unique" is a custom summary type and requires special
      // handling. Other summary types (count, sum, avg, min and max) are
      // "native" summary types.
      options.push({
        value: 'count_unique',
        label: 'Count unique',
        children: countUniqueOptions,
      });
    }

    if (numberFieldOptions.length) {
      options.push({
        value: 'sum',
        label: 'Sum',
        children: numberFieldOptions,
      });
      options.push({
        value: 'avg',
        label: 'Average',
        children: numberFieldOptions,
      });
      options.push({
        value: 'min',
        label: 'Min',
        children: numberFieldOptions,
      });
      options.push({
        value: 'max',
        label: 'Max',
        children: numberFieldOptions,
      });
    }
    return options;
  }

  handleMeasureChange = (index: number, value: string[]) => {
    this.changeMeasure(index, value[0], value[1] || null);
  };

  changeMeasure = (index: number, summaryType: string, dataField: string | null) => {
    const dxFormatter = getDefaultDxFormatter(MAX_DECIMALS);
    const dataSource = this.getDataSource();
    const fieldData: any = {
      dataField,
      summaryType,
      calculateCustomSummary: null,
      format: dxFormatter,
    };

    if (summaryType === 'count_unique') {
      fieldData.summaryType = 'custom';
      fieldData.calculateCustomSummary = calculateCountUniqueSummary;
    }

    dataSource.field(index, fieldData);
    this.updateCaptions();
    dataSource.load();
    this.forceUpdate();
  };

  getCaption(dataField: string | null, summaryType: string) {
    if (summaryType === 'count') return 'Count';
    const field = find(this.getNonDataFields(), (item: any) => item.dataField === dataField);

    // It's now assumed that the only custom summary type is "count unique". If
    // new custom summary types are added this logic has to change!
    if (summaryType === 'custom') {
      return `Count unique / ${field.caption}`;
    }

    return `${capitalize(summaryType)} / ${field.caption}`;
  }

  updateCaptions() {
    const captions: string[] = this.getDataFields().map((field: DxPivotField) => {
      return this.getCaption(field.dataField as string | null, field.summaryType as string);
    });

    // Make captions unique by adding postfixes when duplicates exist.
    for (let i = captions.length - 1; i > 0; i -= 1) {
      const count = captions.slice(0, i).filter((item: string) => item === captions[i]).length + 1;
      if (count > 1) {
        captions[i] = `${captions[i]} (${count})`;
      }
    }

    const dataSource = this.getDataSource();
    captions.forEach((caption: string, index: number) => {
      dataSource.field(index, { caption });
    });
  }

  updateFields(fields: any[]) {
    const dataSource = this.props.grid.getDataSource();
    dataSource.fields(fields);
    this.updateCaptions();
    dataSource.load();
    this.forceUpdate();
  }

  getValueFromField(field: DxPivotField) {
    const summaryType = field.summaryType as string;
    const dataField = field.dataField as string;
    if (summaryType === 'count') return ['count'];

    // It's now assumed that the only custom summary type is "count unique". If
    // new custom summary types are added this logic has to change!
    if (summaryType === 'custom') return ['count_unique', dataField];

    return [summaryType, dataField];
  }

  render() {
    const dataFields = this.getDataFields();
    const measureOptions = this.getMeasureOptions();
    const className = classNames('app-MeasureSelect', {
      'app-MeasureSelect-hidden': this.props.hidden,
    });
    return (
      <div className={className}>
        {dataFields.map((field: DxPivotField, index: number) => {
          const onChange = (value: string[]) => {
            this.handleMeasureChange(index, value);
          };
          return (
            <Cascader
              allowClear={false}
              className="app-MeasureSelect-item"
              key={index}
              options={measureOptions}
              onChange={onChange}
              value={this.getValueFromField(field)}
            />
          );
        })}
        {dataFields.length < MAX_ITEMS && (
          <PlusOutlined className="app-MeasureSelect-add" onClick={this.addItem} />
        )}
        {dataFields.length > 1 && (
          <MinusOutlined className="app-MeasureSelect-remove" onClick={this.removeItem} />
        )}
      </div>
    );
  }
}
