import yaml from 'js-yaml';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';

import * as api from 'state/api';
import * as dashboardItems from 'state/data/dashboardItems';
import * as dataExportsForFront from 'state/data/dataExportsForFront';
import modals from 'state/modals';
import { DashboardItem } from 'types';

function selector() {
  const selectDashboardItemId = createSelector(
    modals.selectDashboardItemEditorData,
    (data: { id: string }) => data.id
  );

  const dashboardItemSelector = dashboardItems.selectDashboardItemFactory(selectDashboardItemId);

  const dataExportIdSelector = createSelector(
    dashboardItemSelector,
    (dashboardItem: DashboardItem) => get(dashboardItem, 'dataView.id', '')
  );

  const specsSelector = createSelector(dashboardItemSelector, (dashboardItem: DashboardItem) => {
    if (!dashboardItem) return '';
    return yaml.dump(dashboardItem.specs);
  });

  return createStructuredSelector({
    dashboardItem: dashboardItemSelector,
    dataExportId: dataExportIdSelector,
    errorMessages: api.selectEditDashboardItemsErrors,
    isSaving: api.selectIsEditDashboardItemsPending,
    isVisible: modals.selectIsDashboardItemEditorVisible,
    specs: specsSelector,
  });
}

const actions = {
  editDashboardItem: dashboardItems.edit,
  fetchDataExports: dataExportsForFront.fetch,
  hideModal: modals.hideDashboardItemEditor,
};

export default connect(selector, actions);
