import { CloseOutlined } from '@ant-design/icons';
import { Button, Input, Popconfirm } from 'antd';
import classNames from 'classnames';
import React from 'react';
import SortableTree, {
  addNodeUnderParent,
  changeNodeAtPath,
  removeNodeAtPath,
} from 'react-sortable-tree';

import TeamAccessSelect from 'shared/TeamAccessSelect';
import { generateNodeId, getNodeKey } from './utils';

interface Props {
  readonly activeViewGroupId: string | null;
  readonly addNewViewGroup: any;
  readonly onChange: any;
  readonly onViewGroupClick: any;
  readonly removeViewGroup: any;
  readonly sidebarTreeData: any;
  readonly updateViewGroup: any;
  readonly viewGroups: any;
}

function canDrop({ node, nextParent }: any) {
  const parentType = nextParent && nextParent.type;
  if (node.type === 'group') {
    return parentType === 'category';
  }
  if (node.type === 'category') {
    return !parentType;
  }
  return false;
}

export default class SidebarEditor extends React.Component<Props> {
  // Add category button
  // -------------------

  handleAddCategory = () => {
    const newCategory = {
      children: [],
      id: generateNodeId(),
      name: 'New category',
      type: 'category',
    };
    const newTreeData = this.props.sidebarTreeData.concat(newCategory);
    this.props.onChange(newTreeData);
  };

  getAddCategoryButton = () => {
    return (
      <Button
        className="app-SidebarEditor__add-category-button"
        onClick={this.handleAddCategory}
        size="small"
        type="primary"
      >
        Add new category
      </Button>
    );
  };

  // Add group button
  // ----------------

  getAddGroupHandler = (node: any, path: any) => {
    return () => {
      const id = generateNodeId();
      const newGroup = {
        id,
        name: 'New page',
        type: 'group',
      };

      const newTreeData = addNodeUnderParent({
        getNodeKey,
        treeData: this.props.sidebarTreeData,
        parentKey: path[path.length - 1],
        expandParent: true,
        newNode: newGroup,
      }).treeData;
      this.props.onChange(newTreeData);
      this.props.addNewViewGroup(id);
    };
  };

  getAddGroupButton = (node: any, path: any) => {
    return (
      <Button
        className="app-SidebarEditor__node-add-group-button"
        key="add-group-button"
        onClick={this.getAddGroupHandler(node, path)}
        size="small"
        type="primary"
      >
        +
      </Button>
    );
  };

  // Remove node button
  // ------------------

  getRemoveNodeHandler = (node: any, path: any) => {
    return () => {
      const newTreeData = removeNodeAtPath({
        path,
        getNodeKey,
        treeData: this.props.sidebarTreeData,
      });
      this.props.onChange(newTreeData);
      if (node.type === 'group') {
        this.props.removeViewGroup(node.id);
      }
    };
  };

  getRemoveNodeButton = (node: any, path: any) => {
    return (
      <Popconfirm
        cancelText="No"
        okText="Yes"
        onConfirm={this.getRemoveNodeHandler(node, path)}
        title="Are you sure？"
      >
        <Button
          className="app-SidebarEditor__node-remove-button"
          icon={<CloseOutlined />}
          key="remove-node-button"
          shape="circle"
          size="small"
        />
      </Popconfirm>
    );
  };

  // Node name changer
  // -----------------

  getNameChangeHandler = (node: any, path: any) => {
    return (event: any) => {
      const name = event.target.value;
      if (node.type === 'group') {
        this.props.updateViewGroup(node.id, { name });
        return;
      }
      const newTreeData = changeNodeAtPath({
        getNodeKey,
        path,
        treeData: this.props.sidebarTreeData,
        newNode: { ...node, name },
      });
      this.props.onChange(newTreeData);
    };
  };

  getNameInput = (node: any, path: any) => {
    const name = node.type === 'group' ? this.getViewGroup(node).name : node.name;

    return (
      <Input
        className="app-SidebarEditor__node-input"
        onChange={this.getNameChangeHandler(node, path)}
        size="small"
        value={name}
      />
    );
  };

  // Other getters
  // -------------

  getRowHeight({ node }: any) {
    return node.type === 'category' ? 60 : 86;
  }

  getViewGroup = (node: any) => {
    return this.props.viewGroups[node.id];
  };

  getViewCount = (node: any) => {
    const viewGroup = this.getViewGroup(node);
    const numberOfViews = viewGroup && viewGroup.views && viewGroup.views.length;
    const text = numberOfViews === 1 ? `${numberOfViews} view` : `${numberOfViews} views`;
    return <span className="app-SidebarEditor__node-view-count">{text}</span>;
  };

  getChangeViewGroupHandler = (node: any) => {
    return (data: {}) => {
      const viewGroup = this.getViewGroup(node);
      this.props.updateViewGroup(viewGroup.id, data);
    };
  };

  // generateNodeProps functions (for customizing node rendering)
  // ------------------------------------------------------------

  generateNodeProps = ({ node, path }: any) => {
    if (node.type === 'category') {
      return this.generateCategoryProps(node, path);
    }
    return this.generateGroupProps(node, path);
  };

  generateCategoryProps = (node: any, path: any) => {
    return {
      buttons: [this.getAddGroupButton(node, path), this.getRemoveNodeButton(node, path)],
      className: 'app-SidebarEditor__node app-SidebarEditor__category',
      title: this.getNameInput(node, path),
    };
  };

  generateGroupProps = (node: any, path: any) => {
    const viewGroup = this.getViewGroup(node);

    const className = classNames('app-SidebarEditor__node', 'app-SidebarEditor__group', {
      'app-SidebarEditor__node--active': node.id === this.props.activeViewGroupId,
    });

    const onClick = () => {
      if (node.type === 'group') {
        this.props.onViewGroupClick(node.id);
      }
    };

    const title = (
      <div>
        {this.getNameInput(node, path)}
        {this.getViewCount(node)}
      </div>
    );

    const subtitle = (
      <TeamAccessSelect
        allowedTeamIds={viewGroup.allowedTeamIds}
        isAccessRestricted={viewGroup.isAccessRestricted}
        onChange={this.getChangeViewGroupHandler(node)}
      />
    );

    return {
      className,
      onClick,
      subtitle,
      title,
      buttons: [this.getRemoveNodeButton(node, path)],
    };
  };

  render() {
    return (
      <div className="app-SidebarEditor">
        <h2 className="app-SidebarEditor__header">Sidebar {this.getAddCategoryButton()}</h2>
        <SortableTree
          canDrop={canDrop}
          generateNodeProps={this.generateNodeProps}
          getNodeKey={getNodeKey}
          maxDepth={2}
          onChange={this.props.onChange}
          rowHeight={this.getRowHeight}
          treeData={this.props.sidebarTreeData}
        />
      </div>
    );
  }
}
