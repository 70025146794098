import { CaretDownOutlined, SettingOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import { canView } from 'services/auth';
import { FOX_PAGE_PATH, FRONT_PAGE_PATH } from 'shared/constants';
import { UserOrganization } from 'types';

import DEFAULT_LOGO from 'assets/images/logo.svg';

export interface OwnProps {
  readonly collapsed: boolean;
  readonly mobile: boolean;
}

export interface InjectedProps {
  readonly currentOrganization: UserOrganization | null;
  readonly currentPage: string;
  readonly organizations: UserOrganization[];
}

type Props = OwnProps & InjectedProps;

export default class OrganizationSelector extends React.Component<Props> {
  getLogo(org: UserOrganization | null) {
    if (!org) return null;
    if (org.logoUrl !== '') return org.logoUrl;
    return DEFAULT_LOGO;
  }

  getPageToLink(org: UserOrganization, currentPage: string) {
    if (currentPage === FOX_PAGE_PATH) {
      if (!canView(org.foxPermissionLevel) || org.isFoxHidden) {
        return FRONT_PAGE_PATH;
      }
    }
    if (currentPage === FRONT_PAGE_PATH) {
      if (!canView(org.frontPermissionLevel) || org.isFrontHidden) {
        return FOX_PAGE_PATH;
      }
    }
    return currentPage;
  }

  renderMenuItem = (org: UserOrganization) => {
    if (org.isFoxHidden && org.isFrontHidden) {
      return null;
    }
    const logo = org.logoUrl !== '' ? org.logoUrl : DEFAULT_LOGO;
    const pageToLink = this.getPageToLink(org, this.props.currentPage);
    const linkTo = `/w/${org.slug}/${pageToLink}`;
    return (
      <Menu.Item key={org.id}>
        <Link className="app-OrganizationSelector-item-link" to={linkTo}>
          <img alt="Logo" className="app-OrganizationSelector-logo" src={logo} />
          <span className="app-OrganizationSelector-item-name">{org.name}</span>
        </Link>
      </Menu.Item>
    );
  };

  renderAdminPageLink = (org: UserOrganization) => {
    const baseUrl = process.env.API_URL;
    const url = `${baseUrl}/admin/${org.id}`;
    return (
      <Menu.Item key="admin-page" className="app-OrganizationSelector-admin-page-item">
        <a className="app-OrganizationSelector-item-link" href={url}>
          <SettingOutlined className="app-OrganizationSelector-admin-page-icon" />
          <span className="app-OrganizationSelector-item-name">Admin</span>
        </a>
      </Menu.Item>
    );
  };

  render() {
    const { collapsed, currentOrganization, mobile, organizations } = this.props;
    const isCurrentOrgAdmin = currentOrganization && currentOrganization.isAdmin;
    const nonCurrentOrganizations = organizations.filter(
      (org) => !(currentOrganization && currentOrganization.id === org.id)
    );
    const menu = (
      <Menu className="app-OrganizationSelector__dropdown">
        {currentOrganization && isCurrentOrgAdmin && this.renderAdminPageLink(currentOrganization)}
        {nonCurrentOrganizations.length > 0 && (
          <div className="app-OrganizationSelector__section-header">Switch organization</div>
        )}
        {nonCurrentOrganizations.map(this.renderMenuItem)}
      </Menu>
    );
    const logo = this.getLogo(currentOrganization);
    const canSelect = isCurrentOrgAdmin || nonCurrentOrganizations.length > 0;
    const className = classNames('app-OrganizationSelector', {
      'app-OrganizationSelector-collapsed': collapsed,
      'app-OrganizationSelector-disabled': !canSelect,
      'app-OrganizationSelector-mobile': mobile,
    });
    const isDefaultLogo = currentOrganization && currentOrganization.logoUrl === '';
    const logoClassName = classNames('app-OrganizationSelector-logo', {
      'app-OrganizationSelector-logo-default': isDefaultLogo,
    });
    return (
      <div className={className}>
        <div className="app-OrganizationSelector-container">
          <Dropdown disabled={!canSelect} overlay={menu} trigger={['click']}>
            <a className="app-OrganizationSelector-current" href="#">
              <div className="app-OrganizationSelector-current-logo">
                {logo ? (
                  <img alt="Logo" className={logoClassName} src={logo} />
                ) : (
                  <div className="app-OrganizationSelector-no-logo" />
                )}
              </div>
              <div className="app-OrganizationSelector-current-name">
                {currentOrganization ? currentOrganization.name : 'Select workspace'}
              </div>
              <div className="app-OrganizationSelector-current-icon">
                {canSelect && <CaretDownOutlined />}
              </div>
            </a>
          </Dropdown>
        </div>
      </div>
    );
  }
}
