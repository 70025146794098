import { Spin } from 'antd';
import React from 'react';
import { Redirect } from 'react-router-dom';

interface Props {
  redirectPath: string | null;
}

export function Home(props: Props) {
  if (props.redirectPath) {
    return <Redirect to={props.redirectPath} />;
  }
  return (
    <div className="app-Home">
      <div className="app-Home-loading">
        <Spin />
      </div>
    </div>
  );
}

export default Home;
