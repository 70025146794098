import { Button, Modal, Radio, Switch, Tabs, Typography } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import classNames from 'classnames';
import React from 'react';

import CopyInput from 'shared/CopyInput';
import { DataExport } from 'types';
import DataExportConnections from './DataExportConnections';

const { Button: RadioButton, Group: RadioGroup } = Radio;
const { TabPane } = Tabs;
const { Paragraph } = Typography;

export interface Props {
  readonly canEdit: boolean;
  readonly dataExport: DataExport;
  readonly onChange: (values: {}) => void;
}

interface State {
  format: string;
  modalVisible: boolean;
  system: string;
}

const DEFAULT_SYSTEM = 'tableau';
const DEFAULT_FORMAT = 'jsonObject';

export default class SharedUrlAccess extends React.Component<Props, State> {
  state: State = {
    format: DEFAULT_FORMAT,
    modalVisible: false,
    system: DEFAULT_SYSTEM,
  };

  changeFormat = (event: RadioChangeEvent) => {
    const format = event.target.value;
    this.setState({ format });
  };

  changeSystem = (system: string) => {
    this.setState({ system });
  };

  handleSharedUrlAccessChange = (checked: boolean) => {
    this.props.onChange({ has_shared_url_access: checked });
  };

  hideModal = () => {
    this.setState({ modalVisible: false });
  };

  showModal = ({ system = DEFAULT_SYSTEM }: { system: string }) => {
    this.setState({
      system,
      modalVisible: true,
    });
  };

  showGoogleSheetsModal = () => {
    this.showModal({ system: 'googleSheets' });
  };

  showOtherModal = () => {
    this.showModal({ system: 'other' });
  };

  showTableauModal = () => {
    this.showModal({ system: 'tableau' });
  };

  // --------------
  // render helpers
  // --------------

  renderAccessSwitch = () => {
    const { canEdit, dataExport } = this.props;
    const disableParameterized = dataExport.isParameterized && !dataExport.hasSharedUrlAccess;
    return (
      <Switch
        checked={dataExport.hasSharedUrlAccess}
        disabled={!canEdit || disableParameterized}
        key={`${dataExport.id}-shared-url-access`}
        onChange={this.handleSharedUrlAccessChange}
        size="small"
      />
    );
  };

  renderAction = (label: string, content: any) => {
    return (
      <table className="app-SharedUrlAccess__action">
        <tbody>
          <tr>
            <th>{label}</th>
            <td>{content}</td>
          </tr>
        </tbody>
      </table>
    );
  };

  renderEnableStep = () => {
    return (
      <li>
        <h3>Enable external access to this data export with a secret URL</h3>
        <Paragraph type="secondary">
          This will enable a secret URL that any external system can use to access this data export.
        </Paragraph>
        {this.renderAction('Enable access:', this.renderAccessSwitch())}
      </li>
    );
  };

  renderCopyText = (text?: string) => {
    if (!text) return '-';
    return <CopyInput isPassword text={text} />;
  };

  // ---------
  // info tabs
  // ---------

  renderTableuTab = () => {
    const { isParameterized, sharedUrls } = this.props.dataExport;

    if (isParameterized) {
      return (
        <p>
          This data export requires a logged in QuickBI user to filter data and can't be used to
          create a Tableau data connector.
        </p>
      );
    }

    return (
      <ol className="app-SharedUrlAccess__steps">
        {this.renderEnableStep()}
        <li>
          <h3>Create a data connector in Tableau</h3>
          <Paragraph>Use the following secret URL to create a data connector in Tableau.</Paragraph>
          {this.renderAction('Secret URL:', this.renderCopyText(sharedUrls.tableauConnector))}
        </li>
      </ol>
    );
  };

  renderGoogleSheetsTab = () => {
    const { isParameterized, sharedFormulas } = this.props.dataExport;

    if (isParameterized) {
      return (
        <p>
          This data export requires a logged in QuickBI user to filter data and can't be used in
          Google Sheets.
        </p>
      );
    }

    return (
      <ol className="app-SharedUrlAccess__steps">
        {this.renderEnableStep()}
        <li>
          <h3>Import data to Google Sheets</h3>
          <Paragraph>
            Use the following macro to import data directly to Google Sheets. For more info see{' '}
            <a
              href="https://support.google.com/docs/answer/3093335?hl=en"
              rel="noreferrer"
              target="_blank"
            >
              Google documentation
            </a>
            .
          </Paragraph>
          {this.renderAction('Macro:', this.renderCopyText(sharedFormulas.googleSheet))}
        </li>
      </ol>
    );
  };

  renderOtherTab = () => {
    const { isParameterized, sharedUrls } = this.props.dataExport;

    if (isParameterized) {
      return (
        <p>
          This data export requires a logged in QuickBI user to filter data and can't be used to
          create custom connections to external systems.
        </p>
      );
    }

    const { format } = this.state;
    return (
      <ol className="app-SharedUrlAccess__steps">
        {this.renderEnableStep()}
        <li>
          <h3>Choose a format</h3>
          <Paragraph>Select in which format to get the data from this data export.</Paragraph>
          {this.renderAction(
            'Format:',
            <RadioGroup
              buttonStyle="solid"
              onChange={this.changeFormat}
              value={format}
              size="small"
            >
              <RadioButton key="jsonObject" value="jsonObject">
                JSON
              </RadioButton>
              <RadioButton key="jsonArray" value="jsonArray">
                JSON (arrays)
              </RadioButton>
              <RadioButton key="csv" value="csv">
                CSV
              </RadioButton>
              <RadioButton key="xlsx" value="xlsx">
                XLSX
              </RadioButton>
              <RadioButton key="html" value="html">
                HTML
              </RadioButton>
            </RadioGroup>
          )}
        </li>
        <li>
          <h3>Access data through secret URL</h3>
          <Paragraph>
            Use the following secret URL to access data directly in the selected format.
          </Paragraph>
          {this.renderAction('Secret URL:', this.renderCopyText(sharedUrls[format]))}
        </li>
      </ol>
    );
  };

  // ----------
  // info modal
  // ----------

  renderInfoModal = () => {
    const { name } = this.props.dataExport;
    return (
      <Modal
        className="app-SharedUrlAccess__modal"
        footer={
          <Button type="primary" onClick={this.hideModal}>
            Ok
          </Button>
        }
        onCancel={this.hideModal}
        title={`Connect external system to data export "${name}"`}
        visible={this.state.modalVisible}
        width={650}
        zIndex={1100}
      >
        <Tabs activeKey={this.state.system} animated={false} onChange={this.changeSystem}>
          <TabPane tab="Tableau" key="tableau">
            {this.renderTableuTab()}
          </TabPane>
          <TabPane tab="Google Sheets" key="googleSheets">
            {this.renderGoogleSheetsTab()}
          </TabPane>
          <TabPane tab="Other" key="other">
            {this.renderOtherTab()}
          </TabPane>
        </Tabs>
      </Modal>
    );
  };

  // ------
  // render
  // ------

  render() {
    const enabled = this.props.dataExport.hasSharedUrlAccess;
    const connections = this.props.dataExport.fetchSummary;
    return (
      <div
        className={classNames('app-SharedUrlAccess', { 'app-SharedUrlAccess--enabled': enabled })}
      >
        <div className="app-SharedUrlAccess__header">
          <h4>External access with secret URL</h4>
          {this.renderAccessSwitch()}
        </div>
        <div className="app-SharedUrlAccess__buttons">
          <Button onClick={this.showTableauModal} size="small">
            Tableau
          </Button>
          <Button onClick={this.showGoogleSheetsModal} size="small">
            Google Sheets
          </Button>
          <Button onClick={this.showOtherModal} size="small">
            Other
          </Button>
        </div>
        <DataExportConnections accessType="shared_url" connections={connections} />
        {this.renderInfoModal()}
      </div>
    );
  }
}
