import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as editorTabs from 'state/fox/editorTabs';
import { OwnProps } from './DataDisplay';

function selector() {
  function tabIdSelector(state: any, props: OwnProps) {
    return props.tabId;
  }

  return createStructuredSelector({
    errorMessage: editorTabs.selectErrorMessageFactory(tabIdSelector),
    showData: editorTabs.selectIsSqlQueriedFactory(tabIdSelector),
  });
}

export default connect(selector);
