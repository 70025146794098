import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as api from 'state/api';
import * as auth from 'state/auth';
import * as dataExports from 'state/data/dataExports';
import * as dataExportDetails from 'state/fox/dataExportDetails';
import * as editorTabs from 'state/fox/editorTabs';

const selector = createStructuredSelector({
  canEdit: auth.selectCanEditDataExports,
  canView: auth.selectCanViewFox,
  currentOrganization: auth.selectCurrentOrganization,
  dataExportGrouping: dataExports.selectDataExportGrouping,
  isFetching: api.selectIsFetchDataExportsPending,
  selectedDataExportId: dataExportDetails.selectVisibleDataExportId,
});

const actions = {
  deleteDataExport: dataExports.remove,
  fetchDataExports: dataExports.fetch,
  showSqlInEditor: editorTabs.openTab,
  toggleDataExportDetails: dataExportDetails.toggle,
};

export default connect(selector, actions);
