import { connect } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';

import * as api from 'state/api';
import * as auth from 'state/auth';
import * as dataExports from 'state/data/dataExports';
import * as editorTabs from 'state/fox/editorTabs';
import modals from 'state/modals';

const dataExportIdSelector = createSelector(
  editorTabs.selectActiveTab,
  (activeTab) => activeTab.dataExportId || ''
);

const isSavingSelector = createSelector(
  api.selectIsSaveDataExportsPending,
  api.selectIsEditDataExportsPending,
  (isSaving, isEditing) => isSaving || isEditing
);

const errorMessagesSelector = createSelector(
  api.selectSaveDataExportsErrors,
  api.selectEditDataExportsErrors,
  (saveErrors, editErrors) => [...saveErrors, ...editErrors]
);

const selector = createStructuredSelector({
  dataExport: dataExports.selectDataExportFactory(dataExportIdSelector),
  dataExportGroupOptions: dataExports.selectDataExportGroupOptions,
  errorMessages: errorMessagesSelector,
  isSaving: isSavingSelector,
  isVisible: modals.selectIsDataExportVisible,
  sql: editorTabs.selectActiveTabSql,
  tabId: editorTabs.selectActiveTabId,
  usesPostgres: auth.selectUsesPostgres,
});

const actions = {
  editDataExport: dataExports.edit,
  hideModal: modals.hideDataExport,
  saveDataExport: dataExports.save,
};

export default connect(selector, actions);
