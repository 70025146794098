import { Action, combineActions, createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

import * as api from 'services/api';
import * as auth from 'services/auth';
import { AuthUser, Credentials, UserOrganization } from 'types';
import * as router from './router';

enum Status {
  NotStarted,
  FetchingSession,
  NotAuthenticated,
  LoggingIn,
  Authenticated,
  LoginClassicFailed,
  LoginGoogleFailed,
  LoggedOut,
  RateLimitError,
}

interface State {
  readonly isPasswordChangeRequired: boolean;
  readonly status: Status;
  readonly user: AuthUser | null;
}

// -------
// Actions
// -------

export const logIn = api.createApiAction<api.LoginCredentials, AuthUser>(
  createAction('app/auth/logIn', (credentials: api.LoginCredentials) => credentials)
);
export const logInGoogle = api.createApiAction<string, AuthUser>(
  createAction('app/auth/logInGoogle', (token: string) => token)
);
export const logOut = api.createApiAction<void, void>(createAction('app/auth/logOut'));
export const load = api.createApiAction<void, AuthUser | null>(createAction('app/auth/load'));
export const reset = createAction('app/auth/reset');

// ---------
// Selectors
// ---------

function select(state: any): State {
  return state.auth;
}

export const selectUser = createSelector(select, (state: State): AuthUser | null => state.user);

export const selectIsAuthStarted = createSelector(
  select,
  (state: State): boolean => state.status !== Status.NotStarted
);

export const selectIsFetchingSession = createSelector(
  select,
  (state: State): boolean => state.status === Status.FetchingSession
);

export const selectIsAuthenticated = createSelector(
  select,
  (state: State): boolean => state.status === Status.Authenticated
);

export const selectDidLoginClassicFail = createSelector(
  select,
  (state: State): boolean => state.status === Status.LoginClassicFailed
);

export const selectIsRateLimitError = createSelector(
  select,
  (state: State): boolean => state.status === Status.RateLimitError
);

export const selectUsername = createSelector(
  selectUser,
  (user: AuthUser | null): string | null => user && user.email
);

export const selectIsPasswordChangeRequired = createSelector(
  select,
  (state: State): boolean => state.isPasswordChangeRequired
);

export const selectCsrfToken = createSelector(
  selectUser,
  (user: AuthUser | null): string | null => user && user.csrfToken
);

export const selectGsServiceAccountEmail = createSelector(
  selectUser,
  (user: AuthUser | null): string | null => user && user.gsServiceAccountEmail
);

export const selectUserOrganizations = createSelector(
  selectUser,
  (user: AuthUser | null): UserOrganization[] => (user && user.organizations) || []
);

export const selectCurrentOrganization = createSelector(
  router.selectOrganizationSlug,
  selectUserOrganizations,
  (slug: string, organizations: UserOrganization[]): UserOrganization | null => {
    for (const org of organizations) {
      if (org.slug === slug) {
        return org;
      }
    }
    return null;
  }
);

export const selectCurrentOrganizationId = createSelector(
  selectCurrentOrganization,
  (org: null | UserOrganization) => org && org.id
);

export const selectDefaultOrganization = createSelector(
  selectUserOrganizations,
  (organizations: UserOrganization[]): UserOrganization | null => {
    if (organizations.length === 0) return null;
    for (const org of organizations) {
      if (org.isDefaultMembership) return org;
    }
    return organizations[0];
  }
);

export const selectCurrentOrganizationTeams = createSelector(
  selectCurrentOrganization,
  (org: null | UserOrganization) => (org ? org.teams : [])
);

export const selectCredentials = createSelector(
  selectCurrentOrganizationId,
  selectCsrfToken,
  (organizationId: string | null, csrfToken: string | null): Credentials => {
    return {
      csrfToken,
      organizationId,
    };
  }
);

export const selectIsFoxHidden = createSelector(
  selectCurrentOrganization,
  (organization: UserOrganization | null) => {
    return organization ? organization.isFoxHidden : true;
  }
);

export const selectIsFrontHidden = createSelector(
  selectCurrentOrganization,
  (organization: UserOrganization | null) => {
    return organization ? organization.isFrontHidden : true;
  }
);

// Data warehouse selectors

export const selectUsesPostgres = createSelector(
  selectCurrentOrganization,
  (organization: UserOrganization | null) => {
    return organization && organization.dbms === 'postgres';
  }
);

export const selectUsesSqlite = createSelector(
  selectCurrentOrganization,
  (organization: UserOrganization | null) => {
    return organization && organization.dbms === 'sqlite';
  }
);

// Permission selectors

export const selectFoxPermissionLevel = createSelector(
  selectCurrentOrganization,
  (organization: UserOrganization | null) => {
    return organization ? organization.foxPermissionLevel : '';
  }
);

export const selectFrontPermissionLevel = createSelector(
  selectCurrentOrganization,
  (organization: UserOrganization | null) => {
    return organization ? organization.frontPermissionLevel : '';
  }
);

export const selectCanViewFox = createSelector(selectFoxPermissionLevel, (permissionLevel) =>
  auth.canView(permissionLevel)
);

export const selectCanViewFront = createSelector(selectFrontPermissionLevel, (permissionLevel) =>
  auth.canView(permissionLevel)
);

export const selectCanViewFoxOrFront = createSelector(
  selectCanViewFox,
  selectCanViewFront,
  (canViewFox, canViewFront) => canViewFox || canViewFront
);

export const selectCanViewFoxAndFront = createSelector(
  selectCanViewFox,
  selectCanViewFront,
  (canViewFox, canViewFront) => canViewFox && canViewFront
);

// Specific permissions in fox

export const selectCanQuery = createSelector(selectFoxPermissionLevel, (permissionLevel) =>
  auth.canView(permissionLevel)
);

export const selectCanEditDataExports = createSelector(
  selectFoxPermissionLevel,
  (permissionLevel) => auth.canEdit(permissionLevel)
);

export const selectCanAddDataSources = createSelector(selectFoxPermissionLevel, (permissionLevel) =>
  auth.isAdmin(permissionLevel)
);

// Specific permissions in front

export const selectIsAdminFront = createSelector(selectFrontPermissionLevel, (permissionLevel) =>
  auth.isAdmin(permissionLevel)
);

// -----
// State
// -----

type AuthAction = Action<void> | Action<AuthUser>;
const initialState: State = {
  isPasswordChangeRequired: false,
  status: Status.NotStarted,
  user: null,
};
export const logOutActionTypes = [String(logOut.success), String(reset)];
const logOutTypes: any = combineActions(...logOutActionTypes);
const handlers = {
  [String(load)]: (state: State): State => ({
    ...state,
    status: Status.FetchingSession,
  }),
  [String(load.success)]: (state: State, action: AuthAction): State => {
    const user = action.payload as AuthUser | null;
    return {
      ...state,
      user,
      status: user ? Status.Authenticated : Status.NotAuthenticated,
    };
  },
  [String(load.failure)]: (state: State): State => {
    return {
      ...state,
      status: Status.NotAuthenticated,
    };
  },
  [String(logIn)]: (state: State): State => ({
    ...state,
    isPasswordChangeRequired: false,
    status: Status.LoggingIn,
  }),
  [combineActions(`${logIn}.success`, `${logInGoogle}.success`) as any]: (
    state: State,
    action: AuthAction
  ): State => {
    const payload = action.payload as AuthUser;
    return {
      ...state,
      isPasswordChangeRequired: false,
      status: Status.Authenticated,
      user: payload,
    };
  },
  [String(logIn.failure)]: (state: State, action: any): State => {
    const isPasswordChangeRequired =
      action.payload && action.payload.type === 'PasswordChangeRequired';
    const status =
      action.payload && action.payload.type === 'RateLimitError'
        ? Status.RateLimitError
        : Status.LoginClassicFailed;
    return {
      ...state,
      isPasswordChangeRequired,
      status,
    };
  },
  [String(logInGoogle.failure)]: (state: State): State => {
    return {
      ...state,
      status: Status.LoginGoogleFailed,
    };
  },
  // NOTE: Root reducer is supposed to have already set initial state so no
  // need to reset user here.
  [logOutTypes]: (state: State): State => {
    return {
      ...state,
      status: Status.LoggedOut,
    };
  },
};
export default handleActions(handlers, initialState);
