import { Action, ActionFunction1, createAction } from 'redux-actions';

import { ApiError } from 'types';

type ApiAction<RequestPayload, SuccessPayload> = ActionFunction1<
  RequestPayload,
  Action<RequestPayload>
> & {
  success: ActionFunction1<SuccessPayload, Action<SuccessPayload>>;
  failure: ActionFunction1<ApiError, Action<ApiError>>;
};

/** Create success and failure actions for given action.
 *
 * Example 1:
 *  const action = createAction('app/session/fetchSession');
 *  const fetchSession = api.createApiAction<void, ISession>(action);
 *  dispatch(fetchSession());
 *  dispatch(fetchSession.success({ ... } as ISession);
 *  dispatch(fetchSession.failure({ ... } as ApiError);
 *
 * Example 2:
 *  const action = createAction('app/session/fetchSession', (params: IParams) => params);
 *  const fetchSession = api.createApiAction<IParams, ISession>(action);
 *  dispatch(fetchSession({ ... } as IParams));
 *  dispatch(fetchSession.success({ ... } as ISession);
 *  dispatch(fetchSession.failure({ ... } as ApiError);
 *
 * The "as <type>" parts are only included in the examples to specify what types the
 * arguments have. They are not actually required when using the function.
 */
export default function createApiAction<RequestPayload, SuccessPayload>(
  action: ActionFunction1<RequestPayload, Action<RequestPayload>>
): ApiAction<RequestPayload, SuccessPayload> {
  const apiAction = action as ApiAction<RequestPayload, SuccessPayload>;
  apiAction.success = createAction(`${action}.success`, (payload: SuccessPayload) => payload);
  apiAction.failure = createAction(`${action}.failure`, (payload: ApiError) => payload);
  return apiAction;
}
