import {
  DeleteOutlined,
  MoreOutlined,
  SyncOutlined,
  TableOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, Menu, Modal } from 'antd';
import React from 'react';

import * as types from 'types';

const { confirm } = Modal;

export interface OwnProps {
  readonly sourceTableGroup: types.SourceTableGroup;
}

export interface InjectedProps {
  readonly delete: (args: { id: string }) => void;
  readonly showDataSourceUploadModal: (args: { groupKey: string }) => void;
  readonly sync: (args: { id: string }) => void;
}

type Props = OwnProps & InjectedProps;

interface State {
  deleting: boolean;
  syncing: boolean;
}

export default class GroupDropdown extends React.Component<Props, State> {
  state: State = {
    deleting: false,
    syncing: false,
  };

  handleDelete = () => {
    this.setState({ deleting: true });
    this.props.delete({ id: this.props.sourceTableGroup.id });
  };

  handleMenuClick = (event: any) => {
    if (event.key === 'refresh') {
      this.handleSync();
    } else if (event.key === 'showSource') {
      const { sourceUrl } = this.props.sourceTableGroup;
      if (sourceUrl) {
        window.open(sourceUrl, '_blank');
      }
    } else if (event.key === 'delete') {
      this.showDeleteConfirmModal();
    } else if (event.key === 'reupload') {
      this.props.showDataSourceUploadModal({
        groupKey: this.props.sourceTableGroup.key,
      });
    } else {
      throw new Error('Invalid GroupDropdown menu key');
    }
  };

  handleSync = () => {
    this.setState({ syncing: true });
    this.props.sync({ id: this.props.sourceTableGroup.id });
  };

  showDeleteConfirmModal = () => {
    return confirm({
      content: 'This action can not be undone.',
      icon: 'exclamation',
      okButtonProps: { danger: true },
      okText: 'Delete',
      onOk: this.handleDelete,
      title: 'Are you sure you want to delete this source table group?',
    });
  };

  renderDropdownMenu = () => {
    const { sourceTableGroup } = this.props;
    const isSyncing =
      this.state.syncing || sourceTableGroup.syncStartedAt > sourceTableGroup.mergedWithNewDataAt;
    return (
      <Menu className="app-GroupDropdown__dropdown" onClick={this.handleMenuClick}>
        {sourceTableGroup.groupType === 'google-sheet' && (
          <Menu.Item disabled={isSyncing || this.state.deleting} key="refresh">
            <SyncOutlined spin={isSyncing} /> {isSyncing ? 'Refreshing...' : 'Refresh'}
          </Menu.Item>
        )}
        {sourceTableGroup.groupType === 'google-sheet' && (
          <Menu.Item key="showSource">
            <TableOutlined /> Open sheet
          </Menu.Item>
        )}
        {sourceTableGroup.groupType === 'manual-file-upload' && (
          <Menu.Item disabled={this.state.deleting} key="reupload">
            <UploadOutlined /> {isSyncing ? 'Reuploading...' : 'Reupload'}
          </Menu.Item>
        )}
        <Menu.Item disabled={this.state.deleting} key="delete">
          <DeleteOutlined /> {this.state.deleting ? 'Deleting...' : 'Delete'}
        </Menu.Item>
      </Menu>
    );
  };

  render() {
    return (
      <div className="app-GroupDropdown">
        <Dropdown overlay={this.renderDropdownMenu()} placement="bottomCenter" trigger={['click']}>
          <Button
            className="app-GroupDropdown__button"
            icon={<MoreOutlined />}
            size="small"
            type="link"
          />
        </Dropdown>
      </div>
    );
  }
}
