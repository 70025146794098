import React from 'react';

export interface OwnProps {
  readonly children: any;
  readonly fox?: 'admin' | 'edit' | undefined;
  readonly front?: 'admin' | undefined;
}

export interface InjectedProps {
  readonly canAccess: boolean;
}

export type Props = OwnProps & InjectedProps;

export default class PermissionCheck extends React.Component<Props> {
  render = () => {
    if (!this.props.canAccess) {
      return null;
    }
    return this.props.children;
  };
}
