import { Button, Row, Spin } from 'antd';
import React from 'react';

import * as types from 'types';
import DashboardEditor from './DashboardEditor';
import DashboardFilter from './DashboardFilter';
import DashboardItem from './DashboardItem';
import DashboardItemEditor from './DashboardItemEditor';

export interface OwnProps {
  readonly id: string;
}

export interface InjectedProps {
  readonly canEdit: boolean;
  readonly credentials: types.Credentials;
  readonly dashboard: types.Dashboard | null;
  readonly fetchDashboard: (id: string) => void;
  readonly isFetching: boolean;
  readonly showDashboardEditor: () => void;
}

export type Props = OwnProps & InjectedProps;

interface State {
  filters: types.DashboardFilterValues;
}

export default class DashboardView extends React.Component<Props, State> {
  state: State = {
    filters: {},
  };

  UNSAFE_componentWillMount() {
    this.props.fetchDashboard(this.props.id);
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.id !== this.props.id) {
      this.props.fetchDashboard(this.props.id);
    }
  }

  handleFilterChange = (id: string, value: string[]) => {
    this.setState({
      filters: {
        ...this.state.filters,
        [id]: value,
      },
    });
  };

  renderDashboardFilters = (filters: types.DashboardFilter[]) => {
    return (
      <div className="app-DashboardView__filters">
        {filters.map((filter) => (
          <DashboardFilter
            credentials={this.props.credentials}
            dashboardId={this.props.dashboard!.id}
            dataExportId={filter.dataExportId}
            id={filter.filterId}
            key={filter.filterId}
            label={filter.label || ''}
            onChange={this.handleFilterChange}
            options={filter.options}
            value={this.state.filters[filter.filterId] || []}
          />
        ))}
      </div>
    );
  };

  renderDashboardItem = (item: types.DashboardLayoutItem) => {
    return (
      <DashboardItem
        filterValues={this.state.filters}
        // @ts-ignore
        id={item.id}
        key={item.id}
        size={item.size}
      />
    );
  };

  renderRow = (row: types.DashboardRow, index: number) => {
    return (
      <Row className="app-DashboardView__row" gutter={20} key={`dashboard-row-${index}`}>
        {row.map(this.renderDashboardItem)}
      </Row>
    );
  };

  render() {
    const { canEdit, dashboard, isFetching } = this.props;

    if (!dashboard || isFetching) {
      return (
        <div className="app-DashboardView__loading">
          <Spin />
        </div>
      );
    }

    return (
      <div className="app-DashboardView">
        <div className="app-DashboardView__topbar">
          {this.renderDashboardFilters(dashboard.filters || [])}
          {canEdit && (
            <div className="app-DashboardView__edit-tools">
              <Button onClick={this.props.showDashboardEditor}>Edit dashboard</Button>
              {/*@ts-ignore*/}
              <DashboardEditor dashboard={dashboard} />
              <DashboardItemEditor />
            </div>
          )}
        </div>
        {dashboard!.layout.map(this.renderRow)}
      </div>
    );
  }
}
