import filter from 'lodash/filter';
import get from 'lodash/get';
import trimEnd from 'lodash/trimEnd';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { createSelector, createStructuredSelector } from 'reselect';

import * as auth from 'state/auth';
import * as views from 'state/data/views';
import * as router from 'state/router';
import { ViewCategory } from 'types';

interface Params {
  readonly slug: string;
}

interface Props extends RouteComponentProps<Params> {}

function selectUrl(state: any, props: Props) {
  return props.match.url;
}

const selectIsOrganizationRoot = createSelector(
  selectUrl,
  router.selectPathname,
  (url: string, pathname: string) => url === pathname
);

const selectCurrentOrganizationViewCategories = createSelector(
  auth.selectCurrentOrganizationId,
  views.selectAccessibleViewCategories,
  (orgId: string, viewCategories: ViewCategory[]) =>
    filter(viewCategories, (vc) => vc.organization.id === orgId)
);

const selectDefaultViewPath = createSelector(
  selectUrl,
  selectCurrentOrganizationViewCategories,
  (url: string, viewCategories: ViewCategory[]) => {
    const slug = get(viewCategories, '[0].viewGroups[0].slug', null);
    if (!slug) return null;
    const baseUrl = trimEnd(url, '/');
    return `${baseUrl}/${slug}/0`;
  }
);

export const selector = createStructuredSelector({
  canView: auth.selectCanViewFront,
  defaultViewPath: selectDefaultViewPath,
  isOrganizationRoot: selectIsOrganizationRoot,
  organization: auth.selectCurrentOrganization,
  url: selectUrl,
});

export default connect(selector);
