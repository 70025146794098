import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as auth from 'state/auth';
import * as router from 'state/router';

export const selector = createStructuredSelector({
  currentOrganization: auth.selectCurrentOrganization,
  currentPage: router.selectCurrentPage,
  organizations: auth.selectUserOrganizations,
});

export default connect(selector);
