import { Alert } from 'antd';
import React from 'react';

interface Props {
  readonly message: string;
  readonly description: string;
}

export default class ErrorMessage extends React.Component<Props> {
  render() {
    return (
      <Alert
        className="app-ErrorMessage"
        message={this.props.message}
        description={this.props.description}
        type="error"
      />
    );
  }
}
