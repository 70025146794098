import CustomStore from 'devextreme/data/custom_store';
import {
  ArgumentAxis,
  Chart,
  CommonSeriesSettings,
  Export,
  Format,
  Label,
  Legend,
  Series,
  ValueAxis,
} from 'devextreme-react/ui/chart';
import React from 'react';

import { DashboardChartSpecs, DashboardLayoutItemSize } from 'types';
import { aggregateData, getChartTitleSpecs, getChartTooltipSpecs } from './utils';

export interface OwnProps {
  readonly id: string;
  readonly loadFunction?: any;
  readonly loadParams?: any;
  readonly size: DashboardLayoutItemSize;
  readonly specs: DashboardChartSpecs;
}

export type Props = OwnProps;

export default class DashboardChart extends React.Component<Props> {
  chartRef: any;

  componentDidUpdate(prevProps: Props) {
    if (prevProps.size !== this.props.size && this.chartRef) {
      this.chartRef.instance.render();
    }
  }

  aggregateData = (data: any) => {
    const { aggregation } = this.props.specs;
    return aggregateData(data, aggregation);
  };

  getDataSource = () => {
    const { loadFunction, loadParams, specs } = this.props;
    if (loadFunction) {
      const load = () => loadFunction(loadParams);
      return {
        store: new CustomStore({ load }),
        postProcess: this.aggregateData,
      };
    }
    return specs.dataSource;
  };

  handleLegendClick = (event: any) => {
    const series = event.target;
    if (series.isVisible()) {
      series.hide();
    } else {
      series.show();
    }
  };

  handlePointClick(event: any) {
    event.target.select();
  }

  renderCommonSeriesSettings = (settings: any = {}) => {
    const { label, ...commonSeriesSettingsProps } = settings;

    return (
      <CommonSeriesSettings {...commonSeriesSettingsProps}>
        {label && this.renderLabel(label)}
      </CommonSeriesSettings>
    );
  };

  renderLabel = (label: any) => {
    const { format, ...labelProps } = label;
    return <Label {...labelProps}>{format && this.renderFormat(format)}</Label>;
  };

  renderFormat = (format: any) => {
    return <Format {...format} />;
  };

  renderSeries = (series: any = {}, index: number) => {
    return <Series {...series} key={`series-${series.valueField}-${index}`} />;
  };

  renderValueAxises = (valueAxisSpecs: any) => {
    if (Array.isArray(valueAxisSpecs)) {
      return valueAxisSpecs.map((specs: any, index: number) => (
        <ValueAxis key={index} {...specs} />
      ));
    }
    return <ValueAxis {...valueAxisSpecs} />;
  };

  render() {
    const { id, specs } = this.props;
    const series = specs.series || [];
    return (
      <Chart
        dataSource={this.getDataSource()}
        id={id}
        onLegendClick={this.handleLegendClick}
        onPointClick={this.handlePointClick}
        ref={(chart) => {
          this.chartRef = chart;
        }}
        title={getChartTitleSpecs(specs.title)}
        tooltip={getChartTooltipSpecs(specs.tooltip)}
      >
        {this.renderCommonSeriesSettings(specs.commonSeriesSettings)}
        {series.map(this.renderSeries)}
        <ArgumentAxis {...specs.argumentAxis} />
        {this.renderValueAxises(specs.valueAxis)}
        <Legend verticalAlignment={'bottom'} horizontalAlignment={'center'} {...specs.legend} />
        <Export enabled printingEnabled={false} />
      </Chart>
    );
  }
}
