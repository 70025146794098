import { notification, Typography } from 'antd';
import forEach from 'lodash/forEach';
import React from 'react';
import { createLogic } from 'redux-logic';

import * as auth from 'state/auth';
import * as notifications from 'services/notifications';
import * as dataExports from 'state/data/dataExports';
import * as dataSource from 'state/data/dataSource';
import * as pivots from 'state/data/pivots';
import * as types from 'types';

const { Paragraph } = Typography;

export const handleDataExportEditSuccess = createLogic({
  type: [`${dataExports.edit}.success`],
  process({ action }, dispatch, done) {
    const frontAccessEnabled = 'has_front_access' in action.meta && action.meta.has_front_access;
    const authAccessEnabled = 'has_auth_access' in action.meta && action.meta.has_auth_access;
    const sharedUrlAccessEnabled =
      'has_shared_url_access' in action.meta && action.meta.has_shared_url_access;
    const name = action.meta.dataExportName;

    if (frontAccessEnabled) {
      const args = {
        message: 'QuickBI reports access enabled',
        description: `Data export "${name}" can now be used in QuickBI reports.`,
        duration: 10,
        className: 'app-Notification',
      };
      notification.info(args);
    }

    if (authAccessEnabled) {
      const args = {
        message: 'External access with authentication enabled',
        description: `Data export "${name}" can now be accessed from any external system with authentication`,
        duration: 20,
        className: 'app-Notification',
      };
      notification.info(args);
    }

    if (sharedUrlAccessEnabled) {
      const args = {
        message: 'External access with secret URL enabled',
        description: `Data export "${name}" can now be accessed from any external system with the secret URL.`,
        duration: 20,
        className: 'app-Notification',
      };
      notification.info(args);
    }

    done();
  },
});

export const handleDataExportRemoveFailure = createLogic({
  type: `${dataExports.remove}.failure`,
  process(deps, dispatch, done) {
    notifications.show('data-export-remove-failure');
    done();
  },
});

export const handleDataSourceTableGroupSyncFailure = createLogic({
  type: `${dataSource.syncSourceTableGroup}.failure`,
  process({ action, getState }, dispatch, done) {
    const errors = action.payload.errors;
    const defaultErrorArgs = {
      message: 'Refreshing data from Google Sheet failed',
      description: `Something went wrong. Please try again shortly. If the problem persists,
        please contact QuickBI support at support@quickbi.io`,
      duration: 0,
      className: 'app-Notification',
    };

    if (errors && errors.length) {
      forEach(errors, (error: types.ErrorMessage) => {
        if (error.status === '403') {
          const gsServiceAccountEmail = auth.selectGsServiceAccountEmail(getState());
          notification.error({
            message: 'Access to Google Sheet was denied',
            description: (
              <div>
                <p>
                  We were not allowed to get data from your Google Sheet. Please make sure you have
                  shared the Sheet with the following email address:
                </p>
                <Paragraph code copyable>
                  {gsServiceAccountEmail}
                </Paragraph>
              </div>
            ),
            duration: 0,
            className: 'app-Notification',
          });
        } else if (error.status === '409' && error.title === 'DuplicateColumnNameInGoogleSheet') {
          notification.error({
            message: 'Duplicate column name in Google Sheet',
            description: `Refreshing data from Google Sheet failed because some column names appeared more than once.
              Please make sure all headers in the first row of your sheet have unique names and try again.`,
            duration: 0,
            className: 'app-Notification',
          });
        } else {
          notification.error(defaultErrorArgs);
        }
      });
    } else {
      notification.error(defaultErrorArgs);
    }
    done();
  },
});

export const handleDataSourceTableGroupSyncRequest = createLogic({
  type: `${dataSource.syncSourceTableGroup}`,
  process(deps, dispatch, done) {
    notification.info({
      message: 'Refreshing data from Google Sheet started',
      description: 'This may take several minutes if your organization has a lot of data exports.',
      duration: 5,
      className: 'app-Notification',
    });
    done();
  },
});

export const handleDataSourceTableGroupSyncSuccess = createLogic({
  type: `${dataSource.syncSourceTableGroup}.success`,
  process(deps, dispatch, done) {
    notification.info({
      message: 'Refreshing data from Google Sheet finished',
      duration: 3,
      className: 'app-Notification',
    });
    done();
  },
});

export const handlePivotEditFailure = createLogic({
  type: `${pivots.edit}.failure`,
  process(deps, dispatch, done) {
    notifications.show('pivot-edit-failure');
    done();
  },
});

export default [
  handleDataExportEditSuccess,
  handleDataExportRemoveFailure,
  handleDataSourceTableGroupSyncFailure,
  handleDataSourceTableGroupSyncRequest,
  handleDataSourceTableGroupSyncSuccess,
  handlePivotEditFailure,
];
