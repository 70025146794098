import keyBy from 'lodash/keyBy';
import { combineActions, createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

import * as dashboards from 'state/data/dashboards';
import { DashboardItem } from 'types';

interface State {
  [id: string]: DashboardItem;
}

// -------
// Actions
// -------

export const edit = createAction('app/data/dashboardItems/edit');

// ---------
// Selectors
// ---------

type DashboardItemIdSelector = ({}, {}) => string;

export function select(state: any): State {
  return state.data.dashboardItems;
}

export const selectDashboardItemFactory = (idSelector: DashboardItemIdSelector): any =>
  createSelector(select, idSelector, (state: State, dashboardItemId: string) => {
    if (dashboardItemId) {
      return state[dashboardItemId] || null;
    }
    return null;
  });

// -----
// State
// -----

const initialState: State = {};

const handlers = {
  [combineActions(`${dashboards.fetch}.success`, `${dashboards.edit}.success`) as any]: (
    state: State,
    action: any
  ): State => {
    const items = action.payload.genericDashboardItems;
    return {
      ...state,
      ...keyBy(items, 'id'),
    };
  },
  [`${edit}.success`]: (state: State, action: any): State => {
    const dashboardItem = action.payload;
    return {
      ...state,
      [dashboardItem.id]: dashboardItem,
    };
  },
};

export default handleActions(handlers, initialState);
