import { Spin } from 'antd';
import React from 'react';

import { postSql } from 'services/api/sql';
import { parseLoadOptions } from 'services/datagrid';
import DataGrid from 'shared/DataGrid';
import { Credentials, DxColumns } from 'types';

export interface OwnProps {
  readonly height: number;
  readonly tabId: string;
  readonly width: number;
}

interface InjectedProps {
  readonly credentials: Credentials;
  readonly columns: DxColumns;
  readonly dataExportId: string | null;
  readonly isValidating: boolean;
  readonly sql: string;
}

type Props = OwnProps & InjectedProps;

export default class Table extends React.Component<Props> {
  getLoadFunction = () => {
    return (loadOptions: any) => {
      const { credentials, dataExportId, sql } = this.props;
      const options = {
        sql,
        data_view_id: dataExportId || undefined,
        ...parseLoadOptions(loadOptions),
      };
      return postSql(credentials, options);
    };
  };

  render() {
    if (this.props.isValidating) {
      return (
        <div className="app-Table">
          <div className="app-Table-loading">
            <Spin />
          </div>
        </div>
      );
    }
    return (
      <div className="app-Table">
        <DataGrid
          columns={this.props.columns}
          height={this.props.height}
          loadFunction={this.getLoadFunction()}
          maxDecimals={5}
          pageSize={140}
          width={this.props.width}
        />
      </div>
    );
  }
}
