import Papa from 'papaparse';

import { TABLET_WIDTH } from 'shared/constants';

export function isMobile() {
  return window.innerWidth < TABLET_WIDTH;
}

export function formatEuros(num: number) {
  // This was copied from https://stackoverflow.com/a/30780073.
  const amount = Number(num).toLocaleString('fi-FI', { minimumFractionDigits: 2 });
  return `${amount}€`;
}

export function formatNumber(num: number | null, options: any = { maximumFractionDigits: 2 }) {
  if (num === null) {
    return '';
  }
  if (isNaN(num)) {
    return 'NaN';
  }
  return Number(num).toLocaleString('fi-FI', options);
}

export function formatDate(dateObj: Date) {
  const day = dateObj.getDate();
  const month = dateObj.getMonth() + 1;
  const year = dateObj.getFullYear();
  return `${day}.${month}.${year}`;
}

export function csvToObjectArray(data: string) {
  const parseConfig = {
    delimiter: ',',
    dynamicTyping: true,
    header: true,
    newline: '\r\n',
    skipEmptyLines: true,
  };
  return Papa.parse(data, parseConfig);
}

export function getDefaultDxFormatter(maxDecimals: number | null) {
  const options: any = {};
  if (maxDecimals !== null) {
    options.maximumFractionDigits = maxDecimals;
  }
  return (value: any) => (typeof value === 'number' ? formatNumber(value, options) : value);
}
